.date-box-single {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 220px;
    height: 56px;
    border: 1px solid #E0E7F2;
    background-color: #ffffff;
    border-radius: 8px;
    padding-right: 0;
    position: relative;

    &__description-box {
        input {
            padding-bottom: 12px;
        }
    }

    &__description {
        color: #636D65;
        font-family: NunitoSans;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        padding-left: 16px;
        position: absolute;
        top: 20px;
    }

    .react-datepicker__input-container {
        background-color: inherit;
        padding-left: 16px;
        border-radius: 8px;
        height: 100%;
    }

    .react-datepicker {
        -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    }

    input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        border-radius: 8px;
    }

    .selector_wrapper {
        height: 54px;
    }

    &__tooltip {
        padding-left: 8px;
        color: #94A3B8;
        height: 16px;
        font-family: "Inter";
        font-size: 11px;
        height: auto;
        margin-top: 4px;
    }

    .calendar-img {
        position: absolute;
        top: calc(50% - 8px);
        right: 16px;
        pointer-events: none;

        &_active {
            transform: rotate(180deg);
        }
    }
}


.date-picker-custom {
    display: flex;
    gap: 20px;
    align-items: end;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    border: none;
    width: max-content;
    overflow: visible;
    align-items: start;

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        line-height: 1.4rem;
        width: 2rem;
    }

    .text {
        color: #5F5F5F;
        font-size: 13px;
    }


}