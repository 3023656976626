.printable-version {
    display: flex;
    flex-direction: column;
    max-width: 682px;
    width: 100%;
    height: 928px;
    padding: 15px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    background: #FFF;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 20px 20px 0px rgba(30, 41, 59, 0.06);
    padding: 16px;

    &__group-btn {
        display: flex;
        gap: 12px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
        width: 100%;
    }

    &__task-id,
    &__task-id_green {
        display: flex;
        padding: 6px 8px;
        height: 30px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: #F9F9F9;
        gap: 6px;
        color: #636D65;
        font-family: "Golos Text";
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        gap: 6px;

        &_green {
            background-color: #EBFFEB;
        }
    }

    &__title {
        color: #333;
        font-family: Inter;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
    }

    &__description {
        color: #000;
        font-family: NunitoSans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    &__page {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 20px;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 10000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100vw;
        height: 100vh;
        background-color: rgb(0, 0, 0, 0.3);
    }

    &__row {
        display: flex;
        align-items: center;
        gap: 6px;
    }

    &__row-sb {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 6px;
        width: 100%;
    }

    &__label {
        color: #A2A2A2;
        font-family: "Golos Text";
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
    }

    &__text,
    &__text_green {
        overflow: hidden;
        color: #636D65;
        text-overflow: ellipsis;
        font-family: "Golos Text";
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;

        &_green {
            color: #08C104;
        }
    }
}

@media print {
    .printable-version {
        width: 100%;
        height: 100%;
        max-width: 100%;
        min-height: 100%;
        box-shadow: none;

        .btn-basic {
            display: none;
        }
    }
}