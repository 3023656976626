  .niche-search-ver2 {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .title_main {
        color: var(--slate-950, #020617);
        font-family: Rubik;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
    }

      .charts-and-cards-box {
        display: flex;
        align-items: start;
        gap: 8px;
        width: 100%;

        .cards-box {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }

        .charts-group {
            display: flex;
            width: 100%;
            gap: 8px;
            overflow: hidden;
        }
      }
  
      .toolbar-top {
          display: flex;
          width: 100%;
          gap: 16px;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
  
          .filter-group {
              display: flex;
              gap: 13px;
              width: calc(100% );
              min-width: 416px;
              flex-wrap: wrap;
          }
      }
  
      .toolbar-medium {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: start;
          align-items: end;
          gap: 30px;
          flex-wrap: wrap;
  
          .from-to-component-group {
              display: flex;
              gap: 20px;
              margin-top: 24px;
              flex-wrap: wrap;
              width: 100%;
              max-width: calc(100% - 245px - 30px);
              align-items: end;
          }
  
          .ready-made-selections-group {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              gap: 12px;
              margin-top: 24px;
  
              .ready-made-selections {
                  display: inline-flex;
                  padding: 8px;
                  align-items: center;
                  align-content: flex-start;
                  gap: 10px;
                  flex-wrap: wrap;
                  border-radius: 8px;
                  background: #E1EAF5;
  
                  .text_blue {
                      color: var(--primary-400, #4DA9FF);
                      font-size: 13px;
                      font-family: Inter;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 16px;
                  }
              }
  
          }
      }
  
  
      .table-box-component {
          margin-top: 40px;
      }
  
      .drop-box,
      .drop-box_hide {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 30px;
          justify-content: space-between;
          background-color: white;
          background-color: white;
          border-radius: 8px;
          padding: 30px;
          padding-right: 88px;
          margin-top: 24px;
          position: relative;
          height: -webkit-fill-available;
          min-height: calc(17px + 60px);
          max-height: 100%;
          transition: max-height 1s;
  
          .static-box {
              display: flex;
              align-items: center;
              gap: 10px;
              position: absolute;
              top: 28px;
              right: 30px;
  
              .btn__drop,
              .btn__drop_active {
                  height: 32px;
                  width: 32px;
                  border-radius: 50%;
                  background-image: url('./img/drop_grey.svg');
                  background-color: #E6F7FF;
                  cursor: pointer;
                  z-index: 0;
                  border: none;
                  background-size: 14px;
                  background-repeat: no-repeat;
                  background-position: center;
              }
          }
  
          .title {
              color: #888888;
              font-size: 13px;
          }
  
          &_hide {
              max-height: 72px;
              height: 100%;
              overflow: hidden;
          }
  
  
  
          .from-to-component-group {
              display: flex;
              gap: 20px;
              margin-top: 0;
              flex-wrap: wrap;
              align-items: center;
  
              .from-to-component {
                  background-color: #F7F7FD;
                  border-radius: 8px;
                  position: relative;
  
                  .drop-content {
                      background-color: #F7F7FD;
                  }
  
                  &__header {
                      padding: 8px;
                      padding-bottom: 0;
                  }
  
                  .from-to-component__content {
                      background-color: #F7F7FD;
                  }
  
                  .btn__white-border {
                      background-color: #F7F7FD;
                  }
              }
          }
      }
  }