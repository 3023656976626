.modal-add-new-group {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    max-width: 727px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    border-radius: 8px;
    background: var(--white, #FFF);
    overflow: hidden;
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        padding: 16px 32px;
        border-bottom: 2px solid var(--Stroke-Primary, #EDEFED);

        .title {
            color: #020617;
            font-family: Rubik;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: -0.56px;
        }

        .text_date-create {
            color: #64748B;
            font-family: Rubik;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 32px;
        width: 100%;
        align-items: flex-start;
        gap: 16px;
        background: #FFF;

        .top-content {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .title {
                color: #64748B;
                font-family: Rubik;
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
            }

            .drop-zone-and-input {
                display: flex;
                gap: 16px;

                .drop-zone {
                    display: flex;
                    width: 210px;
                    min-width: 210px;
                    height: 165px;
                    padding: 10px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                    border: 2px dashed #EDEFED;
                    position: relative;

                    .input-file {
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        opacity: 0;
                    }

                    .img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

            }
        }

        .text {
            padding: 20px 20px 0px;
            color: #0C2037;
            font-family: Rubik;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }
    }

    .list-content {
        display: flex;
        flex-direction: column;
        height: 64px;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        gap: 4px;
        box-sizing: border-box;
        background-color: #fff;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        overflow: auto;
        padding: 32px;
        padding-right: 0;

        .network-group {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;
            overflow: auto;
            max-height: 100%;
            margin-top: 12px;

            .network-item,
            .network-item_active {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100px;
                gap: 14px;
                cursor: pointer;
                overflow: hidden;
                background-color: inherit;
                border: none;

                &:hover,
                &_active {

                    .img-box {
                        border: 2px solid var(--icons-active, #365EFF);
                    }

                }

                .img-box {
                    display: flex;
                    width: 62px;
                    height: 62px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 5px;
                    background: #F3F6FF;

                    img {
                        max-width: 26px;
                        width: 100%;
                        height: 100%;
                        max-height: 26px;
                    }
                }

                .text {
                    display: -webkit-box;
                    word-wrap: break-word;
                    -webkit-line-clamp: 1; // количество строк
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    padding: 0;
                    color: #5C7189;
                    text-align: center;
                    font-family: Rubik;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                }


            }
        }

        .notification-item,
        .notification-item_active {
            display: flex;
            gap: 8px;
            width: 150px;
            min-width: 150px;
            height: 104px;
            padding: 10px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            border: 2px solid #EDEFFB;
            background: #FFF;
            cursor: pointer;
            position: relative;

            .btn-basic {
                display: none;
            }

            .text {
                overflow: hidden;
                color: #020617;
                text-align: center;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-family: Rubik;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
            }

            .changing-content {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 32px;
            }

            &:hover {
                border: 2px solid #3900B1;
                transition: 0.5s;

                .text {
                    display: none;
                }

                .btn-basic {
                    display: inline-block;
                }
            }

            &_active {
                border: 2px solid #3900B1;

                .text {
                    display: inline-block;
                }
            }

            img {
                margin-left: auto;
                margin-right: auto;
                object-fit: contain;
                width: 40px;
                height: 40px;
            }
        }

        .title {
            color: #64748B;
            font-family: Rubik;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: end;
        padding: 16px 32px;
        border-top: 2px solid #EDEFED;
    }
}