.niche-selection {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 24px;

    &__content {
        display: flex;
        width: 100%;
        flex-direction: column;
        max-width: 100%;
        gap: 12px;
        max-width: calc(1440px - 40px);
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px;
        padding-right: 20px;

        .btn-group_bottom {
            display: flex;
            align-items: center;
            gap: 24px;
            width: 100%;
            justify-content: space-between;
        }
    }

    &__btn-reset {
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;
        border: none;
        background-color: inherit;
    }

    &__header {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 24px;

        .btn_info {
            display: flex;
            align-items: center;
            border: none;
            background-color: inherit;
            cursor: pointer;
            gap: 8px;
            color: #0009E8;
            text-align: center;
            font-family: NunitoSans;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
        }
    }

    .toolbar-top {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 24px;
        justify-content: space-between;

        .checkbox-container {
            display: flex;
            gap: 8px;
            align-items: center;

            .text_label,
            .text_label_disabled {
                color: #020617;
                font-family: Rubik;
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;

                &_disabled {
                    color: #D3DEE7;
                }
            }
        }
    }

    .toolbar-medium {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 24px;
        margin-top: 12px;
        padding: 24px;
        border-radius: 8px;
        background: #FFF;

        &__header {
            display: flex;
            gap: 24px;
            align-items: center;
        }

        &__items {
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        &__title {
            color: #0B110B;
            font-family: NunitoSans;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
        }
    }

    .btn-toolbar {
        display: flex;
        justify-content: end;
        gap: 12px;

        .content-btn {
            display: flex;
            gap: 8px;
            align-items: center;
        }
    }

    .btn-clear {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        width: fit-content;
        border: none;
        background-color: inherit;
        color: #0B110B;
        font-family: NunitoSans;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
    }

}

.filter-item {
    display: flex;
    gap: 24px;
    align-items: center;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
        padding: 8px 16px;
        border-radius: 8px;
        background: #FFF;
        min-width: 325px;
        max-width: 325px;
    }

    &__placeholder {
        max-width: 240px;
        min-width: 240px;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 1; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;
        hyphens: auto;
        color: #0B110B;
        font-family: NunitoSans;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 13px;
        word-break: break-word;
    }

    .text {
        color: #0B110B;
        font-family: NunitoSans;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        word-break: break-word;
        min-width: 30px;
    }

    .input-group {
        display: flex;
        width: 100%;
        gap: 8px;
        align-items: center;
    }

    .fix-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        min-width: 24px;
        border-radius: 4px;
        background: #D2D9D2;
        border: none;
    }
}