.modal-crm {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__btn-close,
  &__btn-settings {
    background-color: inherit;
    border: none;
    width: 16px;
    height: 16px;
  }

  .tooltip-container {
    position: absolute;
    top: calc(50% - 8px);
    right: 8px;
  }

  &__input-group-main {
    display: flex;
    padding: 16px;
    gap: 12px;
    border-radius: 8px;
    border: 2px solid var(--Stroke-Primary-Primary, #edeffb);
    background: #f8f9fe;
  }

  &__title {
    color: #020617;
    font-family: Rubik;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 114.286% */
    letter-spacing: -0.56px;
  }

  &__table-main-info {
    display: flex;
    gap: 12px;
  }

  &__main-content {
    display: flex;
    order: 1;
    flex-direction: column;
    gap: 24px;
    width: 50%;
    min-width: 800px;
    background: white;
    padding: 24px;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.2);
    height: 100%;
    overflow-y: auto;
    animation: slideIn 0.5s ease-out;
    z-index: 10;
  }

  &__generated-modal {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
    background: white;
    animation: slideIn 0.5s ease-out;
    padding: 24px;
  }

  &__btn-violet {
    background-color: inherit;
    border: none;
    cursor: pointer;
    color: #3900b1;
    text-align: center;
    font-family: Rubik;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    min-width: 105px;

    &:hover {
      transition: 0.3s;
      font-weight: 600;
    }
  }

  &__btn-group {
    display: flex;
    gap: 12px;
    margin-top: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }

  &__input-group {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 12px;
  }

  &__item-parametr {
    display: flex;
    gap: 24px;
    width: 100%;
    align-items: center;
    padding: 0 48px 0 0px;
    position: relative;

    .input-dinamyc-placeholder {
      &:nth-child(1) {
        max-width: 210px;
      }
    }
  }

  &__btn-group-violet {
    display: flex;
    gap: 24px;
    // padding: 0 32px;
  }

  .btn-delete {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    width: 40px;
    border: none;
    border-radius: 8px;
    background: #fef2f2;
  }

  .val-box {
    display: flex;
    flex-direction: column;
    padding-right: 12px;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}
