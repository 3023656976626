.account-settings {
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: 100%;
    
    .title {
        color: var(--main-menu-primary-text, #020617);
        /* Заголовки/h3 */
        font-family: Inter;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
    }
}