.card-variant-product {
    display: flex;
    width: 100%;
    padding: 12px;
    align-items: center;
    border-radius: 8px;
    border: 2px solid #EDEFFB;
    padding: 16px;
    background: #F8F9FE;
    cursor: pointer;
   // overflow: hidden;
    gap: 8px;

    &__img-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        padding: 10px;
        border-radius: 4px;
        border: 2px solid #EDEFFB;
        background: #F8F9FE;
    }

    &__content {
        display: flex;
        width: calc(100% - 24px - 40px - 8*2px);
        flex-direction: column;
        height: 100%;

        .title_product {
            color: #11195D;
            font-family: Rubik;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            display: -webkit-box;
            -webkit-line-clamp: 2; // количество строк
            -webkit-box-orient: vertical;
            overflow: hidden;
            hyphens: auto;
            word-break: break-word;
        }

        .text_article {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: #9298CF;
            font-family: Rubik;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
        }
    }

    .btn-delete {
        border: none;
        background-color: inherit;
    }
}