.modal-search-by-inn {
    max-width: 400px;
    min-width: 360px;
    width: 100%;
    padding: 32px;
    background-color: white;
    border-radius: 16px;
    z-index: 10000;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    -webkit-box-shadow: 0px 16px 39px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 16px 39px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 16px 39px 0px rgba(0, 0, 0, 0.2);

    .text_grey {
        color: #888888;
        font-size: 11px;
    }

    .text_small {
        font-size: 11px;
    }

    .group-btn {
        display: flex;
        gap: 16px;
        justify-content: center;
        margin-top: 8px;

        .btn__green,
        .btn__blue-light {
            margin: 0;
            font-size: 11px;
            max-width: 160px;
            min-width: 160px;
        }
    }
}