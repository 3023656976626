.hints-notifications {
    display: flex;
    width: 100%;
    padding: 8px;
    align-items: flex-start;
    gap: 10px;
    border: 1px solid #EDEFED;
    background: #FAFBFA;
    border-radius: 8px;

    &__text {
        color: #020617;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;

        span {
            color: #51CA64;
        }
    }
}