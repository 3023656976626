.bord-task,
.bord-task_open {
    display: flex;
    min-width: 38px;
    max-width: 38px;
    width: 100%;
    height: 100%;
    padding: 5px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #EDEFED;
    background: #FFF;
    overflow: hidden;
    transition: 0.5s;

    &_open {
        transition: 0.5s;
        max-width: 210px;
    }

    &__btn {
        display: flex;
        width: 26px;
        height: 26px;
        padding: 2px;
        align-items: center;
        justify-content: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 4px;
        background: #F2F2F2;
        border: none;
        color: #000;
        font-family: "Nunito Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        &:hover {
            border: 1px solid #00B45E;
        }
    }

    &__btn-group {
        display: flex;
        width: 100%;
        gap: 6px;
    }

    &__content,
    &__content_open {
        display: none;
        flex-direction: column;
        width: 100%;
        gap: 5px;

        &_open {
            display: flex;
            animation-name: fadeIn1;
            animation-duration: 1s;
        }

        @keyframes fadeIn1 {
            0% {
                opacity: 0;
            }

            50% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
    }

    &__content-btns,
    &__content-btns_open {
        display: none;
        flex-direction: column;
        width: 100%;
        gap: 5px;

        &_open {
            display: flex;
            animation-name: fadeIn1;
            animation-duration: 1s;
        }

        @keyframes fadeIn1 {
            0% {
                opacity: 0;
            }

            50% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
    }
}