.button-copy {
  position: relative;
  display: inline-flex;
  align-items: center;
 // z-index: 1000;

  .btn-copy {
    background-image: url('./img/copy.svg');
    background-color: inherit;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .tooltip_black {
    opacity: 0;
    visibility: hidden;
    background-color: #4a4a4a;
    color: white;
    border-radius: 4px;
    padding: 5px 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    max-width: 160px;
    z-index: 1000;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    border: 1px solid #e0e7f2;
    font-size: 11px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    // left: -160px;
  }

  &:hover .tooltip_black {
    opacity: 1;
    visibility: visible;
  }
}
