.modal-response-template {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 560px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 24px;
    flex-direction: column;
    border-radius: 8px;
    background: var(--white, #FFF);
    max-height: calc(100vh - 120px);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    .title {
        color: #020617;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .text {
        color: #94A3B8;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-top: 4px;
    }

    &__input {
        display: flex;
        height: 40px;
        padding: var(--space-x2, 8px) var(--space-x5, 20px);
        align-items: center;
        gap: 13px;
        width: 100%;
        align-self: stretch;
        border-radius: var(--space-o, 0px);
        border: 0px solid var(--stroke-primary-primary-primary, #E0E7F2);
        background: #F4F8FB;
        margin-top: 24px;

        &:focus {
            outline: none;
            border: 1px solid rgba(24, 144, 255, 1);

            &:hover {
                border: 1px solid rgba(24, 144, 255, 1);
            }
        }

        &:hover {
            border: 1px solid #64748B;
        }

    }

    .template-container {
        display: flex;
        gap: 20px;
        flex-direction: column;
        height: auto;
        overflow: auto;
        margin-top: 24px;

        &::-webkit-scrollbar {
            display: none;
        }

        &__item {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            position: relative;
            cursor: pointer;

            .context-more {
                opacity: 0;
                visibility: hidden;
                transition: 0.2s;
            }

            &:hover {
                .context-more {
                    opacity: 1;
                    visibility: visible;
                    transition: 0.2s;
                }
            }
        }

        .text {
            color: var(--all-text-teritary-text, #94A3B8);
            font-family: Rubik;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
        }
    }

    .btn-add {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    .btn-group {
        display: flex;
        gap: 12px;
    }

    .btn-basic {
        margin-top: 40px;
    }

}