// @import url('../../../font.scss');

.information-article {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    max-width: 100%;
    width: 100%;
    border-radius: 10px;
    border: 1px dashed #000;
    background: #F0F2F4;
    padding: 16px;

    &__text {
        display: inline-block;
        width: 100%;
        color: #191919;
        font-family: "Golos Text";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        line-height: 1.6;
        word-wrap: break-word;

        p,
        span,
        li {
            color: inherit !important;
            font-family: inherit !important;
            font-size: inherit !important;
            font-style: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
        }

        &:empty {
            height: 1lh;

            &::after {
                content: "Введите текст";
                color: #c2cef9;
            }
        }
    }

    &__text {
        margin: 0;
    }

    &__text[contenteditable="true"] {
        outline: none;
    
        &:empty {
            width: 100%;
            height: 1lh;
          //  border: 1px solid red;
    
            &::after {
                content: "Введите заголовок";
                color: #D9D9D9;
            }
        }
    }
}

@media (max-width: 1100px) {
    .information-article {
        font-size: 16px !important;
        margin: 18px 0;

        &__text {
            color: #191919;
            font-family: "Golos Text";
            font-size: 18px;
        }
    }
}

@media (max-width: 430px) {
    .information-article {
        font-size: 16px !important;
        margin: 18px 0;

        &__text {
            font-size: 16px !important;
        }
    }
}