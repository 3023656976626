@import 'src/common/styles/svg_img.scss';

.task-list {
    display: flex;
    flex-direction: column;
    width: auto;
    width: 100%;
    max-width: 100%;
    max-height: calc(100vh - 120px);
    height: 100%;
    gap: 16px;
    border-radius: 8px;

    &__hover-box {
        border-radius: 10px;
        height: 100%;
        margin-right: 8px;
        margin-left: 8px;
    }

    &__top-info {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__sum-tasks {
        display: flex;
        column-gap: 24px;
        flex-wrap: nowrap;
        row-gap: 16px;
        width: auto;
    }

    &__sum-text {
        display: flex;
        gap: 6px;
        align-items: center;
        color: #020617;
        font-family: "Open sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;

        &_span {
            display: flex;
            padding: 4px 4px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            color: #0B110B;
            text-align: center;
            font-family: "Open sans";
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: 14px;
            background: #E1E6E2;
        }
    }

    .task-list__content .bottom-info-box .text .title-input {
        .tooltip-container .tooltip-black {
            top: 0;
            left: 0;
            word-break: break-all;
            max-width: 100%;
        }
    }

    .btn-add-executor {
        background-color: #EDEFED;
        width: 32px;
        height: 32px;
        min-width: 32px;
        background-image: url('./img/account.svg');
        background-repeat: no-repeat;
        background-size: 15px;
        background-position: center center;
        border-radius: 50%;
        margin-right: 8px;
        cursor: pointer;
    }

    .confirm-delete-task-list {
        top: 25%;
        margin-left: auto;
        margin-right: auto;
        height: 160px;
        width: 400px;
        border: none;
        border-radius: 8px;
        -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);

        form {
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;

            .buttons-container {
                display: flex;
                width: 100%;
                gap: 12px;
                justify-content: space-between;
            }
        }
    }

    .title {
        font-size: 15px;
        font-weight: 500;
    }

    .toolbar-top {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        flex-wrap: wrap;
        // padding-right: 16px;
        // padding-bottom: 4px;
        width: 100%;
        position: relative;
        z-index: 2;

        .tab-sort {
            overflow: hidden;
            width: auto;
            display: flex;
            height: 40px;
            border-radius: 8px;
            border: 1px solid #EDEFFB;

            .btn,
            .btn_active {
                display: flex;
                align-items: center;
                gap: 6px;
                justify-content: center;
                height: 100%;
                cursor: pointer;
                border-left: 1px solid var(--table-border, #EDEFFB);
                border: none;
                background-color: #fff;
                padding-left: 20px;
                padding-right: 20px;
                color: var(--Slate-950, #020617);
                white-space: nowrap;
                font-family: Rubik;
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: 14px;
                border-left: 1px solid #EDEFFB;

                &:hover,
                &_active {
                    transition: 0.5s;
                    background: #51CA64;
                    color: white;
                }

                &:nth-child(1) {
                    border: none;
                }

                .arrow,
                .arrow_up {
                    min-width: 16px;
                    width: 16px;
                    height: 16px;

                    &_up {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .btn-sort {
            display: flex;
            align-items: center;
            gap: 8px;
            padding-left: 16px;
            border-radius: 8px;
            border: 1px solid #EDEFED;
            padding-right: 16px;
            min-height: 40px;
            min-width: 150px;
            max-width: 150px;
            color: #020617;
            font-family: Rubik;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 14px;
            background-color: inherit;

            &:hover {
                transition: 0.5s;
                background: #3900B1;
                color: #fff;

                svg {
                    g {
                        path {
                            stroke: #fff;
                        }
                    }
                }
            }
        }

        .members-box {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;

            .btn-close {
                border: none;
                background-color: inherit;
                margin-left: 8px;
            }

            .executor-placeholder {
                border: none;
            }

            .sum_executor {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                min-width: 32px;
                border-radius: 50%;
                height: 32px;
                background-color: #F1F5F9;
                white-space: nowrap;
                color: #2E3552;
                text-align: center;
                font-family: "Open sans";
                font-size: 10px;
                font-style: normal;
                font-weight: 800;
                line-height: 8px;
                cursor: pointer;
                margin-left: 8px;

                span {
                    color: rgba(46, 53, 82, 0.60);
                }
            }

            .light-blue-box {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 120px;
                padding-left: 16px;
                padding-right: 16px;
                background-color: #E6F7FF;
                height: 32px;
                border-radius: 16px;
                cursor: pointer;
            }
        }

        .right-filter-box {
            display: flex;
            gap: 12px;
            align-items: center;

            .input-field-wrapper {
                max-width: 210px;
                min-width: 210px;
            }
        }

        .left-box {
            display: flex;
            gap: 16px;
            align-items: center;
            flex-wrap: wrap;
            flex: 1;

            .selector_wrapper {
                max-width: 225px;
            }

            .checkbox-container__text {
                white-space: nowrap;
            }
        }

        &__box-icon {
            display: flex;
            gap: 8px;
            align-items: center;

            .text_grey {
                color: #AAAAAA;
                font-size: 13px;
            }

            img {
                width: 32px;
                height: 32px;
            }
        }

        .select-box {
            display: flex;
            flex-wrap: nowrap;
            column-gap: 24px;
        }

    }

    .main-task-box {
        display: flex;
        width: 100%;
        min-height: 70vh;
        overflow-y: auto;
        overflow-x: auto;
        position: relative;
        // z-index: 0;


        &::-webkit-scrollbar {
            width: 0;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__content {
        display: flex;
        // gap: 16px;
        width: 100%;
        height: 97.5%;
        align-items: stretch;
        // overflow: hidden;

        &.loading {
            opacity: 0.4;
            pointer-events: none;
        }

        .btn-box {
            margin-top: 8px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }



        .col-task {
            position: relative;
            min-width: 240px;
            max-width: 240px;
            padding: 0px 0px 8px;
            border-radius: 12px;
            height: calc(100% - 51px);

            &__count {
                display: flex;
                min-width: 17px;
                padding: 1px 2px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 2px;
                background: #F1F5F9;
                color: #989898;
                text-align: center;
                font-family: "Open Sans";
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                margin-left: auto;
            }

            &:nth-child(1) {
                margin-left: 0;
            }

            &:nth-last-child(1) {
                margin-right: 0;
            }

            &__title-col {
                display: -webkit-box;
                -webkit-line-clamp: 2; // количество строк
                -webkit-box-orient: vertical;
                width: 107px;
                word-break: break-word;
                overflow: hidden;
                color: #0B110B;
                font-family: "Open Sans";
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                max-height: 33px;
                min-height: 33px;
            }

            &__input-col {
                width: 107px;
                padding: 0;
                transition: all, 0.5s;
                font-weight: 500;
                background-color: unset;
                border: none;
                color: #0B110B;
                font-family: "Open Sans";
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                text-overflow: ellipsis;
                overflow: hidden;
                resize: none;
                max-height: 33px;
                min-height: 33px;
                // display: -webkit-box;
                // -webkit-line-clamp: 2; // количество строк
                // -webkit-box-orient: vertical;
                // overflow: hidden;
                hyphens: auto;
                word-break: break-word;

                &::placeholder {
                    color: #C5CCD3;
                }

                &:focus {
                    outline: none;
                }

                &:hover {
                    background-color: white;
                }
            }

            &__drop-icon {
                cursor: grab;

                &:active {
                    cursor: grabbing;
                }

                &:hover {
                    path {
                        fill: #51CA64;
                    }
                    
                }
            }

            &__task-box-btn {
                width: 100%;
                height: calc(100%);
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding-bottom: 8px;
                overflow: hidden;
                background-color: inherit;
                // border: 1px solid inherit;
                border-top: none;

                .no-tasks {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    align-items: center;
                    width: 100%;
                    height: fit-content;
                    margin-top: 200px;
                    justify-content: center;
                    cursor: pointer;

                    img {
                        width: 45px;
                        height: 45px;
                    }

                    .text {
                        color: #969C99;
                        text-align: center;
                        font-family: "Open Sans";
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 14px;
                    }
                }
            }

            &__task-box {
                width: 100%;
                height: calc(100%);
                display: flex;
                flex-direction: column;
                gap: 12px;
                overflow-y: auto;
            }

            &__header-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 12px;
                width: 100%;

                .btn-state {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;
                    border-radius: 4px;
                    border: 1px solid #EDEFED;
                    background: #FAFBFA;

                    img {
                        position: pointer;
                    }
                }
            }

            &__white {
                background: #FFF;
                padding: 8px;
                background-color: white;
                gap: 8px;
                border-radius: 12px;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: start;
                border-radius: 8px;
                border: 1px solid #EDEFED;
                background: #FFF;
                position: relative;

                .static-info {
                    color: #636D65;
                    text-align: center;
                    font-family: "Open sans";
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 10px;
                    letter-spacing: -0.24px;

                    span {
                        color: #020617;
                        font-weight: 700;
                    }
                }



                .sum-task {
                    color: #8F99A3;
                    text-align: center;
                    font-family: "Open sans";
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }


            }

            &__header {
                display: flex;
                flex-direction: row;
                position: sticky;
                top: 0px;
                width: 100%;
                z-index: 1;

                &:hover {
                    .title-input {
                        color: #00B45E;
                    }
                }
            }

            .remove-task-list {
                transition: all 0.5s;
                width: 24px;
                height: 24px;
                position: absolute;
                right: -8px;
                top: 0px;
                border-radius: 16px;
                border: none;
                cursor: pointer;
                background-image: url(./img/close_icon.svg);
                background-color: #D9D9D9;
                background-repeat: no-repeat;
                background-size: 12px;
                background-position: center;
                opacity: 0;
                visibility: hidden;
            }

            &:has(:hover) .remove-task-list {
                visibility: visible;
                opacity: 1;
            }
        }

        .column-drop-placeholder {
            transition: min-width, 0.5s;
            min-width: 0px;
            border: none;
            height: 100%;
            min-height: 70vh;
            background-color: white;

            &.hover {
                width: 160px;
                min-width: 160px !important;
                background-color: #E7F1FF;
            }
        }

        &[dragOver="true"] {
            .column-drop-placeholder {
                min-width: 80px;
                border-radius: 16px;
                border: grey dotted 2px;
            }
        }

        .col-task_admin {
            min-width: 320px;
            max-width: 320px;
        }
    }

}