// @import url('../../../font.scss');

.article__title {
    display: inline-block;
    width: 100%;
    text-align: left;
    color: #191919;
    font-family: "Open Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.6;
    word-break: break-word;
    padding: 0;

    p,
    span, li {
        color: inherit !important;
        font-family: inherit !important;
        font-size: inherit !important;
        font-style: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
    }

    a {
        color: #1890ff;
        text-decoration: underline;
    }

    &:empty {
        min-height: 58px;
        width: 100%;
        padding-bottom: 18px;

        &::after {
            content: "Введите заголовок";
            color: #D9D9D9;
        }
    }
}

.title__medium[contenteditable="true"] {
    outline: none;
  //  margin: 10px 20px;  
}

.article__title[contenteditable="true"] {
    outline: none;

    &:empty {
        width: 100%;
      //  border: 1px solid red;

        &::after {
            content: "Введите заголовок";
            color: #D9D9D9;
        }
    }
}

@media (max-width: 1100px) {
    .article__title {
        font-size: 28px !important;
       
    }
}

@media (max-width: 430px) {
    .article__title {
        font-size: 24px !important;
       
    }
}