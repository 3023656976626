.modal-strategy-parameters {
    display: flex;
    flex-direction: column;
    max-width: 760px;
    width: 100%;
    padding: 32px 24px;
    align-items: center;
    gap: 24px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 12px 32px 0px rgba(0, 40, 77, 0.12);
    position: relative;

    .title {
        padding-left: 32px;
        padding-right: 32px;
        color: #020617;
        width: 100%;
        text-align: center;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        padding-bottom: 24px;
        border-bottom: 1px solid #E0E7F2;
    }

    .btn-back {
        width: 24px;
        height: 24px;
        background-image: url('./img/back_icon.svg');
        background-size: 24px;
        position: absolute;
        border: none;
        background-color: inherit;
        top: 32px;
        left: 24px;
    }

    .selector_wrapper  {
        max-width: 100%;
        min-height: 56px;

        .css-13cymwt-control, .css-t3ipsp-control {
           min-height: 56px;
        }
    }

    .input-field-group {
        display: flex;
        width: 100%;
        gap: 24px;
        flex-direction: column;

        .MuiFormControl-root {
            margin-top: 0;
        }

        .input-field-description {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 8px;

            .text {
                color: var(--text-teritary-black, #8F99A3);
                font-family: Inter;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0.07px;
            }

            .double-group {
                display: flex;
                width: 100%;
                flex-wrap: nowrap;
                gap: 8px;

            }
        }
    }

    .btn__blue {
        max-width: 560px;
        width: 100%;
        min-width: 210px;
        min-height: 56px;
    }
}