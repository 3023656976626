body:has(.blackout) {
    overflow: hidden;
}

#root:has(.article-main-container) {
    overflow: visible;
}

body:has(.article-main-container) {
    overflow: visible;
}

.article-main-container {
    display: flex;
    gap: 20px;
    width: 100%;

    .flex {
        width: 100%;
    }

    &__breadcrumb-and-tabs {
        display: flex;
        gap: 24px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        min-height: 42px;
    }

    &__content {
        display: flex;
        gap: 20px;
        overflow: visible !important;
    }

    &__breadcrumb-and-content {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 30px;
    }

    .title-box {
        max-width: 100%;
    }

    .date-box {
        flex-direction: row;
    }

    .white-btn {
        min-height: 40px;
    }

    .multilevel-sidebar__box,
    .multilevel-sidebar__box_hide {
        top: 20px;
    }

    .settings__content .list-of-content {
        top: 20px;
    }
}

html:has(.main-container) {
    scroll-padding-top: 40px;
}

@media(max-width: 1000px) {
    body {
        .article-main-container {
            width: 100%;

            .settings__content .list-of-content {
                top: 20px;
            }
        }
    }

}

.breadcrumb {

    &__nav {
        display: flex;
        width: 100%;
        gap: 24px;
        align-items: center;
        justify-content: space-between;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        font-size: 13px;
    }
}

.header-breadcrumb {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__btn-group {
        display: flex;
        gap: 14px;
    }

    &__btn {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        gap: 8px;
    }
}

@media (max-width: 768px) {
    .breadcrumb__nav {
        align-items: start;
        flex-direction: column;
    }
}