.act-verification {
    display: flex;
    max-width: 1066px;
    width: 100%;
    background-color: #fff;
    flex-direction: column;
    padding: 100px 70px;
    aspect-ratio: 4/3;

    span {
        color: blue;
    }

    &__title {
        font-size: 13px;
        font-weight: 800;
        text-align: center;
    }

    &__executor {
        display: flex;
        align-items: center;
        margin-top: 24px;
        gap: 24px;
    }

    &__placeholder {
        min-width: 90px;
        font-size: 9px;
    }

    &__text-bold {
        font-weight: 800;
        font-size: 10px;
    }

    &__text-blue {
        color: blue;
    }

    &__table-group {
        display: flex;
        gap: 4px;
        width: 100%;
    }

    &__table {
        width: 100%;
        margin-top: 24px;
        border-collapse: collapse;

        th {
            border: 1px solid black;
            font-size: 9px;
            font-weight: 800;
        }

        td {
            border: 1px solid black;
            font-size: 8px;

            &:nth-child(1) {
                text-align: center;
            }

            &:nth-child(1n + 3) {
                text-align: right;
            }
        }

        .tr-total {
            border: none;

            td {
                border: none
            }
        }

        .td-left {
            text-align: left !important;
        }
    }

    &__item {
        margin-top: 24px;
    }

    &__text-8 {
        font-size: 8px;

        span {
            color: blue;
        }
    }

    &__text-9 {
        font-size: 9px;
    }

    &__text-10 {
        font-size: 10px;
    }

    &__text-bold {
        font-weight: 800;
    }

    &__text-center {
        margin-left: auto;
        margin-right: auto;
    }

    &__delimetr {
        margin-top: 12px;
        width: 100%;
        height: 2px;
        background-color: black;
        margin-bottom: 12px;
    }

    &__place-signature-group {
        display: flex;
        width: 100%;
        gap: 80px;
        margin-top: 24px;
    }

    &__place-signature {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    &__field {
        margin-top: 24px;
        width: 100%;
        height: 1px;
        background-color: black;
    }
}