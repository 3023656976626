.finance-for-services {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: 100%;
  position: relative;

  .content-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &__title {
    color: #0c2037;
    font-family: Rubik;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.56px;
    margin-bottom: 12px;
  }

  &__header-table {
    display: flex;
    gap: 24px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__table {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 33px 24px;
    border-radius: 10px;
    background: #fff;
  }

  &__header {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 24px;
    justify-content: space-between;
  }

  .drop-box.operation-type {
    .selector_wrapper {
      // width: fit-content;
      width: 300px;
      max-width: 300px;
      z-index: 5;
    }
  }

  .range-group-btn-and-range-box {
    display: flex;
    align-items: end;
    // justify-content: end;
    gap: 1em;
    // margin-left: auto;
    flex-wrap: wrap;

    .selector_wrapper {
      max-width: 124px;
      min-width: 124px;
    }

    .drop-box {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
}
