.modal-delete-connection {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    max-width: 500px;
    min-width: 360px;
    width: 100%;
    min-height: 150px;
    height: auto;
    max-height: calc(100vh - 60px - 60px);
    position: relative;
    background-color: white;
    border-radius: 16px;
    padding: 48px;
    z-index: 2000;
    position: relative;
    overflow: hidden;

    .title {
        font-size: 15px;
        line-height: 24px;
    }

    .group-btn {
        display: flex;
        gap: 24px;
        align-items: center;
        justify-content: center;
        margin-top: 50px;

        .btn__green {
            margin: 0;
            min-width: 120px;
            max-width: 120px;

        }
    }

    .close {
        position: absolute;
        right: 24px;
        top: 24px;
    }
}