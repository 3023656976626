.tooltip-container {
    position: relative;

    .tooltip-light-blue {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        width: max-content;
        z-index: 11;
        // top: 36px;
        // left: 15px;
        padding: 6px;
        border-radius: 2px;
        background: #B6C1F8;
        color: #11195D;
        text-align: center;
        font-family: Rubik;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px
    }

    &:hover {
        .tooltip-light-blue {
            opacity: 1;
            visibility: visible;
            transition: 0.5s;
        }
    }
}