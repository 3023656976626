.container {
    display: flex;
    flex-direction: column;
   position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    //visibility: hidden;
    z-index: 1;
    overflow: hidden;
}

._3Z0JX {
    background: forestgreen;
}

.drop_zone {
    width: 40px;
    height: 100%;
    border-radius: 8px;
    border: 1px #E7E7E7 solid;
    min-height: auto;
    height: 100%;
    height: 40px;
   // padding: 24px;
   // overflow-x: hidden;
   // overflow-y: auto;

   span {
    display: none !important;
   }
}

.box {
    padding: 0;
}

.btn {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: white;
    color: #767676;
    text-align: center;
    text-overflow: ellipsis;
    font-family: NunitoSans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    background-image: url('./img/bx-plus-circle.svg.svg');
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 20px;

    &:hover {
        background-color: white !important;
    }
}

.btn {}