.orders-and-sales-inside-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    width: 100%;
    height: 100%;

    .card-group {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;

        .card-dynamic {
            min-width: 254px;
            width: 254px;
        }
    }
}