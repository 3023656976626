/* Inter */

@font-face {
    font-family: Inter;
    src: url("../public/font/Inter/Inter-Light.ttf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url("../public/font/Inter/Inter-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url("../public/font/Inter/Inter-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url("../public/font/Inter/Inter-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url("../public/font/Inter/Inter-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}

/* Circe Rounded */

@font-face {
    font-family: Circe Rounded;
    src: url("../public/font/CirceRounded-Regular.woff");
    font-weight: 400;
    font-style: normal;
}

/* Montserrat */

@font-face {
    font-family: Montserrat;
    src: url("../public/font/Montserrat-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

/* Rubik */

@font-face {
    font-family: Rubik;
    src: url("../public/font/Rubik/Rubik-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Rubik;
    src: url("../public/font/Rubik/Rubik-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Rubik;
    src: url("../public/font/Rubik/Rubik-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
}

/*  PT-Root */

@font-face {
    font-family: PT-Root;
    src: url("../public/font/Pt-Root-UI/PT-Root-UI_Light.ttf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: PT-Root;
    src: url("../public/font/Pt-Root-UI/PT-Root-UI_Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: PT-Root;
    src: url("../public/font/Pt-Root-UI/PT-Root-UI_Medium.ttf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: PT-Root;
    src: url("../public/font/Pt-Root-UI/PT-Root-UI_Bold.ttf");
    font-weight: 700;
    font-style: normal;
}

/* IBMPlexSans */

@font-face {
    font-family: IBMPlexSans;
    src: url("../public/font/IBMPlexSans/IBMPlexSans-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: IBMPlexSans;
    src: url("../public/font/IBMPlexSans/IBMPlexSans-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Golos Text";
    src: url("../public/font/GolosText/GolosText-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Golos Text";
    src: url("../public/font/GolosText/GolosText-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}

/* Nunito Sans */

@font-face {
    font-family: NunitoSans;
    src: url("../public/font/Nunito_Sans/NunitoSans-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: NunitoSans;
    src: url("../public/font/Nunito_Sans/NunitoSans-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: NunitoSans;
    src: url("../public/font/Nunito_Sans/NunitoSans-Bold.ttf");
    font-weight: 800;
    font-style: normal;
}

// Open Sans

@font-face {
    font-family: "Open Sans";
    src: url("../public/font/OpenSans/OpenSans-Light.ttf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url("../public/font/OpenSans/OpenSans-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url("../public/font/OpenSans/OpenSans-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url("../public/font/OpenSans/OpenSans-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url("../public/font/OpenSans/OpenSans-Bold.ttf");
    font-weight: 700;
}
    