.modal-replenish-the-balance {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 360px;
    max-width: 380px;
    height: fit-content;
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    z-index: 5000;

    img {
        width: 90px;
        height: auto;
    }

    .title {
        color: var(--main-menu-primary-text, #020617);
        text-align: center;
        font-family: Inter;
        font-size: 15px;
        line-height: 24px;
        font-style: normal;
        font-weight: 500;
        margin-top: 12px;

        span {
            color: #475569;
        }
    }

    .text_grey-blue {
        color: var(--text-blue-grey, #89AAC7);
        margin-top: 16px;font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px
    }

    .input-number {
        margin-top: 12px;
    }

    .btn__blue {
        margin-top: 32px;
        min-height: 56px;
        max-width: 100%;
    }
}