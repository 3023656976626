@import 'src/common/styles/mixins.scss';
@import 'src/common/styles/svg_img.scss';

.modal-rich-notification {

    .title {
        font-size: 15px;
        line-height: 24px;
    }
    
    .body {
        display: inline-flex;
        align-items: center;
        width: 100%;
        justify-content: center;
    }

    .modal-add-links {
        z-index: 1001;
        border-radius: 16px;
        overflow: hidden;
        background-color: white;
    }

    .link__blue {
        cursor: pointer;
        padding: 0 0.5rem;
    }

    .btn_bell-round {
        cursor: pointer;
        width: 64px;
        height: 64px;
        @include optimizeSafariSvg(url($bg1));
        border: none;
        background-color: inherit;
        border-radius: 50%;
        position: absolute;
        bottom: 28px;
        left: calc(50% - 32px);
    }

    .blue-box {
        background-color: #E6F7FF;
        height: 56px;
        width: 100%;
        border: 0 0 16px 16px;
    }
}