.bord-card, .bord-card_active {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    padding: 4px 6px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px;
    background: #F2F2F2;
    border: 1px solid #F2F2F2;
    min-width: 196px;
    cursor: pointer;

    &:hover, &_active {
        border: 1px solid #00B45E;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 12px;
    }

    &__title {
        color: #000;
        font-family: "Nunito Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    &__info {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    &__sum-task {
        color: #858585;
        font-family: "Nunito Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    &__date-box {
        display: flex;
        gap: 4px;
        align-items: center;
    }

    &__date {
        display: flex;
        padding: 2px 4px;
        align-items: center;
        gap: 2px;
        border-radius: 4px;
        background: #FFF;
        color: #636D65;
        font-family: "Golos Text";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }
}