.title-content-editable,.title-content-editable_edit {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background-color: transparent;

  &__title {
    white-space: nowrap;
    color: #000000;
    font-family: Inter, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
  }

  &__area {
    outline: none;
    border: none;
    background-color: transparent;
    width: 100%;
    color: #64748b;
    font-family: Inter, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    word-wrap: break-word;
    white-space: pre-wrap;

    &:empty:before {
      content: attr(data-placeholder);
      color: #a0a0a0;
      pointer-events: none;
    }

    &:focus {
      outline: none;
    }
  }

  &_edit {
    background: #f1f5f9;
    transition: background 0.3s;
  }

  &_disabled {
    cursor: default;

    &:hover {
      background: transparent;
    }
  }

  &_requared .title-content-editable__area:empty:before {
    color: #f55252;
  }

  &_edit__countSymbol {
    color: #636d65;
    font-size: 10px;
    font-family: NunitoSans, sans-serif;
    font-weight: 400;
    line-height: 12px;
  }
}
