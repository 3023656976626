.modal-selection-social-media {
    display: flex;
    width: 100%;
    gap: 12px;

    &__title {
        color: #2B2B46;
        font-family: "Golos Text";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    &__content {
        display: flex;
        height: 100%;
        width: 100%;
        gap: 24px;
        flex-wrap: wrap;
    }

    &__item-social {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 8px;
        width: calc(50% - 12px);
    }

    &__info {
        display: flex;
        align-items: center;
        gap: 8px;
        overflow: hidden;

        img {
            width: 24px;
            height: 24px;
            object-fit: contain;
        }
    }

    &__name-social {
        color: #2B2B46;
        font-family: "Golos Text";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        display: -webkit-box;
        -webkit-line-clamp: 1; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
        hyphens: auto;
    }

    .btn-basic {
        margin-top: auto;
    }
}