.blackout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.modal_hint_container {
  width: 750px;
  height: 90%;
  background: white;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;

  .modal-hint__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 1em;
    border-bottom: 1px solid #e0e7f2;

    h2 {
      margin: 0;
      font-family: 'Rubik', sans-serif;
      font-size: 20px;
      font-weight: 500;
      color: #020617;
    }

    .modal-hint__close-btn {
      background: url('../ModalCreatingAnAdvertisingCampaignV2/img/close_icon.svg')
        no-repeat center;
      background-size: contain;
      border: none;
      width: 20px;
      height: 20px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.table-content {
  flex: 1;
  overflow-y: auto;
  padding: 1em;

  &::-webkit-scrollbar {
    // display: none;
  }
}
