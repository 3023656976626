.modal-unboarding-for-space-1,
.modal-unboarding-for-space-2 {
    display: flex;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 458px;
    width: 100%;
    min-width: 300px;
    margin-left: 200px;
    margin-right: auto;
    flex-direction: column;
    padding: 10px 14px;
    flex-direction: column;
    gap: 8px;
    border-radius: 10px;
    max-width: 300px;
    background: #FFF;
    position: absolute;
    top: 60px;
    left: 220px;
    animation: show 400ms;
    opacity: 1;

    &__title {
        color: #000;
        font-family: NunitoSans;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
    }

    &__description {
        color: #000;
        font-family: NunitoSans;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;

        a {
            color: #0009E8;
            text-decoration: underline;
        }
    }

    img {
        margin-left: auto;
        margin-right: auto;
        width: 272px;
        height: 192px;
    }
}

.modal-unboarding-for-space-2,
.modal-unboarding-for-space-3,
.modal-unboarding-for-space-4 {
    max-width: 380px;
}

@keyframes show {
    0% {opacity: 0;}
    100% {opacity: 1;}
 }