.textarea-component {
    width: 100%;
    margin-top: 28px;
    position: relative;

    .placeholder {
        position: absolute;
        left: 16px;
        top: 8px;
        color: var(--main-menu-secondary-text, #94A3B8);
        /* Fields/Text title */
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .textarea {
        display: flex;
        width: 100%;
        height: 137.999px;
        padding: 23px 20px;
        align-items: center;
        align-self: stretch;
        border-radius: var(--space-x-2, 8px);
        border: 1px solid var(--stroke-primary-primary, #E0E7F2);
        background: var(--white, #FFF);
        min-height: 130px;
        resize: vertical;

        &:focus {
            outline: none;
            border-radius: var(--space-x-2, 8px);
            border: 1px solid rgba(24, 144, 255, 1);
            -webkit-box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
            -moz-box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
            box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
        }

        &::placeholder {
            color: var(--text-teritary-inactive, #C0C7CD);
            /* Fields/Text placeholder/L */
            font-family: Inter;
            font-size: 13px;
            font-weight: 400;
            font-style: normal;
            line-height: 24px;
            /* 150% */
        }
    }
}