.modal-first-connection {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 760px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    .title {
        color: #0B110B;
        text-align: center;
        font-family: NunitoSans;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-top: 50px;
    }

    .text {
        color: #0B110B;
        text-align: center;
        font-family: NunitoSans;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-top: 24px;

        b {
            font-weight: 700;
        }

        .text {
            color: #475569;
        }

        .link-blue {
            text-decoration: underline;
            color: #0009E8;
        }
    }

    .btn-group {
        display: flex;
        width: 100%;
        gap: 12px;
        margin-top: 40px;
    }

    .cases-item {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &__content {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 24px 24px;
            width: 200px;
            height: 200px;
            border-radius: 8px;
            background-color: #E6F7FF;
        }
    }
}