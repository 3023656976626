.modal-add-mp-simple {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  width: 100%;
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  max-height: calc(100vh);

  &__header,
  &__input-box {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    width: 100%;
    padding: 10px 14px;
  }

  &__header {
    border-bottom: 1px solid var(--Stroke-Primary, #EDEFED);
  }

  &__title {
    overflow: hidden;
    color: #0B110B;
    text-overflow: ellipsis;
    font-family: NunitoSans;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }

  &__btn-close {
    background-color: inherit;
    border: none;
    cursor: pointer;
  }

  &__btn-box {
    padding: 10px 14px;
    width: 100%;
    border-top: 1px solid #EDEFED;
    background: #FFF;
    margin-top: auto;
  }

  &__content {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100% - 61px - 61px - 48px );
  }

  &__btn {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .top-panel {
    display: flex;
    gap: 12px;
    align-items: center;

    .input-field-wrapper {
      max-width: 100%;
    }
  }
}