.chat-bot {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;

    &__title-main {
        color: #0C2037;
        font-family: Rubik;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
    }

    &__toolbar-top {
        display: flex;
        width: 100%;
        align-items: center;
    }

    &__btn {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }
}