.products-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    align-items: flex-start;

    .title-main-content {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-bottom: 12px;

        .title {
            font-size: 22px;
            color: #020617;
        }
    }

    .blue-btn {
        justify-self: flex-end;
    }

    &__header {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 16px;
        flex-wrap: wrap;
        justify-content: space-between;

        .filter-group {
            display: flex;
            gap: 12px;
            align-items: center;
        }
    }
}