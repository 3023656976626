.modal-add-produts-side {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    max-width: 800px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    border-radius: 8px 0px 0px 8px;
    background: var(--white, #FFF);

    &__header {
        display: flex;
        gap: 24px;
        align-items: center;
        padding: 14px;
        width: 100%;
        justify-content: space-between;
        border-bottom: 1px solid var(--Stroke-Primary, #EDEFED);
    }

    &__btn-close {
        border: none;
background-color: inherit;
    }

    &__title {
        overflow: hidden;
        color: #0B110B;
        text-overflow: ellipsis;
        font-family: NunitoSans;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
    }

    &__title-product {
        padding: 14px;
        overflow: hidden;
        color: #00B45E;
        text-overflow: ellipsis;
        font-family: NunitoSans;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
    }

    &__content-btn {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 8px;
        justify-content: center;
    }
}