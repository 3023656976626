.modal-about-order {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 1360px;
    height: 100%;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    &__header {
        display: flex;
        justify-content: space-between;
        gap: 24px;
        width: 100%;
        padding: 28px 32px 18px;
        border-bottom: 1px solid #EDEFED;

        .btn-group {
            display: flex;
            gap: 12px;
            align-items: center;
            flex-wrap: wrap;
            align-items: end;
            justify-content: end;

            .btn-print {
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }

        .main-info {
            display: flex;
            overflow: hidden;
            align-items: center;
            column-gap: 45px;
            row-gap: 12px;
            flex-wrap: wrap;
            justify-content: start;

            .title {
                color: #0B110B;
                font-family: NunitoSans;
                font-size: 33px;
                font-style: normal;
                font-weight: 700;
                line-height: 44px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .status {
                display: flex;
                align-items: center;
                color: #94A3B8;
                text-align: center;
                font-family: NunitoSans;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 11px;
                border-radius: 4px;
                background: #F1F5F9;
                height: 22px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding-left: 8px;
                padding-right: 8px;
            }
        }
    }

    &__content {
        display: flex;
        gap: 26px;
        width: 100%;
        padding: 32px;
        overflow: auto;
        margin-bottom: 32px;

        .left-content,
        .right-content {
            width: 45%;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .title {
                color: #0B110B;
                font-family: NunitoSans;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;

                span {
                    color: #0B110B;
                    font-weight: 900;
                }
            }
        }

        .left-content {
            width: 45%;
            padding-bottom: 24px;


            .order-info {
                display: flex;
                flex-direction: column;
                gap: 16px;
                border-radius: 8px;
                border: 1px solid #F4F6F4;
                background: #FAFBFA;
                width: 100%;
                height: 100%;
                padding: 8px 12px;

                &__item {
                    display: flex;
                    gap: 24px;

                    .parametr,
                    .value {
                        color: #636D65;
                        font-family: NunitoSans;
                        word-break: break-word;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        width: calc(50% - 12px);
                    }

                    .value {
                        text-align: end;
                        color: #0B110B;
                        font-weight: 600;
                    }
                }
            }
        }

        .right-content {
            width: 55%;
            padding-bottom: 24px;

            .shipment-of-goods,
            .drop-zone-white {
                display: flex;
                flex-direction: column;
                gap: 12px;
                border-radius: 8px;
                border: 1px solid #F4F6F4;
                background: #FAFBFA;
                width: 100%;
                height: auto;
                padding: 12px;

                .drop-zone-plus {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 4px;
                    border-radius: 8px;
                    border: 1px dashed #DCE5DD;
                    background: #FFF;
                    position: relative;

                    .add-icon {
                        border: 1px solid #CBEBD0;
                        border-radius: 50%;
                        padding: 4px;
                        width: 24px;
                        height: 24px;
                    }

                    input {
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        opacity: 0;
                        cursor: pointer;
                    }
                }
            }

            .title_bold {
                color: #0B110B;
                font-family: NunitoSans;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
            }

            .drop-zone-white {
                background-color: #EDF3F6;
                
                &__content {
                    display: flex;
                    width: 100%;
                    padding: 8px 16px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 4px;
                    border-radius: 8px;
                    border: 1px dashed #DCE5DD;
                    background: #FFF;
                    cursor: pointer;
                    position: relative;

                    input {
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        opacity: 0;
                        cursor: pointer;
                    }

                    .text {
                        color: #484F48;
                        text-align: center;
                        font-family: NunitoSans;
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 14px;
                        max-width: 90%;
                    }
                }
            }
        }
    }
}

.order-card-drop {
    display: flex;
    flex-direction: column;
    padding: 8px 12px;
    padding-left: 40px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #EDEFED;
    background: #FFF;
    position: relative;

    &__header {
        display: flex;
        gap: 50px;
        padding-bottom: 8px;
        border-bottom: 1px solid var(--Stroke-Primary, #EDEFED);
        align-items: center;

        .left-card-info {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 12px;

            .img-box {
                width: 45px;
                min-width: 45px;
                height: 45px;
                border-radius: 4px;
                border: 1px solid #EDEFED;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .title {
                color: #0B110B;
                font-family: NunitoSans;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                // display: -webkit-box;
                // -webkit-line-clamp: 3; // количество строк
                // -webkit-box-orient: vertical;
                // overflow: hidden;
                // hyphens: auto;
                // min-height: 48px;
            }

            .text {
                white-space: nowrap;
                color: #0B110B;
                font-family: NunitoSans;
                font-size: 11px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px;

                span {
                    color: #B4B8B4;
                    font-weight: 500;
                }
            }
        }

        .right-card-info {
            display: flex;
            flex-direction: column;
            align-items: end;
            gap: 8px;
            min-width: 120px;

            &__top {
                display: flex;
                align-items: center;
                gap: 8px;

                .btn-points {
                    border: none;
                    background-color: inherit;
                    cursor: pointer;
                }
            }

            .status {
                display: flex;
                align-items: center;
                color: #94A3B8;
                text-align: center;
                font-family: NunitoSans;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 11px;
                border-radius: 4px;
                background: #F1F5F9;
                // height: 22px;
                // white-space: nowrap;
                // text-overflow: ellipsis;
                // overflow: hidden;
                padding-left: 8px;
                padding-right: 8px;
            }

            .text {
                white-space: nowrap;
                color: #636D65;
                text-align: right;
                font-family: NunitoSans;
                font-size: 10px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
            }
        }


    }

    &__content {
        width: 100%;
        display: flex;
        gap: 24px;
        padding-top: 20px;
        align-items: center;

        .text_category,
        .text_brand {
            width: calc(20%);
            overflow: hidden;
            color: #0B110B;
            font-family: NunitoSans;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            word-wrap: break-word;
        }

        .item-info {
            display: flex;
            flex-direction: column;
            gap: 4px;
            width: calc(20%);

            .parametr {
                color: #636D65;
                font-family: NunitoSans;
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
            }

            .val {
                color: #020617;
                font-family: NunitoSans;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: 14px;
                letter-spacing: 0.14px;
                word-wrap: break-word;

                span {
                    color: #A1D9A3;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 700;
                }
            }
        }
    }

    .drag-zone {
        position: absolute;
        top: calc(50% - 12px);
        left: 12px;
        cursor: grab;
    }
}