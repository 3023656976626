.modal-transfer-order{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 2000;
    display: flex;
    max-width: 460px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    gap: 40px;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    .title {
        color: var(--Text-Primary, #0B110B);
        text-align: center;
        font-family: NunitoSans;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
    }

    .text_grey-blue {
        border-radius: 8px;
        font-size: 13px;
        margin-top: 16px;
    }

    .btn-group {
        display: flex;
        width: 100%;
        gap: 12px;
    }

    .selector_wrapper {
        max-width: 100%;
    }
}