.modal-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 560px;
    width: 100%;
    max-width: 360px;
    height: fit-content;
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
    padding: 32px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    z-index: 5000;

    img {
        width: 110px;
        height: 110px;
    }

    .title {
        margin-top: 24px;
        color: var(--text-primary-blue, #020617);
        text-align: center;
        font-family: Inter;
        font-size: 15px;
        line-height: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
    }

    .text_grey-blue {
        margin-top: 12px;
        text-align: center;
        color: var(--main-menu-teritary-text, #94A3B8);
        text-align: center;
        /* Button link/Medium */
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    .loading-dynamics {
        margin-top: 36px;
        width: 100%;
        height: 8px;
        border-radius: 4px;
        background: var(--notification-tips-verification-bg, #EDEDFE);

        .dynamics {
            width: 0%;
            height: 100%;
            border-radius: 4px;
            background: var(--primary-elements-hover, #0E86D7);
            transition: 2s;
        }
    }
}