.article-view {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 100%;

    &__info-article {
        display: flex;
        width: 100%;
        column-gap: 18px;
        row-gap: 16px;
        align-items: center;
        flex-wrap: wrap;

        .share__drop-container {
            right: 0;
            left: auto;
        }
    }

    &__author-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        min-width: 24px;
        background-color: #27BC62;
        color: #FFF;
        text-align: center;
        font-family: NunitoSans;
        border-radius: 50%;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        margin-left: auto;
    }

    &__share-and-author {
        display: flex;
        align-items: center;
        margin-left: auto;
        gap: 16px;
        flex-wrap: wrap;
    }

    &__author-element {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #8E8E9A;
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    &__date-box,
    &__time-box,
    &__view-box {
        display: flex;
        align-items: center;
        gap: 6px;
    }

    &__text-grey {
        color: #8E8E9A;
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    &__content, &__content-modal {
        display: flex;
        min-height: 70vh;
        width: 100%;
        gap: 30px;
        justify-content: space-between;
        background-color: #fff;
        padding: 10px 160px;
        border-radius: 12px;
        border: 1px solid #EDEFFB;

        .list-of-content {
            display: none;
        }

        .title-box {
            display: flex;
            gap: 12px;
            width: 100%;
            padding: 28px 0px 28px 0px;

            .title-input {
                border: none;
                outline: none;
                width: 100%;
                -webkit-user-modify: read-write;
                overflow-wrap: break-word;
                -webkit-line-break: after-white-space;
                flex-grow: 1;
                overflow-y: auto;
                resize: none;
                background-color: inherit;

                &::placeholder {
                    color: #D9D9D9;
                }
            }

            .btn-group {
                display: flex;
                align-items: center;
                gap: 4px;
            }
        }

        .title__big[contenteditable="true"] {
            outline: none;
        }
    }

    &__content-modal {
        padding: 10px 48px;
    }

    // Блок с рекомендациями

    &__this-will-be-interesting {
        background-color: #fff;
        gap: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 20px;
        border-radius: 12px;
    }

    &__this-will-be-interesting-title {
        color: #2B2B46;
        font-family: "Golos Text";
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
    }

    &__this-will-be-interesting-content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 20px;

        .tile-short {
            max-width: calc(50% - 20px);
        }
    }
}

@media (max-width: 1100px) {
    .article-view {
        gap: 14px;

        &__text-grey_author {
            display: none;
        }

        .title-box {
            padding: 24px 0px 24px 0px;
        }

        .title__big {
            font-size: 38px;
        }

        &__this-will-be-interesting {
            padding: 8px;
        }

        &__this-will-be-interesting-content {
            gap: 28px;

            .tile-short {
                max-width: 100%;
            }
        }

        &__content {
            padding: 16px;

            .title__big {
                font-size: 32px;
                line-height: 42px;
            }
        }
    }
}


@media (max-width: 430px) {
    .article-view {

        &__info-article {
            display: flex;
            column-gap: 14px;
            row-gap: 14px;
            align-items: center;
            flex-wrap: wrap;
        }

        &__this-will-be-interesting {
            padding: 8px;
        }

        &__this-will-be-interesting-content {
            gap: 6px;

            .tile-short {
                max-width: 100%;
            }
        }

        &__content {
            padding: 8px;

            .title__big {
                font-size: 26px;
                line-height: 28px;
            }
        }
    }
}