.privacy-policy {
    display: flex;
    gap: 32px;
    max-width: 1200px;
    width: 100%;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    background-color: #FBFBFB;
    border-radius: 16px;
    padding: 24px;
    align-items: start;
    margin-bottom: 16px;
    overflow-anchor: none

    &__tab-box {
        width: 300px;
        min-height: 200px;
        background-color: white;
        border: 1px solid #D9D9D9;
        border-radius: 16px;
        padding: 16px;
    }

    &__tab-item {
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid #D9D9D9;

        .link__grey {
            font-size: 15px;
            color:#888888;

            &:hover {
                color: #020617;
                transition: 0.3s;
            }
        }
    }

    &__content {
        max-width: 800px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .title {
            font-size: 36px;
            font-weight: 500;
        }

        .text {
            font-size: 15px;
            margin-top: 20px;

            b {
                font-weight: 500;
            }

            &_medium {
                font-size: 17px;
                font-weight: 500;
                margin-top: 20px;
            }

            &_big {
                margin-top: 20px;
                font-size: 22px;
                font-weight: 500;
            }
        }
    }
}