.modal-editing-rules {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1000;
    max-width: 425px;
    width: 100%;
    min-width: 425px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    max-height: calc(100vh - 40px);
    height: 300px;
    max-height: fit-content;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    padding: 1em;
    align-items: flex-end;


    &__header {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        gap: 12px;
        padding-bottom: .5em;
        border-bottom: 2px solid #EDEFED;

        .title {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            color: #020617;
            font-family: Rubik;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            margin-right: calc(190px + 12px);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        max-width: 420px;
        height: 100%;
        max-height: calc(100% - 20px);
        gap: 16px;
        width: 100%;
        padding-top: 1em;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .selector_wrapper {
         
            margin-top: 8px;
        }
        .promotion-rules-parametr__content{
            display: flex;
            flex-direction: column;
            gap: .5em;
        }
    }

}