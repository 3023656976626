.breadcrumb {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    color: #2B2B46;
    font-family: NunitoSans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &__nav {
        display: block;
        width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        color: #AAAAAA;
        font-size: 14px;
    }

    li:last-child {
        &::after {
            display: none;
        }
    }

    &__link {
        display: flex;
        align-items: center;

        a {
            color: rgb(5, 55, 235);
        }

        .img-home {
            margin-right: 8px;
        }

        &::after,
        &::before {
            content: "/";
            display: inline-block;
            // background-image: url("./img/drop-right.svg");
            // background-position: center;
            // background-repeat: no-repeat;
            // width: 6px;
            // height: 12px;
            vertical-align: middle;
            margin-left: 8px;
            margin-right: 8px;
            color: #2B2B46;
            font-family: NunitoSans;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }

        &::before {
            content: "";
            display: none;
            transform: rotate(180deg);
        }
    }

    .element-title {
        //  color: #88306D;
    }
}

.header-breadcrumb {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .title {
        color: #2B2B46;
        font-family: "Golos Text";
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;

        .text_grey {
            display: inline-block;
            margin-left: 16px;
            color: var(--unnamed, #AAA);
            font-family: "Golos Text";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}