.landing {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-left: auto;
    margin-right: auto;

    &__img-changes {
        display: block;
        margin-left: auto;
       max-height: 100%;
    }

    &__content {
        display: block;
        width: 100%;
        height: auto;
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
    }

    &__box-long {
        display: flex;
        gap: 16px;
        margin-left: auto;
        margin-right: auto;
    }

    &__box-small {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        max-width: 896px;
        margin-left: auto;
        margin-right: auto;
    }

    &__content-changes-box {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 16px;
        flex-direction: column;
        justify-content: space-between;

        & .btn__green {
            width: 100%;
            max-width: 100%;
            font-size: 22px;
            height: 56px;
        }
    }

    &__block_small {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 440px;
        height: 360px;
        background-color: white;
        border-radius: 16px;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
    }

    &__block_long {
        display: flex;
        width: 400px;
        height: 736px;
        border: 1px solid #F0F0F0;
        border-radius: 16px;
        padding: 30px 16px ;
        background-color: white;
        flex-direction: column;
        justify-content: space-between;

        & .title {
            text-align: center;
        }
    }

    &__box-list {}

    &__box-lists {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-left: 16px;
        margin-top: 16px;
    }

    &__list-item {
        list-style-type: disc;
        font-size: 13px;
    }

    &__header-box {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__content-changes {
        display: flex;
        width: 100%;
        height: 100%;
    }

    &__content-item {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.img-2 {
    display: block;
    max-width: 230px;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.img-1 {
    display: inline-block;
    max-height: 230px;
    max-width: 320px;
    margin-left: auto;
}

.bkg {
    background-image: url("../../../public/img/img-3.png");
    background-size: 440px;
}

.title_white {
    color: white;
    text-align: center;
}

.box-btn {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn__play {
    cursor: pointer;
    display: inline-block;
    width: 67px;
    height: 87px;
    background-color: inherit;
    //background-image: url('../../../public/img/play.png');
    background-repeat: no-repeat;
    background-position: center;
    border: none;

    &:hover .play {
        fill-opacity: 1;
        transition: all 0.5s;
    }
}

.play {
    fill-opacity: 0.7;
    transition: all 0.3s;
    margin-top: -60px;
}

.btn-circle-group {
    display: flex;
    justify-content: center;
    gap: 12px;
    width: 100px;
    height: 8px;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
}

.btn__circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    background-color: #D9D9D9;

    &_active {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        background-color: #888888;
    }

    &:hover {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        background-color: #888888;
        transition: background-color 0.3s;
    }
}

.bkg-grey {
    background-color: #E7E7E7;
}



.marketplaceGroup {
    display: inline-block;
    width: 100%;
    max-width: 320px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}