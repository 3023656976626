body {
    .tooltip-yellow, .tooltip-yellow_active {
        display: flex;
        align-items: start;
        gap: 12px;
        padding: 8px 12px;
        border-radius: 8px;
        width: 100%;
        background-color: #FFF4E4;
        color: #E99518;
        font-size: 13px;
        border: 1px solid var(--orange-200, #E99518);
        opacity: 0;
        visibility: hidden;
        transition: 1.5s;
        animation: hidden 1.5s forwards;

        @keyframes hidden {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        &_active {
            opacity: 1;
            visibility: visible;
            transition: 2s;
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .title {
                color: var(--text-accent-blue, #E99518);
                font-family: Inter;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }

            .text_grey-yellow {
                color: #FDBA74;
                font-size: 13px;
            }
        }

        .close {
            margin-left: auto;
            cursor: pointer;
        }
    }
}