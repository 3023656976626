.chat-widget-circle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #22c55e;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.chat-modal {
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 370px;
  height: 500px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.chat-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #22c55e;
  color: white;
  border-radius: 8px 8px 0 0;
  & > button {
    width: 20px;
    height: 20px;
  }
}

.chat-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.chat-message {
  display: flex;
}

.bot-message {
  justify-content: flex-start;
}

.user-message {
  justify-content: flex-end;
}

.message-content {
  background-color: #f1f1f1;
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 14px;
  color: #333;
  max-width: 70%;
}

.user-message .message-content {
  background-color: #22c55e;
  color: white;
}

.bot-message .message-content {
  background-color: #e5e5ea;
  color: black;
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: white;
}

.chat-input input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.chat-input button {
  margin-left: 5px;
  padding: 8px 12px;
  background-color: #22c55e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.chat-typing-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  gap: 5px;
}

.chat-typing-indicator span {
  width: 8px;
  height: 8px;
  background-color: #ccc;
  border-radius: 50%;
  animation: typing-bounce 1.5s infinite ease-in-out;
}

.chat-typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.chat-typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typing-bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
