@import './mixins.scss';
@import './svg_img.scss';

body {

    .btn__green,
    .btn__red,
    .btn__white-blue,
    .btn__blue,
    .btn__grey,
    .btn__bej,
    .btn__green-and-white,
    .btn__blue-light,
    .btn__orange {
        width: 100%;
        min-height: 32px;
        font-size: 12px;
        max-width: 200px;
        border-radius: 8px;
        background-color: #27BC62;
        margin-top: unset;
        border: none;
        color: white;
        font-family: "Inter";
        min-width: 120px;
        padding: 4px 20px;
        cursor: pointer;

        &[disabled] {
            position: relative;
            cursor: not-allowed;
            background-color: #E1E6EA;
            color: #C5C0DB;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;

            &:hover {
                box-shadow: none;
                background-color: #E1E6EA;
                box-shadow: none;
            }

            &:active {
                border: none;
            }

        }

        &:hover {
            -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
            transition: all 0.3s;
        }
    }

    .btn__green {
        min-height: 40px;
    }

    .btn__white {
        border-radius: 8px;
        color: #020617;
        border: 1px solid #E0E7F2;

        &:hover {
            background-color: #E0E7F2;
            transition: all 0.3s;
        }
    }

    .btn__white-blue {
        color: #1890FF;
        background: white;
        border: 1px solid #1890FF;

        &:hover {
            background-color: #E0E7F2;
            transition: all 0.3s;
        }
    }

    .btn__bej {
        background-color: #D1A77E;
    }

    .btn__blue {

        background-color: #1890FF;
        margin-top: 0;
        min-height: 40px;
        box-shadow: 2px 4px 10px 0px rgba(24, 144, 255, 0.25);

        &:hover {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            background-color: #005DB2;
            transition: 0.5s;
            box-shadow: none;
        }

        &:active {
            transition: all 0.5s;
            border: 2px solid #1890FF;
        }

        &[disabled] {
            position: relative;
            cursor: not-allowed;
            background-color: #E1E6EA;
            border: none;
            color: #A9B3BC;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;

            &:hover {
                box-shadow: none;
                border: none;
                background-color: #E1E6EA;
                box-shadow: none;
            }

            &:active {
                border: none;
            }

        }
    }

    .btn__grey {
        background-color: #F0F0F0;
        color: #020617;
    }

    .back-button {
        border: none;
        background: none;
        cursor: pointer;
    }

    .btn__green-and-white {
        background-color: white;
        border: 1px solid#27BC62;
        color: #27BC62;
    }

    .btn__red {
        background-color: #F87171;
        color: white;

        &:hover {
            background-color: #EF4444;
            transition: 0.5s;
        }

        &:active {
            background-color: #F87171;
            border: 2px solid #EF4444;
            transition: 0.5s;
        }
    }

    .btn__delete-light {
        width: 16px;
        height: 16px;
        cursor: pointer;
        background-image: url('../img/icon-delete.svg');
        border: none;
        background-position: center;
        background-repeat: no-repeat;
        background-color: inherit;
        background-size: 14px;

        &[disabled] {
            cursor: not-allowed;
        }
    }

    .btn__blue-light {
        background-color: #E6F7FF;
        color: #AAAAAA;
    }



    .input-field-text {
        border: 1px solid #E0E7F2;
        height: 48px;
        border-radius: 8px;
        width: 100%;
        min-width: 100px;
        max-width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        font-family: 'Inter';
        font-size: 11px;
        color: #020617;
        cursor: pointer;


        &:hover {
            outline: 1px solid #E0E7F2;
        }


        &::placeholder {
            font-family: 'Inter';
            font-size: 11px;
            color: grey;
        }

        &:focus {
            outline: none;
            //   border: 1px solid #94A3B8;
            -webkit-box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
            -moz-box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
            box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
        }
    }


    .btn__drop,
    .btn__drop_hide {
        background-image: url($drop);
        border: none;
        background-color: inherit;
        background-size: 14px;
        background-repeat: no-repeat;
        background-position: center;
        width: 26px;
        height: 26px;
        transform: rotate(180deg);
        border-radius: 50%;
        border: none;
        background-color: white;
    }

    .btn__drop_hide {
        transform: rotate(0deg);
        -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        transition: all 0.3s;
    }

    .btn__orange {
        background-color: #FCC089;
        border-radius: 8px;
        font-size: 13px;
        line-height: 16px;
        color: #020617;
        border: 1px solid #1890FF;

        &:hover {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            background-color: #ffb26a;
        }
    }
}