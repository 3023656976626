.logo {
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: inherit;

    // img {
    //     width: 30px;
    //     height: 30px;
    // }

    .title_logo {
        color: #00B45E;
        font-family: NunitoSans;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
    }
}