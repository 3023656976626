.side-info-block {
    display: flex;
    width: 230px;
    padding: 8px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 8px;
    background: #53B979;

    &__title {
        color: #FFF;
        font-family: "Golos Text";
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    &__description {
        color: #FFF;
        font-family: "Golos Text";
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
    }
}