.modal-warning {
    &__buttons-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        width: 100%;
        padding-bottom: 40px;
    }

    .title {
        font-size: 15px;
        line-height: 24px;
    }
}