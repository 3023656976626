.parsing-advertising-by-keywords {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    width: 100%;
    max-width: 100%;

    &__title-main {
        color: #020617;
        font-family: Rubik;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
    }

    &__toolbar-top {
        display: flex;
        width: 100%;
        gap: 24px;
        align-items: flex-start;
        justify-content: space-between;

        .filters-container {
            flex: 1;
        }
    }

    &__slider-box {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
    }

}