@import 'src/common/styles/svg_img.scss';

.download-history {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;
    
    .title_main {
        color: #0C2037;
        font-family: Rubik;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; 
        letter-spacing: -0.56px;
        margin-bottom: 12px;
    }
}