.modal-chat-bot {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 32px;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 750px;
    width: 100%;
    min-width: 340px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    border-radius: 8px;
    background: #FFF;
    padding: 24px;
    max-height: calc(100vh - 40px);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    &__header {
        display: flex;
        gap: 24px;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        color: #000;
        font-family: NunitoSans;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
    }

    &__btn-close {
        cursor: pointer;
        border: none;
        background-color: inherit;
    }
}