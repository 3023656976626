.modal-create-board {
    display: flex;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 900px;
    width: 100%;
    padding: 24px;
    min-width: 300px;
    flex-direction: column;
    gap: 24px;
    border-radius: 10px;
    background: #FFF;
    animation: show 400ms;
    opacity: 1;
    min-height: 500px;

    &__header {
        display: flex;
        gap: 32px;
    }

    &__title {
        flex: 1;
        white-space: nowrap;
        color: #020617;
        font-family: "Nunito Sans";
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
    }

    &__row {
        display: flex;
        flex-direction: column;
        gap: 14px;
    }

    &__title-medium {
        color: #020617;
        font-family: "Nunito Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    &__description {
        color: #000;
        font-family: NunitoSans;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
    }

    &__btn-close {
        border: none;
        background-color: inherit;
        cursor: pointer;
    }
}