.alphabetical-search {
    display: flex;
    gap: 40px;
    justify-content: start;
    border-radius: 4px;
    margin-top: 40px;
    align-items: start;

    &__btn {
        color: #1890FF;
        border: 1px solid #E0E7F2;
        font-size: 13px;
        padding: 8px;
        background-color: white;
        border-radius: 1px solid #E0E7F2;
        white-space: nowrap;
        height: auto;
        min-width: 26px;
        border-radius: 4px;

        &:hover {
            border: 1px solid rgb(24, 144, 255);
            background-color: rgb(24, 144, 255, 0.2);
            transition: 0.3s;
        }
    }

    .btn-select-group {
        display: flex;
        gap: 30px;
    }

    .alphabetical-box {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        .alphabetical-search__btn {
            text-transform: uppercase;
        }
    }
}