.author-avatar,
.author-avatar-m,
.author-avatar-xs,
.author-avatar_left {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    min-width: 32px;
    background-color: #F79E6C;
    border: 1px solid white;
    color: #FFF;
    text-align: center;
    border-radius: 50%;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;



    &__wrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        &:nth-child(n+2) {
            margin-left: - 8px;
        }
    }

    &__name {
        font-size: 14px;
    }
}

.author-avatar-m {
    font-size: 20px;
    width: 48px;
    height: 48px;
    min-width: 48px;
}

.author-avatar-xs {
    width: 24px;
    height: 24px;
    min-width: 24px;
}