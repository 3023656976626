.context-more {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 32px;
    min-width: 32px;
    height: 32px;

    .btn-more {
        width: 100%;
        min-width: 24px;
        height: 24px;
        background-image: url('./img/more-vertical.svg');
        border: none;
        background-color: inherit;
        background-repeat: no-repeat;
        background-position: center;
    }

    .drop-menu,
    .drop-menu_active {
        position: absolute;
        top: calc(100% + 4px);
        right: 0;
        width: 124px;
        height: auto;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        border: 1px solid #E0E7F2;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(30, 41, 59, 0.06);
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
        z-index: 5;

        &_active {
            opacity: 1;
            visibility: visible;
            transition: 0.3s;
        }

        &__btn {
            padding-left: 8px;
            padding-right: 8px;
            width: 100%;
            height: 32px;
            background-color: inherit;
            border: none;
            text-align: left;
            color: var(--text-primary-black, #1E293B);
            font-family: Inter;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &:hover {
                transition: 0.3s;
                background-color: #F7FAFD;
            }
        }
    }
}