@import "src/common/styles/svg_img.scss";

.modal-add-event,
.modal-add-event_tall {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1000;
  margin-left: auto;
  margin-right: auto;
  min-width: 360px;
  background-color: white;
  border-radius: 16px;
  max-height: calc(100vh - 120px);
  justify-content: space-between;
  max-width: 960px;
  width: 100%;
  height: auto;
  position: absolute;
  top: 121px;


  &__header {
    padding: 4px 20px;
    width: 100%;
    border-radius: 4px 4px 12px 12px;
    border: 4px solid #fff;
    background: var(--info-bg, #e7f1ff);

    .title {
      color: #404e8c;
      font-family: Inter;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    padding: 24px;

    .comment-container {
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      width: 100%;
      gap: 12px;
    }

    .filter-group {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      padding-left: calc(12px + 24px);
    }

    .textarea-box {
      display: flex;
      align-items: flex-start;
      gap: 12px;
    }

    .file-container {
      display: flex;
      align-items: center;
      gap: 12px;

      .add_file {
        display: flex;
        align-items: center;
        gap: 12px;
        position: relative;

        .text {
          color: #94A3B8;
          font-family: Rubik;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          cursor: pointer;
        }

        .input-file {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          cursor: pointer;
        }
      }
    }

    .files-group {
      padding-left: calc(24px + 12px);
      display: flex;
      gap: 16px;

      .file-item {
        display: flex;
        gap: 8px;
        align-items: center;

        .text {
          overflow: hidden;
          color: #020617;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-family: Rubik;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          width: 70px;
        }

        .btn-close {
          background-color: inherit;
          border: none;
        }
      }
    }

    .checkbox-label {
      display: flex;
      gap: 12px;
    }

    .executor-content {
      display: flex;
      gap: 12px;
    }

    .author-box {
      display: flex;
      align-items: flex-start;
      gap: 12px;
      align-items: center;

      .text {
        color: #8f99a3;
        text-align: right;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .executor {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 50%;
      }

      .executor-placeholder {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
      }
    }

    .executor-content {
      display: flex;
      gap: 12px;

      .exexutor-checkbox {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__btn-group {
    display: flex;
    width: 100%;
    justify-content: end;
    margin-top: auto;
    padding: 24px;
    border-top: 2px solid var(--Stroke-Primary, #EDEFFB);
  }

  .context-more {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 5;

    .btn-more {
      width: 24px;
      min-width: 24px;
      height: 24px;
      background-image: url("./img/more-vertical.svg");
      border: none;
      background-color: inherit;
    }

    .drop-menu,
    .drop-menu_active {
      position: absolute;
      width: 124px;
      height: 32px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      border: 1px solid #e0e7f2;
      background: #fff;
      top: calc(100% + 10px);
      right: 0px;
      box-shadow: 6px 8px 8px 0px rgba(30, 41, 59, 0.09);
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;

      &_active {
        opacity: 1;
        visibility: visible;
        transition: 0.3s;
      }

      &__btn {
        padding-left: 8px;
        padding-right: 8px;
        width: 100%;
        height: 100%;
        background-color: inherit;
        border: none;
        text-align: left;
        color: var(--text-primary-black, #1e293b);
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &:hover {
          transition: 0.3s;
          background-color: #f7fafd;
        }
      }
    }
  }

  //option status
  .option-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;

    .status_red {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #fecaca;
      border: 1px solid #ef4444;
    }

    .status_yellow {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #ffedd5;
      border: 1px solid #fdba74;
    }

    .status_green {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #bbf7d0;
      border: 1px solid #15803d;
    }
  }

  .select-group {
    display: flex;
    gap: 12px;
    align-items: center;
    gap: 12px;
    min-width: 432px;
    max-width: 432px;

    .single-date-picker {
      cursor: pointer;
      border: 2px solid #EDEFFB;
      max-width: 160px;
      min-width: 160px;
    }
  }
}

.search_add-executor,
.search_add-executor_active {
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 22px;
  padding: 10px;
  transition-duration: 1000ms;
  cursor: pointer;
  background-repeat: no-repeat;
  font-size: 11px;
  color: transparent;
  //border: 1px solid #F0F0F0;
  border: none;
  overflow: hidden;
  background-image: url($executor);
  background-color: white;
  background-size: 14px;
  background-position: 50% 50%;
  padding-left: 22px;

  &::placeholder {
    color: #1890ff;
    font-size: 11px;
  }

  &:focus {
    outline: none;
  }
}

.search_add-executor_active {
  width: 250px;
  cursor: pointer;
  padding-left: 30px;
  color: #020617;
  outline: none;
  //  border: 1px solid #F0F0F0;
  background-position: 10px 50%;
}

.search-box-executor {
  position: relative;
  width: 250px;
  height: 24px;

  .search_add-executor {
    position: absolute;
    top: 0;
    left: 0;
  }

  .options-box,
  .options-box_active {
    position: absolute;
    left: 0;
    top: 40px;
    display: flex;
    width: 250px;
    flex-direction: column;
    border-radius: 16px;
    padding: 4px 0px;
    background-color: white;
    position: absolute;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    right: -24px;
    cursor: pointer;
    transition: 1s;
    visibility: hidden;
    opacity: 0;
    transition: all 1s;
    overflow: hidden;
  }

  .options-box_active {
    z-index: 1;
    visibility: visible;
    opacity: 1;
    transition: all 1s;
  }
}

.text_grey {
  font-size: 11px;
  color: #888888;
}

.text-no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-size: 11px;
}

.tooltip-executors-box {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;

  .close-executor {
    width: 16px;
    height: 16px;
    position: absolute;
    right: -15px;
    top: -4px;
    border-radius: 16px;
    border: none;
    cursor: pointer;
    background-image: url($close);
    background-color: #f5f5f5;
    background-repeat: no-repeat;
    background-size: 8px;
    background-position: center;
    opacity: 0;
    visibility: hidden;
  }

  img {
    position: absolute;
    top: 0;
    right: -10px;
  }

  .tooltip-executors {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 34px;
    border-radius: 16px;
    padding: 8px 16px;
    background-color: white;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
  }

  .text-box {
    display: flex;
    flex-direction: column;

    .text {
      font-size: 11px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 180px;
    }

    .text_grey {
      font-size: 10px;
      color: grey;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 180px;
    }
  }
}

.tooltip-executors-box:hover {
  .tooltip-executors {
    opacity: 1;
    visibility: visible;
    position: absolute;
    top: 34px;
    border-radius: 16px;
    padding: 8px 16px;
    background-color: white;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.5s;
  }

  .close-executor {
    width: 16px;
    height: 16px;
    position: absolute;
    right: -15px;
    top: -4px;
    border-radius: 16px;
    border: none;
    cursor: pointer;
    background-image: url($close);
    background-color: #d9d9d9;
    background-repeat: no-repeat;
    background-size: 8px;
    background-position: center;
    opacity: 1;
    visibility: visible;
    transition: all 0.5s;
  }
}

.modal-add-task {

  .input-box {
    margin-top: 0;
  }

  .flag_red {
    stroke: #fa0f0f !important;

    path {
      stroke: #fa0f0f !important;
    }
  }

  .flag_green {
    stroke: #27bc62 !important;

    path {
      stroke: #27bc62 !important;
    }
  }

  .flag_yellow {
    stroke: #fa9c0f !important;

    path {
      stroke: #fa9c0f !important;
    }
  }

  .flag_blue {
    stroke: #1890ff !important;

    path {
      stroke: #1890ff !important;
    }
  }
}

.modal-add-task_tall {
  padding: 6px;
  display: flex;
  flex-direction: column;
  height: 70vh;

  .main-info {
    height: calc(70vh - 120px);
  }

  .input-box {
    margin: 0;
  }
}