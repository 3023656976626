.modal-fixed-message {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 340px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-items: center;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);
    padding: 14px;
    gap: 18px;
    border-radius: 10px;
    background: #FFF;

    &__title {
        color: #000;
        font-family: "Open Sans";
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    &__group-btn {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
    }

    &__btn {
        color: #848484;
        font-family: "Open Sans";
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        border: none;
        background: inherit;
    }
}