.modal-bitrix-form {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 32px;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 560px;
    width: 100%;
    min-width: 340px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    border-radius: 8px;
    background: #FFF;
    padding: 24px;
    max-height: calc(100vh - 40px);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    &__header {
        display: flex;
        flex-direction: column;
        width: calc(100% - 24px - 16px);
        gap: 12px;
    }

    &__title {
        color: #333;
        font-family: Inter;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    &__description {
        color: #333;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 28px;
        width: 100%;
        height: auto;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 10px;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__btn-close {
        position: absolute;
        top: 24px;
        right: 24px;
        background-color: inherit;
        border: none;
        cursor: pointer;
        z-index: 11;
    }

    &__successful {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 12px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
        background-color: #fff;
        border-radius: 8px;
    }

    &__icon-done {
        max-width: 135px;
        width: 100%;
        height: auto;
    }

    &__text-green {
        color: #00B45E;
        text-align: center;
        font-family: Inter;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        text-align: center;
    }

    &__text {
        color: #333;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
    }

    .selector_wrapper {
        max-width: 100%;
    }
}

@media (max-width: 430px) {
    .modal-bitrix-form {
        gap: 14px;

        &__title {
            font-size: 18px;
            line-height: 32px;
        }

        &__header {
            gap: 8px;
        }

        &__text {
            font-size: 13px;
            line-height: 24px;
        }
    }
}