.sceleton-product-data {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;

        span {
            border-radius: 4px;
        }
    }

    &__group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
    }
}