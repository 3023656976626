.modal-choice-of-strategy {
    .steps-items {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
        margin-top: 24px;

        .step-item,
        .step-item_active {
            display: flex;
            gap: 12px;
            align-items: center;

            .step-num {
                display: flex;
                align-items: center;
                min-width: 24px;
                width: 24px;
                height: 24px;
                justify-content: center;
                align-items: center;
                border-radius: 24px;
                background: #F2F4F7;
                color: #00284D;
                font-family: IBMPlexSans;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }

            .step-text {
                color: #8F99A3;
                font-family: Inter;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }

            &_active {
                .step-num {
                    background: #BBF7D0;
                }

                .step-text {
                    color: #020617;
                    font-weight: 500;
                }
            }
        }
    }
}