.notifications-ver2 {
    width: calc(100%);
    height: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    min-height: 500px;
    border-radius: 8px;
    border: 1px solid var(--gray-300, #D1D5DB);
    display: flex;
    overflow: hidden;
    margin-top: 10px;
    height: 80vh;
    max-height: calc(80vh);
    background-color: white;

    .article-item {
        width: 100%;
    }

    .option-sort {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .selected-message-plumb,
    .empty-article-plumb {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 80%;
        color: var(--menu-secondary-text, #53616E);
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-top: auto;
    }

    .empty-article-plumb {
        margin-left: auto;
        margin-right: auto;
        color: var(--text-accent-blue, #EF4444);
        border: 1px solid var(--error-bg, #FECACA);
        border-radius: 8px;
        padding: 16px;
        width: fit-content;
        height: auto;
        background-color: #FFF0F0;
        margin-top: 40px;
    }

    .navigation-panel {
        display: flex;
        flex-direction: column;
        max-width: 120px;
        min-width: 120px;
        height: 100%;
        width: 100%;
        border-right: 1px solid #D1D5DB;
        padding: 20px 10px;
        justify-content: space-between;

        .text {
            color: #000;
            text-align: center;
            /* Основной текст Inter 14 */
            font-family: Inter;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 32px;
            width: 100%;
            height: auto;

            .navigation-panel__item_active,
            .navigation-panel__item {
                display: flex;
                min-height: 61px;
                flex-direction: column;
                align-items: center;
                gap: 4px;
                cursor: pointer;
                position: relative;

                &_active,
                &:hover {
                    transition: 0.5s;
                }

                .img-box {
                    width: 24px;
                    height: 24px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                p {
                    color: #6B7280;
                    text-align: center;
                    font-family: NunitoSans;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 16px;
                    max-width: 108px;
                    font-size: 13px;
                    text-align: center;
                }
            }
        }
    }

    .content-messages {
        display: flex;
        flex-direction: column;
        width: calc(50%);
        max-width: 400px;
        height: 100%;
        border-right: 1px solid #D9D9D9;

        &__header {
            display: flex;
            flex-direction: column;
            gap: 14px;
            align-items: start;
            justify-content: center;
            padding: 20px;
            row-gap: 18px;
            column-gap: 40px;

            .input-field-wrapper {
                max-width: 100%;
            }

            .header-top-box {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                gap: 16px;
                flex-wrap: wrap;

                .input-field-wrapper {
                    max-width: 229px;
                    min-height: 40px;
                }

                .text_chat {
                    color: #000;
                    text-align: center;
                    font-family: NunitoSans;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                }

            }

            .header-bottom-box {
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 100%;
            }

            .checkbox-content {
                display: flex;
                align-items: center;
                gap: 8px;
                flex-wrap: nowrap;

                .text {
                    color: #000;
                    font-family: Inter;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.24px;
                }
            }

            .btn {
                width: 160px;
                min-height: 32px;
                border-radius: 16px;
                border: 1px solid #D9D9D9;
                background-color: white;

                &:hover {
                    background-color: rgb(248, 248, 248);
                    transition: 0.5s;
                }
            }
        }

        .messages-box {
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;

            // .messages-list {
            //     display: flex;
            //     flex-direction: column;
            //     width: 100%;

            //     &__item,
            //     &__item_active {
            //         display: flex;
            //         width: 100%;
            //         min-height: 96px;
            //         padding: 18px 20px;
            //         align-items: center;
            //         flex-shrink: 0;
            //         gap: 10px;
            //         cursor: default;
            //         position: relative;
            //         border-bottom: 1px solid #D1D5DB;

            //         .new-message {
            //             position: absolute;
            //             top: calc(50% - 4px);
            //             width: 8px;
            //             height: 8px;
            //             border-radius: 1px;
            //             background: #EF4444;
            //             right: 20px;
            //         }

            //         .read-message {
            //             position: absolute;
            //             top: calc(50% - 9px);
            //             right: 20px;
            //         }

            //         &_active,
            //         &:hover {
            //             transition: 0.5s;
            //             background: #F0FDF4;
            //         }

            //         .img-box {
            //             min-width: 40px;
            //             max-width: 40px;
            //             border-radius: 50%;
            //             overflow: hidden;
            //             height: 40px;

            //             img {
            //                 width: 100%;
            //                 height: 100%;
            //                 object-fit: cover;
            //             }
            //         }

            //         .message-box {
            //             display: flex;
            //             flex-direction: column;
            //             gap: 4px;
            //             width: calc(100% - 50px - 32px);

            //             .title {
            //                 color: #000;
            //                 font-family: Inter;
            //                 font-size: 13px;
            //                 font-style: normal;
            //                 font-weight: 400;
            //                 line-height: normal;
            //                 display: -webkit-box;
            //                 -webkit-line-clamp: 1; // количество строк
            //                 -webkit-box-orient: vertical;
            //                 overflow: hidden;
            //                 hyphens: auto;
            //                 word-break: break-word;
            //             }

            //             .text {
            //                 display: -webkit-box;
            //                 -webkit-line-clamp: 1; // количество строк
            //                 -webkit-box-orient: vertical;
            //                 overflow: hidden;
            //                 hyphens: auto;
            //                 color: #000;
            //                 text-overflow: ellipsis;
            //                 font-family: NunitoSans;
            //                 font-size: 13px;
            //                 font-style: normal;
            //                 font-weight: 400;
            //                 line-height: 16px;
            //             }

            //             .title-and-date {
            //                 display: flex;
            //                 flex-direction: column;
            //                 width: 100%;
            //                 align-items: start;
            //                 justify-content: space-between;
            //                 gap: 10px;
            //                 overflow: hidden;

            //                 .info-message {
            //                     display: flex;

            //                     .title {
            //                         //white-space: nowrap;
            //                         overflow: hidden;
            //                         text-overflow: ellipsis;
            //                         color: #6B7280;
            //                         font-family: NunitoSans;
            //                         font-size: 11px;
            //                         font-style: normal;
            //                         font-weight: 600;
            //                         line-height: 14px;
            //                         padding-right: 6px;
            //                         border-right: 1px solid #6B7280;
            //                     }

            //                     .text_grey {
            //                         color: #6B7280;
            //                         font-family: NunitoSans;
            //                         font-size: 11px;
            //                         font-style: normal;
            //                         font-weight: 600;
            //                         line-height: 14px;
            //                         padding-left: 6px;
            //                         white-space: nowrap;
            //                         min-width: 90px;
            //                     }
            //                 }


            //                 .text_grey {
            //                     text-overflow: ellipsis;
            //                     overflow: hidden;
            //                 }
            //             }
            //         }
            //     }
            // }
        }
    }

    .message-item-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: 300px;
        position: relative;

        &__header {
            min-height: 70px;
            max-height: 70px;
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 20px;
            border-bottom: 1px solid #D9D9D9;
            overflow: hidden;

            .header-content {
                justify-content: space-between;
                display: flex;
                width: 100%;
                align-items: center;
                gap: 24px;
                min-height: 36px;

                .user-info {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    overflow: hidden;

                    .title {
                        color: #111827;
                        font-family: NunitoSans;
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 22px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }
            }



            .text_online {
                color: #6B7280;
                font-family: NunitoSans;
                font-size: 11px;
                font-style: normal;
                font-weight: 600;
                line-height: 14px;
                white-space: nowrap;

                span {
                    color: #1F2937;
                }
            }
        }

        .message-box {
            display: flex;
            justify-content: center;
            overflow-x: hidden;
            overflow-y: auto;

            .date-box {
                flex-direction: row;
            }

            .message {
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 100%;
                max-width: 96%;
                border-radius: 16px;
                padding: 50px 20px;
                background-color: #F9FAFB;
                border-radius: 16px;

                .title {
                    color: #000;
                    font-family: Inter;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .text {
                    color: #000;
                    font-family: Inter;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .article {
                margin-top: 0;
            }
        }

        .text-field {
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            color: var(--unnamed, #AAA);
            font-family: Inter;
            font-size: 13px;
            padding: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            outline: none;
            border: none;
            border-top: 1px solid #D9D9D9;
            resize: none;
            max-height: 64px;
            color: #020617;

            &::-webkit-scrollbar {
                width: 0;
            }

            &::-webkit-scrollbar {
                width: 0;
            }

            &:focus {
                outline: none;
                border-top: 1px solid #D9D9D9;
            }

        }
    }

    .support__content {
        width: 100%;
        height: auto;
        overflow: auto;
        display: flex;
        flex-direction: column;
        max-height: 700px;
        padding: 20px;
        border-radius: 16px;

        .date-box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 120px;
            height: 24px;
            border-radius: 16px;
            border: 1px solid #F0F0F0;
            box-shadow: none;
            background: #FFF;
            border-radius: 16px;
            font-size: 11px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 10px;
            margin-top: 10px;
        }

        .support-message,
        .user-message {
            display: flex;
            align-items: flex-end;
            gap: 16px;
            max-width: 70%;
            width: 100%;

            img {
                width: 40px;
                height: 40px;
            }

            .message-box {
                display: flex;
                flex-direction: column;
                background-color: #F3F4F6;
                padding: 8px 16px;
                border-radius: 8px 8px 8px 0;
                gap: 12px;



                .text_grey {
                    color: #888888;
                    font-size: 11px;
                }

                .text {
                    color: #000;
                    font-family: NunitoSans;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                }

                .text_time {
                    color: #888888;
                    font-size: 11px;
                    margin-left: auto;
                    width: 40px;
                }
            }
        }

        .user-message {
            margin-left: auto;

            .message-box {
                background-color: #DCFCE7;
                border-radius: 8px 8px 0 8px;
            }
        }
    }
}

.notification-round {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -4px;
    left: 55px;
    border-radius: 1px;
    background: #EF4444;
    width: auto;
    min-width: 8px;
    height: 12px;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 0 3px;
    font-size: 10px;
    color: #FFF;
    text-align: center;
    font-family: NunitoSans;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
}