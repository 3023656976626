.article-template__content-article {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.article-box {
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;

    .article-box-short {
        row-gap: 24px;
        column-gap: 24px;
    }

    .paginator {
        background-color: #fff;
    }
}

.article-main {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    gap: 8px;

    &__header {
        max-width: 100%;
        margin-left: auto;
    }

    .article-box {
        width: 100%;
        max-width: 100%;
    }

    .table-box {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
    
        .table-box-component {
            width: min-content;
            max-width: 100%;
        }
    }

    .notifications-portal {
        position: fixed;
        z-index: 10;
        left: 0;
        right: 0;
        top: 50px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-left: auto;
        margin-right: auto;
        width: 400px;

        .tooltip-yellow {
            max-width: 100%;
        }
    }

    .toolbar-top {
        display: flex;
        flex-direction: column;
        align-items: end;
        gap: 12px;

        .filter {
            display: flex;
            // justify-content: space-between;
            //flex-wrap: wrap;
            column-gap: 12px;
            gap: 8px;
            width: 100%;

            .selector_wrapper {
                max-width: 358px;
            }
        }

        .new-article-btn {
            margin-left: auto;
            width: 200px;
        }

        .filter-group,
        .btn-group {
            display: flex;
            column-gap: 24px;
            row-gap: 16px;
            align-items: center;
            width: 100%;
           // flex-wrap: wrap;

            &__header {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 24px;
                width: 100%;

                &__group {
                    display: flex;
                    align-items: center;
                    gap: 24px;

                    .text {
                        white-space: nowrap;
                        font-size: 11px;
                    }
                }
            }

        }

        .btn__green,
        .btn__bej {
            margin: 0;
            height: 32px;
            min-width: 200px;
            font-size: 11px;
        }
    }

    .content-and-filter {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;
        align-items: flex-start;
        justify-content: space-between;
    }

    .select-box {
        display: flex;
        align-items: center;
        gap: 24px;
    
        .text {
            white-space: nowrap;
            font-size: 11px;
        }
    }
    
    .loader-box {
        margin-top: 120px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}