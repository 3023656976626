.total-information {
    display: flex;
    gap: 24px;
    max-width: 522px;
    min-width: 522px;
    width: 100%;
    height: calc(100% - 40px);
    flex-direction: column;
    border-radius: 0px 8px 8px;
    border: 2px solid #EDEFFB;
    position: relative;
    align-self: start;
    margin-top: 40px;
    max-height: calc(100vh - 100px);
    min-height: calc(100vh - 100px);
    background-color: #fff;

    .field-group {
        display: flex;
        gap: 12px;
    }

    .tabs-top {
        max-width: 522px;

        &__item {

            .tab-copy {

                .text_name {
                    display: inline-block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }
    }

    .content-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 8px;
    }

    &__tooltip {
        display: flex;
        flex-direction: column;
        width: calc(100% - 24px*2);
        padding: 16px;
        gap: 4px;
        border-radius: 8px;
        background: #F7FAFD;
        margin-left: 24px;
        margin-right: 24px;

        .title_tooltip {
            color: #2F506D;
            font-family: Rubik;
            font-size: 11px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
        }

        .text_tooltip {
            color: #6B8CA9;
            font-family: Rubik;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
        }

        .link_tooltip {
            @extend .text_tooltip;
            color: #3900B1;
            text-decoration: underline;
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-top: 24px;
        width: calc(100% - 24px*2);
        margin-left: 24px;
        margin-right: 24px;

        .title {
            color: #475569;
            font-family: Rubik;
            font-size: 17px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
        }
    }

    &__content {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 28px;
        width: calc(100%);
        height: 100%;
        padding-right: 24px;
        padding-left: 24px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .tab-copy {
        display: flex;
        align-items: center;
        gap: 8px;

        .btn-copy-product {
            background-color: inherit;
            border: none;
        }
    }

    .input-group {
        display: flex;
        align-items: center;
        gap: 20px;

        .barcode_information {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            justify-content: center;
            height: 56px;
            margin-top: 24px;

            .text_grey {
                text-align: center;
            }

            .link__blue {
                color: var(--button-primary-default, #1890FF);
                /* Button link/Small */
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                text-decoration: underline;
                text-align: center;
                /* 123.077% */
            }
        }
    }
}

.characteristics-product-ver3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .title {
        color: var(--main-menu-primary-text, #020617);
        font-family: Inter;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .field-group {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        min-height: 300px;
        width: 100%;
        max-height: 700px;
        overflow: hidden;
        overflow-y: auto;
        width: 100%;

        .selector_wrapper {
            margin-top: 24px;
        }

        .checkbox-container {
            display: flex;
            gap: 10px;
            align-items: center;
            font-size: 13px;
        }

        .error {
            padding-top: 5px;
            margin-left: 15px;
            color: red;
            font-size: 11px;
        }
    }

    .selector_wrapper .dropdown_selector {
        height: unset;
    }

    .selector_wrapper {
        min-width: 100%;
        max-width: 100%;
    }
}

.characteristics-container {
    display: flex;
    max-width: 522px;
    min-width: 522px;
    width: 100%;
    height: calc(100% - 40px);
    flex-direction: column;
    padding-top: 10px;
    gap: 14px;
    border-radius: 0px 8px 8px;
    border: 2px solid #EDEFFB;
    margin-top: 40px;
    position: relative;
    max-height: calc(100vh - 100px);
    min-height: calc(100vh - 100px);
    background-color: #fff;

    &__header {
        padding-top: 14px;
        padding-left: 24px;
        padding-right: 24px;
    }

    &__content {
        display: flex;
        padding: 24px;
        flex-direction: column;
        gap: 28px;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 10px;

        .selector_wrapper {
            max-width: 100%;
        }
    }

    // .textarea-dynamic-placeholder {
    //     height: 100%;

    //     &__content {
    //         height: 100%;
    //     }
    // }

    .checkbox-content {
        display: flex;
        gap: 8px;

        .text_description {
            color: #94A3B8;
            font-family: "Inter";
            font-size: 11px;
        }
    }

    .input-box {
        margin-top: 0;
    }

    .title {
        color: #475569;
        font-family: Rubik;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
    }

    &__content {
        height: 100%;
    }

    .btn-group {
        align-self: flex-end;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        gap: 12px;
        margin-top: auto;
    }
}