.push-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}

.push {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border-radius: 8px;
  background: #ffffff;
  color: #4a4a4a;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: all 0.4s ease;
  opacity: 0.95;
  backdrop-filter: blur(10px);
}

.push:hover {
  transform: translateY(-3px);
  opacity: 1;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.push-content {
  flex-grow: 1;
}

.push h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.push p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #555;
}

.push-close {
  background: none;
  border: none;
  color: #999;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.push-close:hover {
  color: #333;
}

.push-info {
  background: rgba(33, 150, 243, 0.1);
  color: #2196f3;
}

.push-success {
  background: rgba(27, 178, 32, 0.1);
  color: #4caf50;
}

.push-error {
  background: rgba(244, 67, 54, 0.1);
  color: #f44336;
}

.push-warning {
  background: rgba(255, 152, 0, 0.1);
  color: #ff9800;
}
