.file-item-component {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 36px;
    gap: 8px;
    align-items: center;
    color: #767676;
    text-overflow: ellipsis;
    font-family: NunitoSans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    cursor: pointer;

    &:hover {
        .file-item-component__hide-box {
            display: flex;
        }

        .file-item-component__link-info {
            display: none;
        }
    }

    &__link-list {
        display: flex;
        flex-direction: column;
        align-items: self-start;
        width: 100%;
        height: 100%;
        gap: 8px;
        overflow: auto;
    }

    &__link-file {
        display: flex;
        gap: 8px;
        align-items: center;
        color: #475569;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        width: calc(100% - 220px);

        span {

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    &__link-file-img {
        width: 20px;
        height: 20px;
    }


    &__link-btn {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 6px;
        border-radius: 4px;
        background: #F3F4F6;
        white-space: nowrap;

        img {
            width: 16px;
            height: 16px;
        }
    }

    &__hide-box {
        display: none;
        gap: 12px;
    }

    &__link-info {
        display: flex;

        span {
            display: flex;
            padding: 0px 8px;

            &:nth-child(1) {
                border-right: 1px solid #767676;
            }
        }
    }
}