.selected-rating {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 210px;
    height: 40px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--Stroke-Primary-Primary, #EDEFFB);
    // position: relative;

    &__num-box {
        display: flex;
        gap: 28px;
        position: absolute;
        left: 0;
        bottom: 10px;


        span {
            color: #64748B;
            font-family: Rubik;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
        }
    }

    .css-iljtu2-MuiRating-root {
        gap: 4px;
        // margin-left: 11px;

        .css-dqr9h-MuiRating-label {}

        input {
            // position: relative;
        }

        label {
            svg {
           
            }
        }

    }

    .star-grey {
        path {
            fill: #C5CCD3;
        }
    }

    .star-yellow {
        path {
            fill: #F97316;
        }
    }
}