.article-template {
    display: flex;
    width: 100%;
    gap: 20px;
    align-items: flex-start;

    &__content-article {
        width: 100%;
        position: relative;

    }


    .container-article {
        width: 100%;
        position: relative;

        

        .text {
            font-size: 22px;
        }

        .yellow-information-box {
            .text {
                font-size: 15px;
            }
        }
    }

    // .title__big {
    //     font-size: 29px;
    //     font-weight: 500;
    //     text-align: center;
    //     text-align: start;

    //     &_grey {
    //         color: #D9D9D9;
    //         font-size: 29px;
    //         font-weight: 400;
    //         text-align: center;
    //     }

    //     &:empty {
    //         padding-top: 0;
    //         height: 1lh;

    //         &::before {
    //             content: "Введите заголовок";
    //             color: #D9D9D9;
    //         }
    //     }
    // }

    // .title__big[contenteditable="true"] {
    //     outline: none;
    // }

    .line {
        display: block;
        width: 70%;
        background-color: #F0F0F0;
        height: 1px;
        margin-bottom: 8px;
    }

    .toolbar-filter {
        display: flex;
        column-gap: 24px;
        row-gap: 16px;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 25px;

        .btn-publish {
            margin-left: auto;
            max-width: 160px;
            min-width: 160px;
        }

        .btn__green {
            margin: 0;
            max-width: 200px;
            min-width: 200px;
        }

        .select-group {
            display: flex;
            align-items: center;
            column-gap: 24px;
            row-gap: 16px;
            flex-wrap: wrap;
        }
    }

    .toolbar-info-box {
        max-width: 70%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 8px;

        .toolbar-top {
            width: 100%;
            max-width: calc(100vw - 30px - 30px - 200px - 360px - 100px - 40px);
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 24px;
            flex-wrap: wrap;

            .date-picker {
                max-width: 168px;
            }

            .text_grey {
                white-space: nowrap;
                font-size: 11px;
                color: #888888;
            }

            .select-box {
                display: flex;
                gap: 16px;
                align-items: center;

                .date-picker {
                    border: 1px solid #F0F0F0;
                }
            }

            .tag-search-box {
                display: flex;
                gap: 24px;
                align-items: center;
                flex-wrap: wrap;
                width: auto;

                .btn__bej {
                    max-width: 160px;
                    min-width: 160px;
                    margin-top: 0;
                }

                &__content {
                    gap: 16px;
                    align-items: center;
                    width: auto;
                    display: flex;

                    .input-field-text {
                        height: 32px;
                        max-width: 200px;
                        min-width: 200px;
                    }
                }
            }
        }

        .toolbar-bottom {
            width: 100%;
            display: flex;
            gap: 16px;

            .text_grey {
                white-space: nowrap;
                font-size: 11px;
                color: #888888;
            }

            .tag-box_green {
                display: flex;
                align-items: center;
                width: auto;
                padding-left: 8px;
                padding-right: 8px;
                background-color: #F2FFE6;
                border-radius: 16px;
                gap: 10px;
                height: 20px;



            }
        }
    }

    .tags-input {
        border: 1px solid #F0F0F0;
        display: flex;
        align-items: center;
        background-color: white;
        padding: 0;
        border-radius: 1em;
        width: 100%;
        min-width: 200px;
        max-width: 200px;
        padding: 0 !important;

        // todo: fix height
        .control {
            min-height: 32px;
            height: auto;
        }
    }

    .title-box {
        display: flex;
        gap: 12px;
        width: 100%;

        .title-input {
            border: none;
            outline: none;
            width: 100%;
            -webkit-user-modify: read-write;
            overflow-wrap: break-word;
            -webkit-line-break: after-white-space;
            flex-grow: 1;
            overflow-y: auto;
            resize: none;
            background-color: inherit;

            &::placeholder {
                color: #D9D9D9;
            }
        }

        .btn-group {
            display: flex;
            align-items: center;
            gap: 4px;
        }
    }


}

.action-panel-top {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 30px;

    .btn__green {
        margin: 0;
    }

    .status {
        white-space: nowrap;
        color: red;
        margin-right: auto;
    }
}

// Новые стили 

.article-and-tabs {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;


    &__top-panel {
        display: flex;
        justify-content: end;    
        gap: 20px;
    }

    &__tabs {
        display: flex;
        width: 100%;
        gap: 20px;
        justify-content: end;
    }
}