.promotion-rules {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 24px;

    .title_main-page {
        color: #020617;
        font-family: Rubik;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
    }

    .content-btn {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .toolbar-medium, .toolbar-top {
        display: flex;
        width: 100%;
        gap: 12px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    .btn__blue {
        min-height: 40px;
        max-width: 229px;
        min-width: 229px;
        padding: 0px 8px;
    }
}