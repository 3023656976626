.post {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;

    
    &__toolbar-top {
        display: flex;
        width: 100%;
        gap: 12px;
        flex-wrap: wrap;

        .btn-content {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
        }
    }

    .tab-sort {
        overflow: hidden;
        width: fit-content;
        display: flex;
        height: 40px;
        border-radius: 8px;
        border: 1px solid #EDEFFB;

        .btn,
        .btn_active {
            display: flex;
            align-items: center;
            gap: 6px;
            justify-content: center;
            height: 100%;
            cursor: pointer;
            border-left: 1px solid var(--table-border, #EDEFFB);
            border: none;
            background-color: #fff;
            padding-left: 20px;
            padding-right: 20px;
            color: var(--Slate-950, #020617);
            font-family: Rubik;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 14px;
            border-left: 1px solid #EDEFFB;

            &:hover,
            &_active {
                transition: 0.5s;
                background: #51CA64;
                color: white;

                path {
                    fill: #fff
                }
            }

            &:nth-child(1) {
                border: none;
            }

            .arrow, .arrow_up {
                min-width: 16px;
                width: 16px;
                height: 16px;

                &_up {
                    transform: rotate(180deg);
                }
            }
        }
    }


    &__header {
        display: flex;
        gap: 12px;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .title_main {
            color: #0C2037;
            font-family: Rubik;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: -0.56px;
        }

     
    }

    .content-table {
        display: flex;
        gap: 12px;
        align-items: center;
    }

    .toolbar {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        flex-wrap: wrap;

        .tab_view {
            overflow: hidden;
            min-width: 120px;
            max-width: 120px;
            display: flex;
            height: 40px;
            border-radius: 8px;
            border: 1px solid #EDEFFB;
            background: #F8F9FE;

            &__item {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                height: 100%;
                cursor: pointer;
                border-left: 1px solid var(--table-border, #EDEFFB);
            }

            .table-view_active,
            .table-view:hover,
            .card-view_active,
            .card-view:hover {
                transition: 0.3s;

                svg {
                    path {
                        fill: #3900B1;
                        //  stroke: #1890FF;
                    }
                }
            }

            .chat-view_active,
            .chat-view:hover {
                transition: 0.3s;

                svg {
                    path {
                        //  fill: #3900B1;
                        stroke: #3900B1;
                    }
                }
            }

            &__item:last-child {
                border-left: 1px solid var(--table-border, #EDEFFB);
            }
        }

        .btn__green {
            max-width: 160px;
            min-width: 160px;
            margin: 0;
        }

        .select-box {
            display: flex;
            gap: 16px;
            align-items: center;


            .text {
                white-space: nowrap;
            }
        }

        .date-picker {
            max-width: 240px;
            min-width: 240px;

            .react-datepicker__input-container {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

    }

    .card-group {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        // .card-item {
        //     display: flex;
        //     border: 2px solid #F3F6FF;
        //     padding: 14px;
        //     border-radius: 10px;
        //     gap: 8px;
        //     background-color: #fff;

        //     .img-box {
        //         min-width: 108px;
        //         max-width: 108px;
        //         height: 108px;

        //         img {
        //             width: 100%;
        //             height: 100%;
        //             object-fit: contain;
        //         }
        //     }

        //     &__content {
        //         display: flex;
        //         flex-direction: column;
        //         gap: 12px;
        //         min-width: 324px;
        //         max-width: 324px;

        //         .title {
        //             color: #020617;
        //             font-family: Rubik;
        //             font-size: 13px;
        //             font-style: normal;
        //             font-weight: 500;
        //             line-height: 20px;
        //         }

        //         .text {
        //             display: flex;
        //             gap: 4px;
        //             white-space: nowrap;
        //             color: #70808F;
        //             font-family: Rubik;
        //             font-size: 13px;
        //             font-style: normal;
        //             font-weight: 400;
        //             line-height: 16px;

        //             span {
        //                 overflow: hidden;
        //                 white-space: nowrap;
        //                 text-overflow: ellipsis;
        //                 color: #020617;
        //             }
        //         }

        //         .social-name {
        //             display: flex;
        //             align-items: center;
        //             gap: 12px;

        //             .icon-box {
        //                 min-width: 24px;
        //                 width: 24px;
        //                 height: 24px;

        //                 img {
        //                     width: 100%;
        //                     height: 100%;
        //                     object-fit: contain;
        //                 }
        //             }

        //             .text {
        //                 display: inline-block;
        //                 text-overflow: ellipsis;
        //                 white-space: nowrap;
        //                 color: #70808F;
        //                 font-family: Rubik;
        //                 font-size: 13px;
        //                 font-style: normal;
        //                 font-weight: 400;
        //                 line-height: 16px;
        //                 overflow: hidden;

        //                 span {
        //                     overflow: hidden;
        //                     white-space: nowrap;
        //                     text-overflow: ellipsis;
        //                     color: #020617;
        //                 }
        //             }
        //         }

        //         .status_disactive {
        //             display: flex;
        //             align-items: center;
        //             justify-content: center;
        //             width: fit-content;
        //             padding-left: 8px;
        //             padding-right: 8px;
        //             border-radius: 4px;
        //             background: #F1F5F9;
        //             height: 30px;
        //             color: #94A3B8;
        //             text-align: center;
        //             font-family: Rubik;
        //             font-size: 10px;
        //             font-style: normal;
        //             font-weight: 500;
        //             line-height: 14px;
        //         }

        //         .status_active {
        //             display: flex;
        //             align-items: center;
        //             justify-content: center;
        //             width: fit-content;
        //             padding-left: 8px;
        //             padding-right: 8px;
        //             border-radius: 4px;
        //             background: #BBF7D0;
        //             height: 30px;
        //             color: #166534;
        //             text-align: center;
        //             font-family: Rubik;
        //             font-size: 10px;
        //             font-style: normal;
        //             font-weight: 500;
        //             line-height: 14px;
        //         }
        //     }

        //     &__header {
        //         display: flex;
        //         align-items: center;
        //         justify-content: space-between;
        //         width: 100%;
        //         gap: 8px;

        //         .title {
        //             color: var(--Base-Black, #000);
        //             white-space: nowrap;
        //             overflow: hidden;
        //             text-overflow: ellipsis;
        //             font-family: Rubik;
        //             font-size: 13px;
        //             font-style: normal;
        //             font-weight: 500;
        //             line-height: 20px;
        //             margin: 0;
        //         }

        //         .drop-and-btn {
        //             position: relative;

        //             .btn-points {
        //                 background-color: inherit;
        //                 border: none;
        //             }

        //             .drop-content {
        //                 display: flex;
        //                 right: 0;
        //                 flex-direction: column;
        //                 background-color: white;
        //                 border: 2px solid #F2F2F4;
        //                 border-radius: 4px;
        //                 padding: 12px 16px;
        //                 width: 137px;
        //                 height: auto;
        //                 position: absolute;
        //                 top: 16px;

        //                 &__item {
        //                     color: #0C2037;
        //                     font-family: Rubik;
        //                     font-size: 13px;
        //                     font-style: normal;
        //                     font-weight: 400;
        //                     line-height: 18px;
        //                     cursor: pointer;
        //                 }
        //             }
        //         }


        //     }
        // }

        
        .card-item {
            cursor: pointer;
            background-color: #ffffff;
            border-radius: 8px;
            border: 1px solid #E0E7F2;
            height: auto;
            position: relative;
            width: 240px;
            min-width: 240px;
            min-height: 319px;
           // max-height: 319px;

            &:hover {
                transition: 0.3s;
                background-color: #E7F1FF;
                border: 1px solid #1890FF;

                .card-item__header {
                    transition: 0.3s;
                    background-color: #F5F9FF;
                }
            }

            &__top-content {
                display: flex;
                flex-direction: column;
                gap: 16px;
                padding: 12px 20px 20px;
                border-radius: 8px 8px 0px 0px;
                background: #F8F9FC;

                .img-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 112px;
                    min-height: 112px;
                    width: 100%;
                    border-radius: 8px;
                    overflow: hidden;

                    .img {
                        width: 100%;
                        object-fit: contain;
                        height: 100%;
                    }
                }
            }

            &__header {
                display: flex;
                justify-content: space-between;
                width: auto;
                align-items: center;
                gap: 8px;

                .statistic-tasks {
                    display: flex;
                    gap: .25em;
                    overflow: hidden;
                    flex-grow: nowrap;
                    flex-direction: column;
                    align-items: flex-start;

                    &__item {
                        padding: 2px 4px;
                        align-items: flex-start;
                        white-space: nowrap;
                        border-radius: 4px;
                        text-align: center;
                        color: var(--Slate-500, #64748B);
                        font-family: NunitoSans;
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 14px;

                        span {
                            color: #020018;
                            font-weight: 600;
                        }
                    }
                }


            }

            &__medium-content {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding: 12px;
                width: auto;

                .title {
                    color: #020617;
                    font-family: NunitoSans;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    min-height: 40px;
                    letter-spacing: 0.08px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; // количество строк
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    hyphens: auto;
                }

                .date_project {
                    color: #64748B;
                    font-family: NunitoSans;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0.12px;
                }
            }

            &__bottom-content {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding: 20px 16px;
                width: auto;
                border-top: 1px solid #EDEFFB;

                .sum-task,
                .date-box {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    img {
                        width: 16px;
                        height: 16px;
                    }

                    .text {
                        color: #8F99A3;
                        font-family: Inter;
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                    }

                    .days-left {
                        margin-left: auto;
                        display: flex;
                        padding: 0px 2px;
                        min-height: 8px;
                        align-items: center;
                        gap: 8px;
                        border-radius: 6px;
                        background: #FFF0F0;
                        color: var(--red-300, #FCA5A5);
                        font-family: Inter;
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;

                        span {
                            color: #F93232;
                        }
                    }
                }

                .date-of-creation {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: auto;
                    margin-left: auto;

                    .text {
                        color: #94A3B8;
                        font-family: NunitoSans;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 12px;
                        letter-spacing: 0.05px;
                    }

                    .text_date {
                        @extend .text;
                        color: #020617;
                    }
                }
            }

            .box-title {
                display: flex;
                flex-direction: column;
                gap: 16px;
                justify-content: space-between;
                align-items: center;
                padding: 24px;
                height: 55%;
                width: 100%;
                border-radius: 16px;
                border-top: 1px solid #f0f0f0;
                overflow: hidden;

                .title-box {
                    max-width: 100%;
                    overflow: hidden;

                    .title {
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }


                .group-btn {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    gap: 8px;
                }
            }
        }
    }

    .chat-content {
        display: flex;
        overflow: hidden;
        min-height: 90vh;
        width: 100%;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;

        .list-post {
            min-width: 340px;
            border-right: 2px solid #EDEFFB;

            &__header {
                padding: 8px 32px;
                min-height: 62px;
                max-height: 62px;
                border-bottom: 2px solid var(--Stroke-Primary, #EDEFFB);

                .title {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: #020617;
                    font-family: Rubik;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 32px;
                    letter-spacing: -0.56px;
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 24px 10px;
                overflow: auto;
                max-height: calc(100% - 62px);

                .card-item {
                    display: flex;
                    border: 2px solid #F3F6FF;
                    padding: 10px;
                    gap: 16px;
                    border-radius: 10px;
                    cursor: pointer;

                    &:hover {
                        border: 2px solid #3900B1;
                    }

                    .img-box {
                        min-width: 62px;
                        max-width: 62px;
                        height: 62px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    &__content {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        width: 100%;

                        .title {
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            font-family: Rubik;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 16px
                        }

                        .text {
                            color: #70808F;
                            font-family: Rubik;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 16px;

                            span {
                                color: #020617;
                            }
                        }

                        .social-name {
                            display: flex;
                            align-items: center;
                            gap: 12px;

                            .icon-box {
                                min-width: 24px;
                                width: 24px;
                                height: 24px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }

                            .text {
                                display: inline-block;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                color: #0C2037;
                                font-family: "Golos Text";
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px;
                                overflow: hidden;

                                span {
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    color: #020617;
                                }
                            }
                        }

                        .status_active {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: fit-content;
                            padding-left: 8px;
                            padding-right: 8px;
                            border-radius: 4px;
                            background: #BBF7D0;
                            height: 30px;
                            color: #166534;
                            text-align: center;
                            font-family: Rubik;
                            font-size: 10px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 14px;
                        }
                    }

                    &__header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        gap: 8px;

                        .title {
                            color: var(--Base-Black, #000);
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-family: Rubik;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            margin: 0;
                        }

                        .drop-and-btn {
                            position: relative;

                            .btn-points {
                                background-color: inherit;
                                border: none;
                            }

                            .drop-content {
                                display: flex;
                                right: 0;
                                flex-direction: column;
                                background-color: white;
                                border: 2px solid #F2F2F4;
                                border-radius: 4px;
                                padding: 12px 16px;
                                width: 137px;
                                height: auto;
                                position: absolute;
                                top: 16px;

                                &__item {
                                    color: #0C2037;
                                    font-family: Rubik;
                                    font-size: 13px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 18px;
                                    cursor: pointer;
                                }
                            }
                        }


                    }
                }
            }
        }

        .post-item {
            @extend .list-post;
            width: 60%;
            max-width: 340px;

            &__header {
                display: flex;
                gap: 12px;
                justify-content: space-between;
                align-items: center;
                @extend .list-post__header;

                .text_date-create {
                    color: #64748B;
                    text-align: right;
                    font-family: Rubik;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                }
            }

            &__content {
                @extend .list-post__content;
                padding: 24px;
                overflow-x: hidden;

                .title {
                    color: #020617;
                    font-family: Rubik;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                }

                .text_date-create,
                .text_tooltip {
                    color: #9CA3AF;
                    font-family: Rubik;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }

                .text {
                    color: #020617;
                    font-family: Rubik;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }

                .img-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    aspect-ratio: 4/3;
                    border-radius: 5px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain
                    }
                }

                .btn-group {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    gap: 6px;

                    .btn,
                    .btn_active {
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        border: none;
                        background-color: #A9B3BC;

                        &:hover,
                        &_active {
                            transition: 0.5s;
                            background-color: #2E1065
                        }
                    }
                }
            }
        }

        .comment-list {
            @extend .list-post;
            border-right: none;
            width: 100%;
            overflow: hidden;


            &__header {
                @extend .list-post__header;
            }

            &__content {
                @extend .list-post__content;
                padding: 0;
                height: calc(100% - 62px);
                gap: 0;

                .comment-content {
                    overflow: auto;
                    height: 100%;
                    padding: 24px;
                }

                .comment-item {
                    display: flex;
                    gap: 12px;
                    flex-direction: row;

                    .img-box {
                        width: 42px;
                        min-width: 42px;
                        height: 42px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    &__content {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        

                        .title {
                            color: #020617;
                            font-family: Rubik;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 16px;
                        }

                        .text {
                            color: #3C4853;
                            font-family: Rubik;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;

                            
                        }

                       
                        .info-content {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            margin-bottom: 6px;

                            .text_date-create {
                                color: #8F99A3;
                                font-family: Rubik;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 16px;
                            }

                            .btn-response {
                                border: none;
                                cursor: pointer;
                                background-color: inherit;
                                color: #2E1065;
                                font-family: Rubik;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 16px;
                                text-decoration-line: underline;
                            }
                        }
                    }
                }
            }
        }
    }

    .post-box {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
    }

    .settings_connection_table {
        .cell-with-icon {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            gap: 16px;
        }
    }

    .modal-warning {
        img {
            padding-left: 0;
            padding-right: 0.5rem;
        }
    }

}