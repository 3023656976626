.modal-crm-detail {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 40%;
  width: 40%;
  min-width: 360px;
  background-color: white;
  padding: 32px 24px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  max-height: calc(100vh);
  gap: 20px;
  animation: slidein 1000ms;

  @keyframes slidein {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }

  .title {
    color: #020617;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    padding-bottom: 24px;
  }

  .top-panel {
    display: flex;
    gap: 12px;
    align-items: center;

    .input-field-wrapper {
      max-width: 100%;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e7f2;
  }

  &__btn-close,
  &__btn-settings {
    background-color: inherit;
    border: none;
    width: 16px;
    height: 16px;
  }
}
