.dragging-task {
  opacity: 0.5;
  transform: scale(0.95);
}

.col-task__loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
}
