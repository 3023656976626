.side-tabs {
    display: flex;
    flex-direction: column;
    width: 186px;
    min-width: 168px;
    border: 1px solid #EDEFFB;
    border-radius: 10px;
    overflow: hidden;

    &__title {
        padding: 10px 14px;
        background: #FFF;
        border-bottom: 1px solid #EDEFFB;
        color: #0B110B;
        font-family: Rubik;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
    }

    &__btn, &__btn_active {
        display: flex;
        padding: 10px 14px;
        text-align: left;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        width: 100%;
        border: none;
        border-bottom: 1px solid #EDEFFB;
        background: #FFF;
        color: #636D65;
        font-family: Rubik;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        &_active, &:hover {
            color: #00B45E;
        }
    }
}