@import 'src/common/styles/mixins.scss';
@import 'src/common/styles/svg_img.scss';

.landing-performance {
    &__group-btn {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;

        .btn__green {
            margin-top: 0;
            text-align: left;
            padding-left: 24px;
            padding-right: 24px;
            max-width: 100%;
            height: 56px;
            font-size: 18px;

            &_google {
                position: relative;
                @include optimizeSafariSvg(url($google_icon));
                &::before {
                    transform: scale(0.18);
                    transform-origin: 331px 9px;
                }
            }

            &_yandex {
                position: relative;
                @include optimizeSafariSvg(url($yandex_icon));
                &::before {
                    transform: scale(0.18);
                    transform-origin: 331px 9px;
                }
            }

            &_ozon {
                position: relative;
                @include optimizeSafariSvg(url($ozon_icon));
                &::before {
                    transform: scale(0.18);
                    transform-origin: 331px 9px;
                }
            }
        }
    }



    &__content-group-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 40px;

        .link__blue {
            display: inline-block;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: 23px;
            font-size: 13px;

            &::before {
                content: "";
                display: inline-block;
                vertical-align: middle;
                margin-right: 8px;
                background-image: url($info_icon);
                background-repeat: no-repeat;
                background-size: 16px;
                width: 16px;
                height: 16px;
            }
        }

    }

    &__media {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
        width: 320px;
        height: 200px;
        border-radius: 16px;
        background-color: #666464;
        margin-left: auto;
        margin-right: auto;

        .play {
            margin-top: 0;
        }
    }
}

.chats__img {
    display: inline-block;
    width: 280px;
    height: 132px;
    margin-left: auto;
    margin-right: auto;
}

.landing-performance___block_long {
    padding: 46px 16px;
    .landing__list {
        gap: 24px;
    }
}

.logo-group__img {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 72px;
    width: 248px;
    height: auto;
}