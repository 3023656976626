.key-performance-indicators {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    .toolbar-top {
        display: flex;
        gap: 24px;
        justify-content: space-between;
        align-items: center;

        .left-content {
            display: flex;
            gap: 12px;
        }
    }

    .title_main {
        color: #020617;
        font-family: Rubik;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        margin-bottom: 12px;
        letter-spacing: -0.56px;
    }

    .title {
        @extend .title_main;
        font-size: 20px;
        margin: 0;
    }

    .dashboard-item {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 24px;

        &__content {
            display: flex;
            width: 100%;
            gap: 20px;
        }
    }
}