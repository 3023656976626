.customizaton{
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 100%;

    .btn-group{
        display: flex;
        gap: 1em;
    }

    .row{
        display: flex;
        width: 100%;
        gap: 1em;
    }

    .row.over{
        background-color: rgba(0,0,0,.1);
    }
}