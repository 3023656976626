.modal-create-chat-bot {
    position: absolute;
    max-width: 762px;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px 0 0 8px;
    background: #FFF;

    &__header {
        display: flex;
        width: 100%;
        gap: 24px;
        justify-content: space-between;
        width: 100%;
        padding: 16px 24px;

        .title_main {
            white-space: nowrap;
            color: #020617;
            font-family: Rubik;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
        }
    }

    &__btn-close {
        border: none;
        background-color: inherit;
        cursor: pointer;
    }

    &__title {
        color: #020617;
        font-family: Rubik;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.4px;
    }

    &__input-box {
        display: flex;
        padding: 16px;
        border-radius: 8px;
        border: 2px solid #EDEFFB;
        background: #F8F9FE;
    }

    &__btn-box {
        display: flex;
        gap: 12px;
        padding: 16px 24px;
        display: flex;
        align-items: end;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        height: calc(100% - 72px - 66px);
        padding: 0px 24px;
        overflow: auto;
    }

    &__files-box {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }
}

.file-item {
    display: flex;
    width: 137px;
    min-width: 137px;
    height: 137px;
    padding: 20px;
    gap: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #F8F9FE;
    position: relative;

    &__btn {
        position: absolute;
        top: 2px;
        right: 2px;
        background-color: inherit;
        border: none;
        cursor: pointer;
    }

    &__icon {}

    &__name {
        color: #000;
        text-align: center;
        font-family: NunitoSans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 2; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;
        hyphens: auto;
        word-wrap: break-word;
        max-width: 100%;
    }
}

.drop-zone-2 {
    display: flex;
    width: 137px;
    min-width: 137px;
    height: 137px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 2px dashed #D9E8E1;
    background: #FFF;
    cursor: pointer;
    position: relative;
}