.menu {
  width: 250px;
  background-color: #fff;
  font-family: 'Arial', sans-serif;
  font-size: 13px;
  color: #333;
  border-radius: 4px;
  min-width: 230px;
  width: 230px;
  border: 1px solid #edeffb;
  border-radius: 8px;
  height: auto;
  overflow-y: auto;
  max-height: 600px;
  position: sticky;
  top: 20px;

  .hover-block {
    // background-color: #0056b3;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .menu-item {
    cursor: pointer;
    user-select: none;
    transition: background-color 0.2s;
    border-radius: 3px;
    position: relative;
    background-color: #fff;

    // &:hover {
    //   background-color: #0000000d;

    //   .add-child-btn {
    //     display: inline-block;
    //   }
    // }

    &__group-btn {
      display: flex;
      gap: 8px;
      margin-left: auto;
      margin-left: auto;

      .delete-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: inherit;
      }
    }

    &__arrow-box {
      display: flex;
      width: 16px;
      height: 16px;
    }

    .item-label {
      display: flex;
      align-items: center;
      padding: 10px 14px;
      border-bottom: 1px solid #edeffb;
      color: #676767;
      font-family: Golos Text;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      gap: 8px;

      &__box {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        justify-content: space-between;
      }

      .arrow {
        display: inline-block;
        cursor: pointer;
        transform: rotate(180deg);
        //  transition: transform 0.2s ease;

        &.open {
          transform: rotate(0deg);
        }
      }

      .add-child-btn {
        display: none;
        margin-left: 8px;
        cursor: pointer;
        border: none;
        background: none;
        font-size: 15px;
        color: #333;

        &:hover {
          color: #007bff;
        }
      }

      &:hover {
        color: #00b45e;
      }
    }

    .children {
      // padding-left: 20px;
      // margin-left: 8px;

      .item-label {
        padding-left: 24px;
      }

      .children {
        .item-label {
          padding-left: 34px;
        }

        .children {
          .item-label {
            padding-left: 44px;
          }

          .children {
            .item-label {
              padding-left: 54px;
            }

            .children {
              .item-label {
                padding-left: 64px;
              }
            }
          }
        }
      }
    }
  }

  // .drop-zone {
  //   height: 10px;
  //   margin: 4px 0;
  //   background-color: transparent;

  //   &:hover {
  //     background-color: #a3d4ff;
  //   }
  // }
}

.add-root-item-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  padding: 4px;
  cursor: pointer;
  border: 1px solid #007bff;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  max-width: 100%;
  width: 100%;

  &:hover {
    background-color: #0056b3;
  }
}

.modal-overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  z-index: 100;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 300px;
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  h3 {
    margin-bottom: 10px;
  }

  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
  }

  button {
    padding: 6px 12px;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid #007bff;
    background-color: #007bff;
    color: white;
    border-radius: 3px;

    &:hover {
      background-color: #0056b3;
    }
  }

  button:last-child {
    border-color: #ccc;
    background-color: #ccc;

    &:hover {
      background-color: #aaa;
    }
  }
}

.hovered-for-drop {
  background-color: #e6f7ff;
}

// .mainHover {
//   background-color: #0000000d;
// }

.modal-miltilevel-sidebar-settings {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__select-container {
    display: flex;
    gap: 12px;
  }
}