.range-box-single {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 220px;
    height: 56px;
    border: 2px solid #E0E7F2;
    background-color: #ffffff;
    border-radius: 8px;
    padding-right: 0;
    position: relative;

    .selector_wrapper {
        height: 54px;
    }

    &__tooltip {
        padding-left: 8px;
        color: #94A3B8;
        height: 16px;
        font-family: "Inter";
        font-size: 11px;
        height: auto;
        margin-top: 4px;
    }

    &:hover {
        transition: 0.5s;
        background-color: #E1E6EA;
    }

    .react-datepicker-wrapper {
        background-color: inherit;
        border-radius: inherit;
        width: 100%;
    }

    .calendar-img {
        position: absolute;
        top: calc(50% - 6px);
        right: 20px;
        pointer-events: none;

        &_active {
            transform: rotate(180deg);
        }
    }

    .date-picker {
        padding-right: 0;
        height: 100%;
        background-color: inherit;
        border-radius: inherit;

        input {
            width: 100%;
            text-align: left;
            padding-top: 0;
            padding-left: 20px;
        }

        .react-datepicker-popper {
            margin-top: 18px;
            padding-top: 0;
        }
    }

    .date-picker {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: inherit;
        // z-index: 0;

        img {
            display: none;
        }

        .react-datepicker[data-placement='bottom'] {
            display: flex;
            border-radius: 16px 16px 0 0;
            border: none;
            overflow: visible;
        }
    }

    // сегодня 

    .react-datepicker__day--today,
    .react-datepicker__month-text--today,
    .react-datepicker__quarter-text--today,
    .react-datepicker__year-text--today {
        font-weight: 400;
        color: #1890FF;
        border: 1px solid #1890FF;
        border-radius: 2px;
    }

    .date-picker-custom {

        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            line-height: 1.5rem;
            width: 1.5rem;
        }
    }


    .react-datepicker__day-names {

        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            margin: 8px;
            text-transform: uppercase;
            color: #C5C0DB;
            padding-left: 4px;
            padding-right: 4px;
            font-size: 11px;

            &:nth-child(6),
            &:nth-child(7) {
                color: red;
                text-transform: uppercase;
                font-size: 11px;
            }
        }
    }

    .react-datepicker__navigation-icon,
    .react-datepicker__navigation-icon--previous {
        display: none;
    }

    .react-datepicker__navigation--next,
    .react-datepicker__navigation--previous {
        background-image: url('./img/arrow.svg');
        background-position: center;
        background-repeat: no-repeat;
    }

    .react-datepicker__navigation--previous {
        background-image: url('./img/arrow_left.svg');
    }



    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        font-weight: 500;
        font-size: 15px;
    }


    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        padding-bottom: 11px;
        padding-top: 11px;
        background-color: #ffffff;
        border-radius: 4px;
        padding: 10px 14px;
    }

    .react-datepicker__current-month {
        font-size: 15px;
        font-weight: 500;
    }

    .react-datepicker__header {
        border: none;
        background-color: #fff;
        border-radius: 4px;
        position: static;
        padding: 0;
    }

    .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
    .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
    .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
    .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
        background-color: #F0F0F0;
        border-radius: 4px;
        color: #1890FF;

        &:hover {
            background-color: #F5F5F5;
            border-radius: 4px;
            color: #020617;
        }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-,
    .react-datepicker__day--keyboard-selected {
        background-color: #F0F0F0;
        border-radius: 0px;
        border-radius: 4px;

        // &:last-child {
        //     background-color: #49A7FE;
        //     color: white;
        //     border-radius:  0 4px 4px 0;
        // }

        &:hover {
            background-color: #F0F0F0;
            border-radius: 0px;
            border-radius: 4px;
        }
    }

    .react-datepicker__day {
        //padding: 0 8px;
        margin: 6px 8px;

        &:hover {
            background-color: #F5F5F5;
            border-radius: 2px;
            color: #020617;
        }
    }

    .react-datepicker__month-read-view--selected-month,
    .react-datepicker__year-read-view--selected-year {
        font-weight: 400;
        font-size: 13px;
    }



    .react-datepicker__year-dropdown-container,
    .react-datepicker__month-dropdown-container {
        width: 80px;
        margin: 0;
    }

    .react-datepicker__month-read-view {
        display: flex;
        text-align: left;
    }

    .react-datepicker__month-read-view--down-arrow {
        top: 4px;
        right: 0;
    }

    .react-datepicker__navigation {
        z-index: 0;
    }

    .react-datepicker__week {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    //контейнер выбора месяца
    .react-datepicker__year-dropdown,
    .react-datepicker__month-dropdown,
    .react-datepicker__month-year-dropdown {
        webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        background-color: white;
        min-width: 200px;
        max-width: 200px;
        width: 100%;
        border: none;
        top: 0px;
        left: 5px;
    }

    .react-datepicker__year-option,
    .react-datepicker__month-option,
    .react-datepicker__month-year-option {
        padding: 4px 16px;

        &:hover {
            background-color: #F0F0F0;
            border-radius: 0px;

        }
    }

    //контейнер для селектов месяца и года

    .react-datepicker__header__dropdown {
        display: flex;
        gap: 20px;
        top: 48px;
        position: relative;
        justify-content: center;
        position: absolute;
        max-width: 585px;
        width: auto;
        align-items: center;
        left: calc(50% - 100px);
        background-color: white;
        padding: 2px 6px;


        .react-datepicker__year-dropdown {}
    }


    //кнопка вверх, вниз
    .react-datepicker__navigation--years-upcoming,
    .react-datepicker__navigation--years-previous {
        background-image: url('./img/arrow-next.svg');
        background-repeat: no-repeat;
        background-position: center;
        width: 16px;
        height: 14px;
        transform: rotate(-90deg);
    }

    .react-datepicker__navigation--years-previous {
        transform: rotate(90deg);
    }

    .react-datepicker__day-names {
        margin-top: 40px;
    }


}

.react-datepicker {
    border: none;
}


.date-picker-custom {
    display: flex;
    gap: 20px;
    align-items: end;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    border: none;
    width: max-content;
    overflow: visible;
    align-items: start;

    // div {
    //     box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    //     border: none;
    //     border-radius: 8px;

    //     * {
    //         box-shadow: none;
    //     }
    // }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        line-height: 1.4rem;
        width: 2rem;
    }

    .text {
        color: #5F5F5F;
        font-size: 13px;
    }


}

// .range-toolbar {
//     display: flex;
//     align-items: center;
//     gap: 8px;
//     width: max-content;

//     .btn-range {
//         background-color: #F1F5F9;
//         height: 40px;
//         white-space: nowrap;
//         padding: var(--space-x-3, 12px) var(--space-x-2, 8px);
//         border-radius: 8px;
//         border: 1px solid #C5CCD3;

//         &:hover {
//             transition: 0.5s;
//             background-color: #E1E6EA;
//         }

//         &:active {
//             border: 1px solid #1890FF;
//         }
//     }
// }