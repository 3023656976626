.sceleton-card-order {
    display: flex;
    min-height: 92px;

    &__content {
        display: flex !important;

        span {
            border-radius: 12px;
        }
    }
}