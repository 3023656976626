// @import url('../../../font.scss');

.list-of-content {
    top: 20px;
    height: 600px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 65vh;
    position: sticky;

    &__title {
        color: #9F9F9F;
        font-family: "Golos Text";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-transform: uppercase;

        a {
            color: #1890ff;
            text-decoration: underline;
        }
    }

    &__seo-box {
        display: flex;
        flex-direction: column;
        gap: .5em;
        margin-bottom: 1em;
    }

    &__links {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 10px 22px;
        width: 230px;
        min-width: 230px;
        background-color: inherit;
        border-radius: 16px;
        height: auto;
        border-radius: 10px;
        background: #FFF;
        border: 1px solid #EDEFFB;
        overflow: overlay;
        gap: 10px;
    }

    &__link {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #5C5C5C;
        font-family: "Golos Text";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        word-break: break-word;


        span {
            color: inherit !important;
            font-family: "Golos Text" !important;
            font-size: 12px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 18px !important;
            word-wrap: break-word !important;
        }
    }

    &__point {
        display: inline-block;
        background-color: #BDBDBD;
        border-radius: 2px !important;
        width: 10px;
        min-width: 10px;
        height: 10px;
    }

    &__label {
        display: -webkit-box;
        -webkit-line-clamp: 3; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;
        hyphens: auto;
        overflow: hidden;
    }

    .share-btn {
        width: 100%;
        max-width: 100%;
    }

    .share__drop-container {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 1240px) {
    .list-of-content {
        display: none;
    }
}