.drop-zone {
    display: flex;
    width: 100%;
    min-height: 100px;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 2px dashed #C5CCD3;
    position: relative;
    cursor: pointer;

    &:hover {
        transition: 0.5s;
        border: 2px dashed #1890FF;
    }

    img {
        width: 24px;
        height: 24px;
    }

    p {
        color: #475569;
        text-align: center;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;

        span {
            color: #1890FF;
        }
    }

    .input-file {
        position: absolute;
        cursor: pointer;
        opacity: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}