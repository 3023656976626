.frequent-answers {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: calc(100% - 28px);

    .title {
        color: #94A3B8;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        .response-template {
            display: flex;
            padding: 0px 8px;
            align-items: center;
            gap: 13px;
            border-radius:8px;
            border: 1px solid #80C2FF;
            background: #E6F7FF;
            color: #4CA9FF;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            white-space: nowrap;
            cursor: pointer;
        }

        .tooltip-container {
            position: relative;
            width: 24px;
            height: 24px;

            .tooltip_light-blue {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                z-index: 11;
                top: 36px;
                left: 15px;
                padding: 6px;
                border-radius: 2px;
                border-radius: 2px;
                border: 1px solid var(--slate-200, #C5CCD3);
                background: var(--slate-100, #F1F5F9);
                color: #005DB2;
                font-family: Inter;
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                white-space: nowrap;
            }

            &:hover {
                .tooltip_light-blue {
                    opacity: 1;
                    visibility: visible;
                    transition: 0.5s;
                }
            }

            .notification-count {
                position: absolute;
                top: -4px;
                left: 22px;
                border-radius: 8px;
                background-color: #48A8FE;
                display: flex;
                min-width: 16px;
                width: auto;
                padding-left: 4px;
                padding-right: 4px;
                height: 16px;
                justify-content: center;
                align-items: center;
                z-index: 1;
                color: white;
                font-size: 11px;
            }
        }

        .btn-round {
            width: 24px;
            border-radius: 50%;
            height: 24px;
            background-size: 24px;
            background-image: url('./img/add_round.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-repeat: no-repeat;
            border: none;
            background-color: inherit;
        }
    }
}