$grey-blue: #1890FF;
$blue-light: #E6F7FF;

body {

    .tooltip-blue,
    .tooltip-blue_active {
        display: flex;
        align-items: start;
        gap: 12px;
        padding: 8px 12px;
        border-radius: 8px;
        width: 100%;
        background-color: $blue-light;
        color: $grey-blue;
        font-size: 13px;
        border: 1px solid #1890FF;
        opacity: 0;
        visibility: hidden;
        transition: 1.5s;
        animation: hidden 1.5s forwards;
        max-width: fit-content;

        @keyframes hidden {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        &_active {
            opacity: 1;
            visibility: visible;
            transition: 2s;
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .title {
                color: var(--text-accent-blue, #1890FF);
                font-family: Inter;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }
        }

        .close {
            margin-left: auto;
            cursor: pointer;
        }
    }

}

.text_grey-blue {
    color: #80C2FF;
    font-size: 13px;
}