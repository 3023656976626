.reset-password {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: calc(560px + 96px);
    width: 100%;
    height: auto;
    background-color: #F5F5F5;
    padding-left: 48px;
    padding-right: 48px;
    justify-content: space-between;

    &__input-field {
        width: 100%;
        height: 100%;
        font-family: 'Inter';
        font-size: 13px;
        padding: 16px 20px;
        margin-top: 20px;
        border: 1px solid #F0F0F0;
        border-radius: 16px;

        &:focus {
            outline: none;
            border: 1px solid #1890FF;
            -webkit-box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
            -moz-box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
            box-shadow: 0px 0px 9px -3px rgba(24, 144, 255, 1);
        }

        &::placeholder {
            font-size: 13px;
            font-family: "Inter";
            color: rgb(179, 179, 179);
        }
    }
}