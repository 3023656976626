.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal_csv_container {
  width: 80%;
  height: 80%;
  background: white;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  h2 {
    margin-bottom: 1em;
  }

  // button {
  //   width: 100%;
  //   margin: 0.5em 0;
  //   padding: 0.75em;
  //   border: none;
  //   border-radius: 5px;
  //   background: #007bff;
  //   color: white;
  //   font-size: 1rem;
  //   cursor: pointer;
  //   transition: background 0.3s ease;

  //   &:disabled {
  //     background: #ccc;
  //     cursor: not-allowed;
  //   }

  //   &:hover:not(:disabled) {
  //     background: #0056b3;
  //   }
  // }

  .table-container {
    flex: 1;
    width: 100%;
    overflow-y: auto;
  }
}
