@import 'src/common/styles/mixins.scss';
@import 'src/common/styles/svg_img.scss';

.profile-settings {
    z-index: 11;
    display: flex;
    gap: 1px;
    flex-direction: column;
    width: 100%;
    height: 100px;
    position: absolute;
    top: calc(100% + 1px);
    right: 0px;
    min-width: 190px;
    max-width: 190px;
    height: 96px;
    border: 1px solid #EDEFED;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(30, 41, 59, 0.06);
    border-radius: 8px;
    background-color: #F0F0F0;

    &__btn {
        height: 33.33%;
        background-color: white;
        border: none;
        cursor: pointer;
        text-align: left;
        padding-left: 8px;
        padding-right: 8px;
        color: #1E293B;
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &_exit {
            position: relative;
            background-image: url('./img/exit_icon.svg');
            background-position: center right 10px;
            background-repeat: no-repeat;

            &::before {
                transform: scale(0.12);
                transform-origin: 132px 10px;
            }
        }

        &:hover {
            transition: color 0.3s;
            color: #51CA64;
        }

        &:active {
            transition: color 0.3s;
            color: #51CA64;
        }

        &:nth-child(1) {
            border-radius: 8px 8px 0 0;
        }

        &:last-child {
            border-radius: 0 0 8px 8px;
        }
    }
}