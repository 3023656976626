.image-slider {
    display: flex;
    width: fit-content;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    &__img-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        min-width: 500px;
        max-width: 500px;
        width: fit-content;
        height: 300px;
        border: 1px solid #EDEFED;
        border-radius: 8px;
        background: #fff;
        overflow: hidden;
    }

    &__group-btn {
        display: flex;
        gap: 12px;
    }

    &__img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
    }
    
    &__no-img {
        margin: auto;
    }
}