@import '../../../common/styles/mixins.scss';
@import '../../../common/styles/svg_img.scss';

.blackout {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-height: 100vh;
    overflow: hidden !important;

    // .input-field {
    //     margin-top: 24px;
    //     max-width: 446px;
    //     border-radius: 16px;
    // }

    .input-field.search {
        max-width: 100%;
    }

    .modal-add-public.edit {
        min-height: 400px;

        .btn-group {
            display: flex;
            justify-content: space-between;
        }

        .btn-delete {
            margin-left: auto;
            width: 16px;
            height: 20px;
            border: none;
            cursor: pointer;
            background-image: url($icon_delete);
            background-color: unset;
            background-repeat: no-repeat;
        }

        .modal-add-public__box-items {
            min-height: 160px;
        }
    }

    .modal-add-public {
        display: flex;
        flex-direction: column;
        gap: 24px;
        justify-content: space-between;
        position: relative;
        z-index: 1000;
        margin-left: auto;
        margin-right: auto;
        max-width: 640px;
        width: 100%;
        min-width: 360px;
        background-color: white;
        min-height: 640px;
        padding: 24px;
        border-radius: 16px;
        max-height: calc(100vh - 60px);

        .text_grey {
            color: #888888;
        }

        .add-name-public {
            display: flex;
            align-items: center;
            gap: 24px;
            margin-top: 29px;

            .text {
                white-space: nowrap;
                margin-top: 0;
            }

            .input-field-wrapper {
                margin-top: 0;
            }

            .pencil {
                position: absolute;
                z-index: 1;
                right: 40px;
                pointer-events: none;
            }

        }

        .btn__green {
            margin-top: 24px;
            max-width: 240px;
        }

        .title {
            font-size: 15px;
            line-height: 24px;
            font-weight: 500;

            &:nth-of-type(2) {
                margin-top: 40px;
            }
        }

        .text {
            margin-top: 23px;
        }



        &__box-items {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 24px;
            padding-left: 16px;
            padding-right: 16px;
            overflow: auto;
            height: 1px;
            min-height: 100px;
            max-height: 160px;
            border: 1px solid;
            border-color: #F0F0F0;
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
            padding-left: 16px;
            padding-top: 10px;

            .authorization-checkbox__container {
                display: flex;
                gap: 14px;
                align-items: center;


            }

            .text-and-icon-box {
                display: flex;
                gap: 8px;
                align-items: center;
            }
        }

        .delete-posts-checkbox {
            padding-left: 16px;
            margin-top: 16px;
            display: inline-block;
        }

        &__group-btn {
            display: flex;
            gap: 16px;


            .btn__green,
            .btn__grey,
            .btn__bej {
                max-width: 120px;
                min-width: 120px;
                margin-top: 0;
                height: 32px;
            }

            .btn__grey {
                border: none;
            }

            .btn__delete {
                margin-left: auto;
            }
        }
    }

    .modal-add-account {

        .close {
            margin-left: auto;
            position: absolute;
            right: 1.5em;
            top: 1.5em;
        }

        &__box-items {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 24px;
            padding-left: 16px;
            padding-right: 16px;
            overflow: auto;
            height: 1px;
            min-height: 200px;
            max-height: 300px;
            border: 1px solid;
            border-color: #F0F0F0;
            background-color: white;
            width: 100%;
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
            padding-left: 16px;
            padding-top: 10px;

            .authorization-checkbox__container {
                display: flex;
                gap: 14px;
                align-items: center;
            }

            .text-and-icon-box {
                display: flex;
                gap: 8px;
                align-items: center;
            }
        }
    }
}