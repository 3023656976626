.modal-adding-ready-scenario {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2000;
    display: flex;
    max-width: 1632px;
    width: calc(100% - 40px);
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    height: calc(100vh - 40px);
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    padding: 24px;
    gap: 24px;

    .date-box-single {
        min-width: 210px;
        max-width: 210px;
        height: 40px;

        input {
            background-color: #FFF;
        }
    }

    &__title-box {
        display: flex;
        gap: 12px;
        overflow: hidden;
        padding-top: 10px;
    }

    &__title {
        align-items: end;
        gap: 12px;
        white-space: nowrap;
        color: #020617;
        font-family: NunitoSans;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        span {
            color: #00B45E;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &__step-item {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__step-info {
        display: flex;
        align-items: center;
        color: #0A30CE;
        font-family: NunitoSans;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        gap: 16px;
    }

    &__step-description {
        color: #000;
        font-family: NunitoSans;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    &__input-and-btn {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .input-dinamyc-placeholder {
        min-width: 230px;
    }

    &__btn-done {
        width: 40px;
        min-width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background: #EEE;
        border: none;
    }

    &__btn-edit {
        width: auto;
        height: 28px;
        // display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: inherit;
        border: none;
    }

    &__btn-loader {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__loader-box {
        width: 24px;
        height: 24px;
        min-width: 24px;
    }

    &__toolbar-top {
        display: flex;
        gap: 24px;
        align-items: flex-start;

        .textarea-dynamic-placeholder__content {
            min-height: 92px;
        }

        .filters-container {
            max-width: 432px;
            min-width: 432px;
        }
    }

    &__group-btn {
        display: flex;
        gap: 12px;
    }

    &__label {
        display: flex;
        gap: 8px;
        align-items: center;
    }

    &__label-placeholder {
        display: inline-block;
        min-width: 86px;
        white-space: nowrap;
        color: #475569;
        font-family: NunitoSans;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    &__header {
        display: flex;
        gap: 60px;
        width: 100%;
        justify-content: space-between;
        border-radius: 8px 8px 0px 0px;

        .input-dinamyc-placeholder {
            max-width: 345px;
            margin-right: auto;
        }
    }

    &__header-right,
    &__header-left {
        display: flex;
        gap: 20px;
        align-items: center;
        // overflow: hidden;
        // padding-top: 10px;
        max-width: 100%;
    }

    &__btn-close {
        background-color: inherit;
        border: none;
        cursor: pointer;
    }

    &__main-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: calc(100% - 80px);
        gap: 24px;
    }

    .prepared_scenarios {
        display: flex;
        width: calc(100%);
        flex-direction: column;
        gap: 12px;
        overflow: auto;
        padding-bottom: 24px;
        max-height: calc(100%);
        height: 100%;

        &::-webkit-scrollbar {
            width: 0;
        }

        &__btn {
            display: flex;
            align-items: center;
            gap: 8px;
            justify-content: center;
        }

        &__no-group {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: 12px;
        }

        &__no-group-title {
            color: #334155;
            font-family: NunitoSans;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
        }

        &__no-group-description {
            color: #000;
            text-align: center;
            font-family: NunitoSans;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
        }


        &__group {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 20px;

            &:nth-last-child(1) {
                padding-bottom: 150px;
            }


        }

        &__group-content,
        &__group-content_hide {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 20px;

            &_hide {
                max-height: 0;
                overflow: hidden;
            }
        }

        &__group-header {
            display: flex;
            gap: 24px;
            width: 100%;
            align-items: center;
            justify-content: start;
            cursor: pointer;
        }

        &__header {
            display: flex;
            gap: 10px;

            .title_category,
            .title_col,
            .title_response-delay {
                display: flex;
                gap: 4px;
                align-items: center;
                //  flex-wrap: wrap;
                color: #334155;
                font-family: Rubik;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
            }

            .title_response-delay {
                width: 100%;
                min-width: 120px;
                max-width: 120px;
            }

            .title_category {
                width: 100%;
                min-width: 400px;
                max-width: 400px;

                button {
                    background-color: inherit;
                    color: #00B45E;
                    font-family: NunitoSans;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 16px;
                    margin-left: auto;
                }
            }

            .title_col {
                min-width: 210px;
                max-width: 210px;

                img {
                    width: 16px;
                    height: 16px;
                }

                &:nth-child(2) {
                    min-width: 300px;
                    max-width: 300px;
                }

                &:last-child {
                    min-width: 200px;
                    max-width: 200px;
                }
            }
        }

        .selector_wrapper {
            &:nth-child(2) {
                min-width: 300px;
                max-width: 300px;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 12px;
            height: 100%;

            .btn-setting,
            .btn-more {
                width: 40px;
                height: 40px;
                min-width: 40px;
                border: none;
                border-radius: 8px;
                background: #FAFBFA;
                background-image: url('./img/setting_icon.svg');
                background-repeat: no-repeat;
                background-position: center;
            }

            .btn-more {
                background-image: url('./img/bx-dots-horizontal-rounded.svg')
            }

            .row-item {
                display: flex;
                width: 100%;
                align-items: center;
                gap: 10px;

                &__container {
                    display: flex;
                    align-items: center;
                    gap: 24px;
                }

                .category-item {
                    display: flex;
                    min-height: 40px;
                    padding: 8px 20px;
                    justify-content: space-between;
                    align-items: flex-start;
                    gap: 10px;
                    border-radius: 8px;
                    border: 1px solid #DDD;
                    background: #F4F6F4;
                    width: 100%;
                    overflow: hidden;
                    color: #020617;
                    font-family: Rubik;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    width: 100%;
                    max-width: 400px;
                    min-width: 400px;

                    &__star-box {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        height: 100%;
                    }

                    &__toggle {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        min-width: 180px;
                        max-width: 180px;
                    }

                    span {
                        display: inline-block;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        width: 85%;
                    }

                    &__input {
                        width: calc(100% - 50px);
                        color: #020617;
                        font-family: Rubik;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        background-color: inherit;
                        border: none;

                        ::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                        }

                        &:focus {
                            outline: none;
                        }
                    }

                    img {
                        width: 20px;
                        height: 20px;
                        margin-left: auto;
                        display: none;
                        cursor: pointer;
                    }

                    &:hover {
                        img {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}