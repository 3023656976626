// @import url('../../../font.scss');

.article-block-wrapper {
    display: flex;

    &__drag {
        width: 8px;
        height: 8px;
        max-height: 8px;
        border-radius: 50%;
        background-color: #029CFD;
        margin-top: 10px;
        position: relative;
        left: -8px;
        cursor: grab;
       
    }

    &__close {
        cursor: pointer;
        margin-top: 10px;
        width: 10px;
        height: 10px;
        background-color: rgb(0, 0, 0);
        clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
    }

    &__text {
       padding-bottom: 24px; 
    }

    &__title {
        padding: 32px 0px;

        .article-block-wrapper {
            &__drag {
                margin-top: 20px;
            }

            &__close {
                margin-top: 20px;
            }
        }
    }
}

.article-text {
    display: inline-block;
    width: 100%;
    color: #191919;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    white-space: pre-wrap;
    word-break: break-word;
    padding: 0;

    p,
    span,
    li {
        color: inherit !important;
        font-family: inherit !important;
        font-size: inherit !important;
        font-style: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
    }

    br {
        content: "";
        display: block;
        height: 18px;
    }

    a {
        color: #1890ff;
        text-decoration: underline;
    }

    .link {
        color: #029CFD;
        text-decoration: underline;
    }
}

.link_modal {
    z-index: 1;
    width: 100%;
    //   position: absolute;
    bottom: 0;
    padding: 8px;
    background-color: #fff;

    .link_modal_container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1em;
        border-radius: 1em;
        background: white;
        width: 100%;

        .link_modal_input {
            border: 1px solid grey;
            outline: 1px solid grey;
            border-radius: .5em;
        }
    }

}

.article-text[contenteditable="true"] {
    outline: none;


    &:empty {
        width: 100%;
        //  border: 1px solid red;

        &::after {
            content: "Введите текст";
            color: #D9D9D9;
        }
    }
}

@media (max-width: 1100px) {
    .article-text {
        font-size: 18px !important;
    }

    .article-block-wrapper__text {
        padding-bottom: 18px;
    }

    .article-block-wrapper__title {
        padding: 24px 0px;
    }
}

@media (max-width: 430px) {
    .article-text {
        font-size: 16px !important;
        padding-bottom: 14px;
    }

    .article-block-wrapper__text {
        padding-bottom: 14px;
    }

    .article-block-wrapper__title {
        padding: 24px 0px 18px 0px;
    }
}