.description-inside-content {
    display: flex;
    gap: 12px;
    width: 100%;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
    height: 100%;

    .content-img {
        background-color: #fff;
    }

    .text-box {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        padding: 16px 24px;
        border-radius: 8px;
        background: #FFF;

        &__header {
            display: flex;
            gap: 24px;
        }

        .text_description {
            color: #64748B;
            font-family: Rubik;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }
    }

    .general-information,
    .general-information_hide {
        display: flex;
        flex-direction: column;
        width: 380px;
        height: 100%;
        gap: 24px;
        border-radius: 0px 9px 8px 0px;
        border-left: 1px solid #EDEFED;
        background: #FFF;
        min-width: 380px;
        max-width: 380px;
        padding: 22px 24px;
        overflow: auto;
        position: relative;
        transition: 0.5s;
        overflow-x: hidden;

        &_hide {
            transition: 0.5s;
            max-width: 0;
            min-width: 0;
            padding-left: 0;
            padding-right: 0;
        }

        .btn-back {
            border: none;
            background-color: inherit;
            cursor: pointer;
            top: 18px;
            right: 24px;
            position: absolute;
        }

        .title_info {
            color: #0B110B;
            font-family: NunitoSans;
            font-size: 11px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            text-transform: uppercase;
            min-width: calc(380px - 48px);
        }

        .info-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 12px;
            min-width: calc(380px - 48px);

            &__item {
                display: flex;
                align-items: end;
                justify-content: space-between;
                gap: 12px;
            }

            .text_label {
                color: #606D68;
                font-family: NunitoSans;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 12px;
                letter-spacing: -0.28px;
                width: inherit;
            }

            .text_value,
            .text_value-blue {
                word-break: break-word;
                color: #0B110B;
                text-align: right;
                font-family: NunitoSans;
                font-size: 13px;
                font-style: normal;
                font-weight: 600;
                line-height: 12px;
                letter-spacing: -0.28px;
                max-width: inherit;
            }

            .text_value-blue {
                color: #0009E8;
            }

        }
    }

}