.settings {

    &__main-box {
        display: flex;
        align-items: start;
        flex-direction: column;
        gap: 16px;
        height: auto;
        max-width: calc(100% - 220px - 60px) !important;
        min-height: 720px;
        width: calc(100% - 220px - 60px) !important;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 18px;
        width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;

        .title {
            color: #0C2037;
            font-family: Rubik;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0;
        }

        .title-box {
            cursor: pointer;
            max-width: 240px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            // padding-right: 16px;
            // padding-left: 24px;
            gap: 8px;
        }
    }


}

.flex {
    display: flex;
    gap: 16px;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    margin: 0;
}