@import 'src/common/styles/svg_img.scss';

.modal-add-project {
  z-index: 1000;
  margin-left: auto;
  margin-right: auto;
  min-width: 360px;
  background-color: white;
  border-radius: 10px;
  max-height: calc(100vh - 120px);
  gap: 16px;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 24px;
  min-height: 600px;
  max-height: 600px;

  &__title {
    color: #020617;
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
  }

  &__section {
    display: flex;
    flex-direction: column;
    width: 33%;
    gap: 10px;

    .range-toolbar,
    .range-box {
      width: 100%;
      max-width: 100%;
    }

    .textarea-dynamic-placeholder__content {
      min-height: 156px;
    }
  }

  &__add-executors {
    display: flex;
    align-items: center;
    gap: 32px;
    height: fit-content;

    .select_executor__placeholder {
      display: none;
    }
  }

  .executors {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .executor-content {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__label,
  .select_executor__placeholder,
  .executor__placeholder {
    color: #575757;
    font-family: NunitoSans;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  &__request-item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 240px;
  }

  &__group-btn {
    display: flex;
    gap: 8px;
  }

  &__request-list {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
  }

  &__fields-row {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 12px;
  }

  &__fields-group {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 12px;
  }

  &__fields-item {
    display: flex;
    gap: 12px;
  }

  &__fields-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .btn-basic {
    path {
      fill: #ee9023;
    }
  }

  &__header {
    width: 100%;
    display: flex;
    gap: 24px;
    align-items: center;
    color: #404e8c;

    .input-dinamyc-placeholder {
      max-width: 290px;
    }

    .right-content {
      display: flex;
      align-items: center;
      margin-right: 10px;

      .tooltip-box {
        position: relative;

        .tooltip_black {
          opacity: 0;
          visibility: hidden;
          background-color: #4a4a4a;
          border-radius: 2px;
          overflow: hidden;
          color: white;
          position: absolute;
          font-size: 11px;
          top: calc(100% + 4px);
          right: 0;
          z-index: 100;
          width: max-content;
          height: auto;
          padding: 2px 4px;
          border: 1px solid #e0e7f2;
        }

        &:hover {
          .tooltip_black {
            opacity: 1;
            visibility: visible;
            transition: 0.5s;
          }
        }
      }

      .btn-link {
        display: flex;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        border: 1px solid #e0e7f2;
        background: #f5f7f8;
        background-image: url('./img/link_icon.svg');
        background-size: 14px;
        background-repeat: no-repeat;
        background-position: center;
      }

      .text_date {
        color: #8f99a3;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-right: 16px;
      }
    }
  }

  &__content {
    display: flex;
    gap: 24px;
    position: relative;
    align-items: stretch;
    height: 100%;

    .select_executor__group_executor {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 320px;
    }

    .select_executor__content {
      gap: 20px;
    }

    .executor-placeholder {
      min-height: 48px;
      width: 48px;
      height: 48px;
      min-width: 48px;
    }
  }

  .img-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 156px;
    border-radius: 8px;
    border: 1px solid #edeffb;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: 0.5s;

    &_blackout {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: black;
      opacity: 0;
      transition: 0.5s;

      span {
        position: relative;
        z-index: 1;
        opacity: 1;
        color: #ebf5ff;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }

    &:hover {
      .img-box_blackout {
        opacity: 0.6;
      }
    }

    .img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  //option status
  .option-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;

    .status_red {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #fecaca;
      border: 1px solid #ef4444;
    }

    .status_yellow {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #ffedd5;
      border: 1px solid #fdba74;
    }

    .status_green {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #bbf7d0;
      border: 1px solid #15803d;
    }
  }

  .main-info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 60%;

    .input-box {
      margin-top: 0;
    }
  }

  .additional-information {
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-width: 40%;
    max-width: 40%;
    // overflow-y: auto;

    .range-box-single .date-picker input {
      padding-bottom: 0;
      height: 56px;
    }

    .select-group {
      display: flex;
      gap: 12px;
      align-items: center;

      .selector_wrapper {
        min-width: calc(50% - 6px);
      }
    }

    .info-group {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
    }

    .btn-group {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: end;
    }
  }

  .selected-photo-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .text {
    color: #8f99a3;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .search_add-executor_active {
    cursor: pointer;
    color: #020617;
    outline: none;
  }

  .search-box-executor {
    position: relative;
    width: 100%;
    height: 48px;
    margin-bottom: 12px;

    .search_add-executor,
    .search_add-executor_active {
      position: relative;
      width: 100%;
      height: 48px;
      border-radius: 22px;
      padding: 10px;
      transition-duration: 1000ms;
      cursor: pointer;
      background-repeat: no-repeat;
      font-size: 11px;
      color: black;
      //border: 1px solid #F0F0F0;
      border: none;
      overflow: hidden;
      background-image: url('./img/executor_icon.svg');
      background-color: white;
      background-size: 48px;
      background-position: 0% 50%;
      padding-left: 56px;

      &::placeholder {
        color: #0009e8;
        font-family: NunitoSans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      &:focus {
        outline: none;
      }
    }

    .options-box,
    .options-box_active {
      position: absolute;
      left: 0;
      top: calc(100% + 4px);
      display: flex;
      width: 100%;
      flex-direction: column;
      border-radius: 16px;
      background-color: white;
      position: absolute;
      -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
      right: -24px;
      cursor: pointer;
      transition: 1s;
      visibility: hidden;
      opacity: 0;
      transition: all 1s;
      overflow: hidden;
      max-height: 350px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .options-box_active {
      z-index: 1;
      visibility: visible;
      opacity: 1;
      transition: all 1s;
    }
  }

  .text_grey {
    font-size: 11px;
    color: #888888;
  }

  .text-no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    font-size: 11px;
  }

  .tooltip-executors-box {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;

    .close-executor {
      width: 16px;
      height: 16px;
      position: absolute;
      right: -15px;
      top: -4px;
      border-radius: 16px;
      border: none;
      cursor: pointer;
      background-image: url($close);
      background-color: #f5f5f5;
      background-repeat: no-repeat;
      background-size: 8px;
      background-position: center;
      opacity: 0;
      visibility: hidden;
      z-index: 2;
    }

    img {
      position: absolute;
      top: 0;
      right: -10px;
    }

    .tooltip-executors {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 34px;
      border-radius: 16px;
      padding: 8px 16px;
      background-color: white;
      -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
    }

    .text-box {
      display: flex;
      flex-direction: column;

      .text {
        font-size: 11px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 180px;
      }

      .text_grey {
        font-size: 10px;
        color: grey;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 180px;
      }
    }
  }

  .tooltip-executors-box:hover {
    .tooltip-executors {
      opacity: 1;
      visibility: visible;
      position: absolute;
      top: 34px;
      border-radius: 16px;
      padding: 8px 16px;
      background-color: white;
      -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
      transition: all 0.5s;
    }

    .close-executor {
      width: 16px;
      height: 16px;
      position: absolute;
      right: -15px;
      top: -4px;
      border-radius: 16px;
      border: none;
      cursor: pointer;
      background-image: url($close);
      background-color: #d9d9d9;
      background-repeat: no-repeat;
      background-size: 8px;
      background-position: center;
      opacity: 1;
      visibility: visible;
      transition: all 0.5s;
    }
  }

  .modal-add-task {
    .flag_red {
      stroke: #fa0f0f !important;

      path {
        stroke: #fa0f0f !important;
      }
    }

    .flag_green {
      stroke: #27bc62 !important;

      path {
        stroke: #27bc62 !important;
      }
    }

    .flag_yellow {
      stroke: #fa9c0f !important;

      path {
        stroke: #fa9c0f !important;
      }
    }

    .flag_blue {
      stroke: #1890ff !important;

      path {
        stroke: #1890ff !important;
      }
    }
  }
}
