.card-dynamic {
    display: flex;
    flex-direction: column;
    width: 273px;
    min-width: 273px;
    border-radius: 8px;
    background: #FFF;
    padding: 12px 14px 10px 14px;
    border-radius: 10px;
    background: #FFF;
    position: relative;
    cursor: pointer;

    &__info {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    &:hover {
        transition: 0.5s;
        background-color: #DEEAFF;
    }

    .title_min {
        top: -10px;
        display: flex;
        padding: 2px 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        border-radius: 4px;
        background: #FFF;
        position: absolute;
        overflow: hidden;
        color: #0B110B;
        text-overflow: ellipsis;
        font-family: NunitoSans;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 1; // количество строк
        -webkit-box-orient: vertical;
        overflow: hidden;
        hyphens: auto;

        //  min-height: 32px;
    }

    .text {
        color: #0B110B;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
    }

    .text_grey {
        font-family: NunitoSans;
        color: #9199A5;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
    }

    .dynamic_neutral,
    .dynamic_positive,
    .dynamic_negative {
        position: absolute;
        top: calc(50% - 10px);
        right: 36px;
        display: flex;
        padding: 2px 4px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        width: fit-content;
        border-radius: 128px;
        color: #656565;
        background: #D3D3D3;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        padding-left: 8px;
        padding-right: 8px;
    }

    .dynamic_positive {
        color: #218931;
        background: #E1F9E5;
    }

    .dynamic_negative {
        color: #DA0000;
        background: #F9E1E1;
    }
}