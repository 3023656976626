$--grey--border: #D1D5DB;
$--bcg: #fff;
$--text: #0B110B;
$--grey: #5A6577;

// Окно чата

.chat_message_page {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    border-radius: 10px;
    border: 1px solid $--grey--border;
    background-color: $--bcg;
    max-height: 80vh;

    // Панель выбора проектов

    &__side_panel {
        display: flex;
        flex-direction: column;
        gap: 18px;
        border-right: 1px solid $--grey--border;
        height: 100%;
        width: 216px;
        min-width: 216px;
    }

    &__title {
        font-family: NunitoSans;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        padding: 16px;
        padding-bottom: 0;
    }

    &__project_item,
    &__project_item_active {
        display: flex;
        padding: 6px 8px;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        cursor: pointer;
        color: #6B7280;
        font-family: NunitoSans;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;

        &:hover {
            transition: 0.5s;
            background: #F0FDF4;
        }

        &_active {
            background-color: #60CB71;
            color: #fff;

            &:hover {
                transition: 0.5s;
                background-color: #60CB71;
                color: #fff;
            }
        }
    }

    &__project_list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        overflow: auto;
        padding: 16px;
    }

    // Список чатов

    &__list_message {
        border-right: 1px solid $--grey--border;
        width: 40%;
        max-width: 400px;
        height: 100%;
        
    }

    &__container_list_message {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: auto;
        height: 100%;
        max-height: calc(100% - 184px);

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 18px;
        padding: 20px;
        border-bottom: 1px solid #D1D5DB;

        .input-field-wrapper {
            max-width: 100%;
        }
    }

    &__btn_group {
        display: flex;
        gap: 12px;
    }

    // Активный чат

    &__current_chat {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    &__header_message {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 12px 20px;
        width: 100%;
        min-height: 67px;
        border-bottom: 1px solid $--grey--border;
        position: relative;
    }
    
    &_close_btn{
        position: absolute;
        right: .5em;
        top: .25em;
        cursor: pointer;
    }

    &__user_icon {
        width: 42px;
        height: 42px;
        border-radius: 50%;
    }

    &__name_chat {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #111827;
        font-family: NunitoSans;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
    }

    &__content_message {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        padding: 20px;
        gap: 10px;
        overflow: auto;
        max-height: calc(100% - 67px);

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    &__content_message_send {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        gap: 10px;
        overflow: auto;
        max-height: calc(100% - 67px);
    }
}



// Сообщение

.message_item_v1,
.message_item_user_v1 {
    width: 70%;
    display: flex;
    padding: 12px 14px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 8px 8px 8px 0px;
    background: #F3F4F6;

    &__title {
        color: #000;
        font-family: NunitoSans;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    &__link {
        color: #0009E8;
        font-family: Rubik;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-decoration-line: underline;
    }

    &__text {
        color: #000;
        font-family: NunitoSans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    &__date {
        color: #5C7189;
        font-family: "Golos Text";
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    &__img {
        width: 100%;
        height: 100%;
    }
}

.message_item_user_v1 {
    margin-left: auto;
    border-radius: 8px 8px 0px 8px;
    background: #DCFCE7;
}