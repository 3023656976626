.editor-article {
    display: flex;
    gap: 4px;
    position: relative;
    flex-wrap: wrap;

    &__modal-link {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 12px;
        top: calc(100% + 4px);
        position: absolute;
        width: 300px;
        height: auto;
        background-color: white;
        border: 1px solid black;
        border-radius: 12px;
        border: 1px solid #EDEFFB;
    }

    &__btn {
        padding-left: 4px;
        padding-right: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #EDEFFB;
        border-radius: 4px;
        min-width: 24px;
        height: 24px;
        cursor: pointer;
        border: none;
    }
}