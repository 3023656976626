.blackout {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: 10;
  position: fixed;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 0, 0, 0.3);
  padding: 20px;
}

.modal_container {
  width: 750px;
  height: 500px;
  background: white;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  h2 {
    margin-bottom: 1em;
  }

  .drop_file_container {
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: lightgrey;
    margin-top: 0.5em;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;

    .loader-container {
      height: '60px';
      display: 'flex';
      justify-content: center;
      align-items: center;
    }

    &.dragover {
      background: darkgrey;
    }

    p {
      font-size: 1rem;
      color: #333;
    }
  }

  .action_container {
    align-self: flex-end;
    display: flex;
    gap: 24px;
  }
}
