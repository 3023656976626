.modal-transfer-to-supply {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 2000;
    display: flex;
    max-width: 460px;
    width: 100%;
    min-width: 360px;
    min-height: 400px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 40px);
    gap: 24px;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    .title {
        color: var(--Text-Primary, #0B110B);
        text-align: center;
        font-family: NunitoSans;
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
    }

    &__content {
        display: flex;
        width: 100%;
        height: 100%;
    }

    &__content-supply {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        max-width: 242px;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 8px 20px;
        height: 100%;
    }

    &__content-boxes {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        max-width: 242px;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 8px 20px;
        padding-right: 8px;
        height: 100%;
    }

    .btn-basic {
        margin-top: auto;
    }
}