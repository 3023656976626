.analysis-platform-by-sale-type {
    display: flex;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    gap: 24px;

    .title_main {
        color: var(--slate-950, #020617);
        font-family: Rubik;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.56px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
    }

    .tooltip-blue {
        margin-bottom: 12px;
    }

    .analysis-platform-by-sellers {
        padding: 0;
    }

    .text_blue-gray {
        color: #93B4D1;
        font-size: 15px;
        margin-bottom: 20px;
    }

    .table-box-component {
        align-items: flex-start;
    }

    .top-panel {
        display: flex;
        width: 100%;
        align-items: flex-end;
        gap: 30px;
        justify-content: space-between;

        .btn-group {
            display: flex;
            align-items: center;
            gap: 12px;
        }
    }

    .medium-panel {
        display: flex;
        width: 100%;
        gap: 30px;
        margin-top: 20px;

        &:nth-child(2) {
            justify-content: flex-end;
        }

        .alphabetical-search {
            margin-top: 0;
        }
    }

    .from-to-component-group {
        display: flex;
        gap: 20px;
        margin-top: 40px;
        flex-wrap: wrap;
    }



    .toolbar-top {
        display: flex;
        align-items: center;
        gap: 27px;

        .btn__drop {

            background-position: center;
            background-repeat: no-repeat;
            background-image: url('./img/drop-grey-left.svg');
            // transform: scale(-1, 1);
            // transform: rotate(180deg);
            background-color: inherit;
            cursor: pointer;
            z-index: 0;
        }

        .title {
            font-size: 29px;
            font-weight: 500;
        }
    }

    .toolbar-medium {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 16px;
        flex-wrap: wrap;
        align-items: flex-end;

        .range-group-btn-and-range-box {
            display: flex;
            align-items: center;
            gap: 30px;

            .selector_wrapper {
                max-width: 124px;
                min-width: 124px;
            }
        }
    }

    .tab-box {
        display: flex;
        width: auto;
        row-gap: 12px;
        flex-wrap: wrap;

        .btn__tab,
        .btn__tab_active {
            cursor: pointer;
            height: 34px;
            min-width: 130px;
            width: auto;
            font-size: 15px;
            font-weight: 500;
            background-color: inherit;
            border: none;
            padding-right: 20px;
            padding-left: 20px;
            white-space: nowrap;
            line-height: 20px;
            border-bottom: 2px solid #E0E7F2;

            &:hover {
                transition: 0.5s;
                border-bottom: 2px solid #1890FF;
                color: #1890FF;
            }

            &_active {
                border-bottom: 2px solid #1890FF;
                color: #1890FF;
            }
        }
    }


    .right-content {
        display: flex;
        flex-direction: column;
        gap: 28px;
        max-width: 506px;
        width: 100%;
        flex-shrink: 0;
        flex-grow: 0;
    }

    .title {
        color: #888888;
        font-size: 15px;
        margin-top: 4px;
    }

    .inside-content {
        display: flex;
        width: 100%;
        overflow: hidden;
        gap: 40px;
        flex-wrap: wrap;

        .metrics-box {
            display: flex;
            align-items: start;
            height: min-content;
            flex-wrap: wrap;
            column-gap: 10px;
            row-gap: 15px;
            max-width: calc(248px*2 + 10px);
            width: 100%;
            min-height: 30px;
        }

        .statistic {
            display: flex;
            align-items: start;
            height: min-content;
            flex-wrap: wrap;
            column-gap: 10px;
            row-gap: 15px;
            max-width: calc(248px*2 + 10px);
            width: 100%;
            min-height: 30px;

            &__item {
                display: flex;
                flex-direction: column;
                gap: 8px;
                min-width: 248px;
                padding-left: 12px;
                padding-right: 12px;

                .text_grey {
                    font-size: 13px;
                }

                .text {
                    font-weight: 500;
                    font-size: 15px;
                }

                .text_burgundy {
                    font-weight: 500;
                    color: #A53100;
                    font-size: 15px;
                }
            }
        }
    }

    .line_chart {
        flex-shrink: 1;
        padding: 0;
        width: 100%;
    }

    .chart-box {
        width: auto;
        flex-shrink: 1;
        flex-grow: 1;
        opacity: 1;
        visibility: visible;
        transition: 0.5s;
        overflow: hidden;

        .line_chart {
            flex-shrink: 1;
            padding: 0;
            width: 100%;
            max-height: 250px;
            min-height: 250px;
        }
    }
}