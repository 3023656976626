// Превью

.chat-preview,
.chat-preview_active {
    display: flex;
    gap: 10px;
    padding: 12px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-bottom: 1px solid #D1D5DB;
    background: #FFF;
    cursor: pointer;

    &__content-title-members {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 4px;
    }

    .employees-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .sum_executor {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: #F1F5F9;
            color: #2E3552;
            text-align: center;
            font-family: NunitoSans;
            font-size: 10px;
            font-style: normal;
            font-weight: 800;
            line-height: 8px;
            margin-left: 8px;
            cursor: pointer;

            span {
                color: rgba(46, 53, 82, 0.60);
            }
        }

        .tooltip-container {
            margin-left: -6px;

            &:nth-child(1) {
                margin-left: 0;
            }
        }
    }

    .btn-group {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 11px;
        border-radius: 8px;
        background: #ffffff;

        .btn-edit,
        .btn-delete {
            border: none;
            background-color: inherit;
            width: 18px;
            height: 18px;
            min-width: 18px;
        }
    }

    &_active {
        background: #F0FDF4;
    }

    &:hover {
        transition: 0.5s;
        background: #F0FDF4;
    }

    &__content {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 4px;
    }

    &__top,
    &__bottom {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        overflow: hidden;
    }

    &__top{
        .unread_count{
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: rgb(93, 163, 255);
            padding: .25em;
            font-size: 15px;
            // line-height: 1em;
            color: white;
        }
    }

    &__title {
        white-space: nowrap;
        overflow: hidden;
        color: black;
        text-overflow: ellipsis;
        font-family: NunitoSans;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .employees-container {
        flex-wrap: nowrap;
    }

    &__date {
        color: #D1D5DB;
        font-family: NunitoSans;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        white-space: nowrap;
    }

    &__message {
        overflow: hidden;
        white-space: nowrap;
        color: #747F90;
        text-overflow: ellipsis;
        font-family: NunitoSans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    &__sum_message {
        display: flex;
        width: 20px;
        height: 20px;
        min-width: 20px;
        padding: 4px 3px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: #4E75FF;
        color: #FFF;
        font-family: NunitoSans;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
    }
}