.products-list-master-products {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: calc(100vw - 140px);
    height: 100%;

    &__header {
        display: flex;
        align-items: center;
        gap: 24px;
        padding-bottom: 12px;

        .title_main {
            color: #020617;
            font-family: Rubik;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: -0.56px;
        }

        .title-tooltip {
            display: flex;
            align-items: center;
            gap: 8px;
            color: #64748B;
            font-family: Rubik;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
        }
    }

    .toolbar-top {
        display: flex;
        width: auto;
        width: 100%;
        align-items: center;
        gap: 16px;
       // flex-wrap: wrap;
        justify-content: space-between;

        .filters-container {
            flex: 1;
        }
    }

    .content-btn {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        white-space: nowrap;
        align-items: center;
        gap: 8px;
    }
}