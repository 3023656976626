.modal-alert {
    display: flex;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 458px;
    width: 100%;
    min-width: 300px;
    flex-direction: column;
    padding: 10px 14px;
    flex-direction: column;
    gap: 8px;
    border-radius: 10px;
    max-width: 300px;
    background: #FFF;
    animation: show 400ms;
    opacity: 1;

    &__title {
        color: #000;
        font-family: NunitoSans;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
    }

    &__description {
        color: #000;
        font-family: NunitoSans;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
    }
}
