.modal-err {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    display: flex;
    max-width: 460px;
    width: 100%;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.12);

    .title {
        padding: 0;
        margin-top: 24px;
        font-size: 15px;
        line-height: 24px;
        font-weight: 500;
    }

    .text_grey-blue {
        border-radius: 8px;
        font-size: 13px;
        margin-top: 16px;
    }

    .btn__blue {
        min-width: 120px;
        max-width: 120px;
        min-height: 52px;
        margin-top: 24px;
    }
}