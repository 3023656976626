.tariffs-my {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 100%;
    width: 100%;

    .balance {
        display: flex;
        gap: 24px;

        .balance-card {
            display: flex;
            flex-direction: column;
            border-radius: 10px;
            border: 2px solid #F2F2F4;
            background: #FFF;
            gap: 24px;
            max-width: 714px;
            width: 100%;
            padding: 24px;

            &__content {
                display: flex;
                width: 100%;
                justify-content: space-between;
                padding: 14px 20px;
                border-radius: 10px;
                background: #F6F8FF;
                gap: 24px;
            }

            .text {
                color: #0C2037;
                font-family: Rubik;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .sum {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                align-self: stretch;
                border-radius: 16px;
                color: #365EFF;
                text-align: center;
                font-family: Rubik;
                font-size: 29px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                border-radius: 16px;
                //  border-image-source: linear-gradient(13deg, rgba(24,144,255,1) 40%, rgba(43,220,186,1) 98%);

            }
        }

        .btn-group {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 16px;
        }
    }

    .promo-code {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 10px;
        border: 2px solid #F2F2F4;
        background: #FFF;
        padding: 24px;
        gap: 24px;

        .loader_box {
            width: fit-content;
            height: fit-content;
            position: absolute;
            top: calc(50% - 16px);
            right: calc(62px - 12px);
        }

        .text {
            color: #0C2037;
            font-family: Rubik;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        &__container-input {
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding: 14px 20px;
            border-radius: 10px;
            gap: 24px;
            background: #F6F8FF;

            .input-grey {
                display: flex;
                padding: 8px 12px 8px 8px;
                padding-right: 144px;
                width: 100%;
                min-width: 328px;
                max-width: 328px;
                min-height: 40px;
                border-radius: 8px;
                background: #EDF3F8;
                border: none;
                text-align: center;

                &:placeholder {
                    color: #94A3B8;
                    text-align: center;
                    font-family: Inter;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                }

                &:focus {
                    outline: none;
                }
            }

            .text_err {
                position: absolute;
                top: 40px;
                color: #EF4444;
                font-family: Inter;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .btn-grey,
            .btn-grey_active {
                width: 124px;
                height: 40px;
                top: 0;
                right: 0;
                position: absolute;
                gap: 8px;
                border-radius: 8px;
                background: #E1E6EA;
                border: none;

                &_active {
                    background-color: white;
                    color: #1890FF;
                    border: 1px solid #1890FF;
                }


            }
        }
    }

    .link-load {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--all-text-text-accent, #1890FF);
        justify-content: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .download-history {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        border-radius: 16px;

        .title {
            color: #0C2037;
            font-family: Rubik;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
}