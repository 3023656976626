.anomalies__charts-group {
    display: flex;
    align-items: center;
    gap: 20px;

    .line_chart {
        background-color: #FFF;
        
    }

    .line_chart-wrapper {
        max-width: calc(33% - 10px);
    }
}