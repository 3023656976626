.project-for-tasks {
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 100%;
    height: 100%;
    //  max-width: calc(283px*6 + 12px*5);

    .employees-container {
        display: flex;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;

        .sum_executor {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: #F1F5F9;
            color: #2E3552;
            text-align: center;
            font-family: NunitoSans;
            font-size: 10px;
            font-style: normal;
            font-weight: 800;
            line-height: 8px;
            margin-left: 8px;
            cursor: pointer;

            span {
                color: rgba(46, 53, 82, 0.60);
            }
        }

        .tooltip-container {
            margin-left: -6px;

            &:nth-child(1) {
                margin-left: 0;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        gap: 24px;
    }

    .toolbar-top {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        padding: 12px 24px;
        background-color: #fff;
        border-radius: 8px;
        flex-wrap: wrap;

        &__left {
            display: flex;
            align-items: center;
            column-gap: 24px;
            row-gap: 16px;
            flex-shrink: 0;

            .btn-add-content {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
            }
        }

        
    }

    .toolbar-bottom {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        padding: 12px 24px;
        background-color: #fff;
        border-radius: 8px;

        .btn-group {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 12px;
        }

        .btn-executor-content {
            display: flex;
            align-items: center;
            gap: 8px;
            justify-content: center;
            font-weight: 500;
        }

        .sort-employees {
            display: flex;
            align-items: center;
            gap: 8px;
            border: none;

            .text {
                white-space: nowrap;
                color: #475569;
                font-family: Inter;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }

            .btn-add-executor {
                width: 32px;
                height: 32px;
                min-width: 32px;
                background-color: #EDEFFB;
                background-image: url('./img/account.svg');
                background-repeat: no-repeat;
                background-size: 17px;
                background-position: center;
                border-radius: 50%;
                cursor: pointer;
            }

            .members-box {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;

                .light-blue-box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 120px;
                    padding-left: 16px;
                    padding-right: 16px;
                    background-color: #E6F7FF;
                    height: 32px;
                    border-radius: 16px;
                    cursor: pointer;
                }

                .tooltip-container {
                    margin-left: -6px;

                    &:nth-child(1) {
                        margin-left: 0;
                    }
                }
            }

            .sum_executor {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                min-width: 32px;
                border-radius: 50%;
                height: 32px;
                background-color: #F1F5F9;
                white-space: nowrap;
                color: #2E3552;
                text-align: center;
                font-family: NunitoSans;
                font-size: 10px;
                font-style: normal;
                font-weight: 800;
                line-height: 8px;
                cursor: pointer;

                span {
                    color: rgba(46, 53, 82, 0.60);
                }
            }
        }

        &__right {
            display: flex;
            align-items: center;
            column-gap: 24px;
            row-gap: 16px;
            flex-wrap: wrap;
            flex-shrink: 1;
        }

        .btn-sort {
            display: flex;
            align-items: center;
            gap: 8px;
            flex-wrap: nowrap;
            background-color: inherit;
            border: none;

            .text {
                white-space: nowrap;
                color: #475569;
                font-family: Inter;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }
        }

        .tab-sort {
            overflow: hidden;
            width: auto;
            display: flex;
            height: 40px;
            border-radius: 8px;
            border: 1px solid #EDEFFB;

            .btn,
            .btn_active {
                display: flex;
                align-items: center;
                gap: 6px;
                justify-content: center;
                height: 100%;
                cursor: pointer;
                border-left: 1px solid var(--table-border, #EDEFFB);
                border: none;
                background-color: #fff;
                padding-left: 20px;
                padding-right: 20px;
                color: var(--Slate-950, #020617);
                font-family: Rubik;
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: 14px;
                border-left: 1px solid #EDEFFB;

                &:hover,
                &_active {
                    transition: 0.5s;
                    background: #51CA64;
                    color: white;

                    path {
                        fill: #fff
                    }
                }

                &:nth-child(1) {
                    border: none;
                }

                .arrow, .arrow_up {
                    min-width: 16px;
                    width: 16px;
                    height: 16px;
    
                    &_up {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    &__box-card,
    &__list-card {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        width: 100%;
        height: 100%;

        .no-data-content {
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 30px;

            .title {
                color: #0F172A;
                text-align: center;
                font-family: Rubik;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: -0.2px;
            }

            .text {
                color: #68727D;
                text-align: center;
                font-family: Rubik;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }

    &__list-card {
        height: auto;
        gap: 8px;
    }

    .card-item {
        cursor: pointer;
        background-color: #ffffff;
        border-radius: 8px;
        border: 1px solid #E0E7F2;
        height: auto;
        position: relative;
        width: 240px;
        min-width: 240px;
        min-height: 319px;
        max-height: 319px;

        &:hover {
            transition: 0.3s;
            background-color: #E7F1FF;
            border: 1px solid #1890FF;

            .card-item__header {
                transition: 0.3s;
                background-color: #F5F9FF;
            }
        }

        &__top-content {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 12px 20px 20px;
            border-radius: 8px 8px 0px 0px;
            background: #F8F9FC;

            .img-box {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 112px;
                min-height: 112px;
                width: 100%;
                border-radius: 8px;
                overflow: hidden;

                .img {
                    width: 100%;
                    object-fit: contain;
                    height: 100%;
                }
            }
        }

        &__header {
            display: flex;
            justify-content: space-between;
            width: auto;
            align-items: center;
            gap: 8px;

            .statistic-tasks {
                display: flex;
                gap: 16px;
                overflow: hidden;
                flex-grow: nowrap;

                &__item {
                    padding: 2px 4px;
                    align-items: flex-start;
                    white-space: nowrap;
                    border-radius: 4px;
                    text-align: center;
                    color: var(--Slate-500, #64748B);
                    font-family: NunitoSans;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 14px;

                    span {
                        color: #020018;
                        font-weight: 600;
                    }
                }
            }


        }

        &__medium-content {
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 12px;
            width: auto;

            .title {
                color: #020617;
                font-family: NunitoSans;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                min-height: 40px;
                letter-spacing: 0.08px;
                display: -webkit-box;
                -webkit-line-clamp: 2; // количество строк
                -webkit-box-orient: vertical;
                overflow: hidden;
                hyphens: auto;
            }

            .date_project {
                color: #64748B;
                font-family: NunitoSans;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0.12px;
            }
        }

        &__bottom-content {
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 20px 16px;
            width: auto;
            border-top: 1px solid #EDEFFB;

            .sum-task,
            .date-box {
                display: flex;
                align-items: center;
                gap: 4px;

                img {
                    width: 16px;
                    height: 16px;
                }

                .text {
                    color: #8F99A3;
                    font-family: Inter;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                }

                .days-left {
                    margin-left: auto;
                    display: flex;
                    padding: 0px 2px;
                    min-height: 8px;
                    align-items: center;
                    gap: 8px;
                    border-radius: 6px;
                    background: #FFF0F0;
                    color: var(--red-300, #FCA5A5);
                    font-family: Inter;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;

                    span {
                        color: #F93232;
                    }
                }
            }

            .date-of-creation {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: auto;
                margin-left: auto;

                .text {
                    color: #94A3B8;
                    font-family: NunitoSans;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 12px;
                    letter-spacing: 0.05px;
                }

                .text_date {
                    @extend .text;
                    color: #020617;
                }
            }
        }

        .box-title {
            display: flex;
            flex-direction: column;
            gap: 16px;
            justify-content: space-between;
            align-items: center;
            padding: 24px;
            height: 55%;
            width: 100%;
            border-radius: 16px;
            border-top: 1px solid #f0f0f0;
            overflow: hidden;

            .title-box {
                max-width: 100%;
                overflow: hidden;

                .title {
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }


            .group-btn {
                display: flex;
                width: 100%;
                flex-direction: column;
                gap: 8px;
            }
        }
    }

    .list-item {
        display: flex;
        justify-content: start;
        cursor: pointer;
        background-color: white;
        border-radius: 8px;
        border: 1px solid #E0E7F2;
        height: 60px;
        overflow: hidden;
        position: relative;
        width: 100%;

        &:hover {
            transition: 0.3s;
            background-color: #E7F1FF;
            border: 1px solid #1890FF;

            .list-item__header {
                transition: 0.3s;
                background-color: #E7F1FF;
            }
        }

        &__header {
            display: flex;
            justify-content: space-between;
            padding: 8px 16px;
            background-color: white;
            min-width: 369px;
            max-width: 369px;
            width: 369px;
            min-height: 42px;
            align-items: center;
            gap: 12px;
            width: 100%;

            .title {
                width: 100%;
                color: #020617;
                font-family: Rubik;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                display: -webkit-box;
                -webkit-line-clamp: 2; // количество строк
                -webkit-box-orient: vertical;
                overflow: hidden;
                hyphens: auto;
            }

            .img-box {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 45px;
                min-width: 45px;
                max-width: 45px;
                width: 45px;
                border-radius: 4px;
                border: 1px solid #E0E7F2;
                overflow: hidden;

                .img {
                    width: 100%;
                    object-fit: contain;
                    height: 100%;
                }
            }
        }

        &__content {
            display: flex;
            align-items: center;
            gap: 12px;
            width: 100%;
            height: 100%;
            padding-right: 16px;

            .sum-and-date {
                display: flex;
                gap: 12px;
                align-items: center;
                padding-left: 22px;
                padding-right: 22px;
                min-width: 250px;
                max-width: 250px;
                overflow: hidden;
                border-right: 1px solid var(--stroke-primary-primary-primary, #E0E7F2);
                border-left: 1px solid var(--stroke-primary-primary-primary, #E0E7F2);
                height: 45px;
            }

            .sum-task,
            .date-box {
                display: flex;
                align-items: center;
                flex-direction: row;
                overflow: hidden;
                gap: 4px;

                img {
                    width: 16px;
                    height: 16px;
                }

                .text {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    color: #8F99A3;
                    font-family: Inter;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    white-space: nowrap;
                }

                .days-left {
                    margin-left: auto;
                    display: flex;
                    padding: 0px 2px;
                    min-height: 8px;
                    align-items: center;
                    gap: 8px;
                    border-radius: 6px;
                    background: #FFF0F0;
                    color: var(--red-300, #FCA5A5);
                    font-family: Inter;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;

                    span {
                        color: #F93232;
                    }
                }
            }

            .employees-container {
                display: flex;
                width: auto;
                overflow: hidden;
                flex-wrap: wrap;
                align-items: center;
                height: 30px;
                max-height: 30px;
                min-width: 190px;

                .sum_executor {
                    color: #53616E;
                    font-family: Inter;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    margin-left: 8px;
                }

                .tooltip-container {
                    margin-left: -6px;

                    &:nth-child(1) {
                        margin-left: 0;
                    }
                }
            }

            .date-of-creation {
                min-width: fit-content;
                margin-left: auto;

                .text {
                    white-space: nowrap;
                    color: #8F99A3;
                    font-family: Inter;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                }
            }

            .btn-delete {
                width: 16px;
                min-width: 16px;
                height: 16px;
                background-image: url('./img/delete_icon.svg');
                background-repeat: no-repeat;
                border: none;
                background-color: inherit;
                cursor: pointer;
            }
        }

    }
}

.widget-chat {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00B45E;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    position: fixed;
    bottom: 110px;
    right: 20px;

    &:hover {
        transition: 0.5s;
        background-color: #199547;
    }
}