.input-number {
    width: 100%;
    position: relative;

    input {
        display: flex;
        padding: 8px 12px 8px 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: 8px;
        background: #F4F8FB;
        outline: none;
        border: 1px solid #F4F8FB;
        width: 100%;
        max-width: 500px;
        text-align: center;

        &:focus {
            border: 1px solid var(--text-accent-blue, #1890FF);
            box-shadow: 0px 0px 4px 0px rgba(24, 144, 255, 0.40);
            outline: none;
        }

        &::placeholder {
            color: var(--main-menu-teritary-text, #94A3B8);
            text-align: center;
            font-family: Inter;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }
    }

    .err {
        border: 1px solid var(--fields-stroke-error, #FFD8D8);
        background: var(--fields-bg-error, #FFF5F5);

        &:focus {
            border: 1px solid var(--fields-stroke-error, #FFD8D8);
            box-shadow: none;
            outline: none;
        }
    }

    &__err {
        position: absolute;
        max-height: 30px;
        height: 100%;
        overflow-x: auto;
        top: calc(100% + 4px);
        display: flex;
        gap: 4px;
        align-items: flex-start;

        .btn__err {
            width: 16px;
            height: 16px;
            border: none;
            background-color: inherit;
            background-image: url('./img/close_red.svg');
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: center;
        }

        .text_red {
            color: #FF4D4F;
            font-size: 11px;
            margin: 0;
        }
    }
}