.modal-table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 60px;
    width: 100%;
    padding: 14px 20px;
    position: fixed;
    z-index: 1000;
    bottom: 12px;
    border-top: 2px solid #C4CAEA;
    height: auto;
    background-color: #fff;
    left: 0;

    &__btns {
        display: flex;
        gap: 12px;
    }
}