.emoji-addition-inside-content {
    display: flex;
    width: 100%;
    gap: 24px;

    .old-description,
    .new-description {
        display: flex;
        width: calc(50% - 12px);
        padding: 16px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        border: 2px solid #EDEFED;
        background: #FFF;
        min-height: 300px;
        border-radius: 8px;

        .text {
            color: #64748B;
            font-family: NunitoSans;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;

            ul {
                box-sizing: border-box;
                padding: .25em 1.25em;
                margin: unset;

                li{ 
                    list-style: unset;
                    padding: unset;
                    margin: unset;
                }
            }
        }
    }

}