.btn-drop {
    display: flex;
    width: auto;
    width: 64px;
    min-width: 64px;
    padding: 6px;
    height: 36px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #EDEFED;
    background: #FFF;
    position: relative;

    &__button {
        border: none;
        background-color: inherit;
    }

    &:hover {
        border: 1px solid #64748B;
    }

    &__drop-container {
        display: flex;
        flex-direction: column;
        width: 280px;
        max-width: 280px;
        border: 2px solid #EDEFED;
        position: absolute;
        top: calc(100% + 8px);
        left: 0;
        background-color: #fff;
        overflow: hidden;
        z-index: 11;
        border-radius: 8px;
    }

    &__btn-drop {
        cursor: pointer;
        border: none;
        border-bottom: 2px solid #EDEFED;
        background-color: inherit;
        width: 100%;
        height: 48px;
        padding: 16px;
        color: #020617;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: Rubik;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-align: left;

        &:hover {
            transition: 0.3s;
            background-color: #FAFBFA;
        }

        &:last-child {
            border-bottom: none;
        }
    }
}