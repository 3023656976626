.joined-promotions {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;

    .label-check {
        color: #020617;
        font-family: Rubik;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
    }

    .title_main {
        color: #020617;
        font-family: Rubik;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        margin-bottom: 12px;
    }

    .filter-group {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        width: 100%;
    }

    .child-table {
        display: flex;
        align-items: center;
        gap: 12px;

        .btn-group {
            display: flex;
            gap: 12px;
            width: 100%;
            justify-content: end;
        }
    }

    .content-btn {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .metrics-items {
        width: fit-content;
        display: flex;
        width: fit-content;
        gap: 12px;
        flex-wrap: wrap;
    }

    .child-content {
        display: flex;
        width: 100%;
        column-gap: 12px;
        row-gap: 8px;
        align-items: center;
        flex-wrap: wrap;
    }

}