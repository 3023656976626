.account-safety {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .link__blue {
    display: inline-block;
    width: 100%;
    text-align: right;
    color: #1890ff;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: underline;
    margin-left: auto;
    margin-top: 4px;
  }

  .err-box {
    margin-top: 10px;
  }
}
